import { createContext, useContext, useEffect } from 'react';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { app } from '@microsoft/teams-js';
import { ThemeContextProvider } from 'contexts';
import { LoadingScreen } from '../../components/Common/LoadingScreen';
import { TeamsObject, TeamsProviderProps } from './models';
import { FluentProvider } from '@fluentui/react-components';

const Context = createContext<TeamsObject | null>(null);
const authConfig = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
};

export const TeamsContextProvider = ({ children }: TeamsProviderProps) => {
  const { loading, context, theme, themeString, inTeams, teamsUserCredential } = useTeamsUserCredential(authConfig);

  useEffect(() => {
    if (inTeams) {
      app.notifySuccess();
    }
  }, [inTeams]);

  return (
    <Context.Provider value={{ theme, context, loading, inTeams, themeName: themeString, credential: teamsUserCredential }}>
      <FluentProvider theme={theme}>
        <ThemeContextProvider >
          {loading || !context ? <LoadingScreen /> : <>{inTeams ? children : <div>Not in Teams</div>}</>}
        </ThemeContextProvider>
      </FluentProvider>
    </Context.Provider>
  );
};

export const useTeamsContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Lack of TeamsProvider in components tree');
  }

  return ctx;
};
