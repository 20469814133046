import { Tab, TabList } from '@fluentui/react-components';

interface Props {
  items: {
    content: string;
    key: string;
  }[];
  defaultSelectedValue?: string;
  onChange: (key: string) => void;
}

export const MenuTabs = ({ items, onChange, defaultSelectedValue }: Props) => {
  return (
    <TabList defaultSelectedValue={defaultSelectedValue}>
      {items.map((item) => {
        return (
          <Tab key={item.key} onClick={() => onChange(item.key)} value={item.key}>
            {item.content}
          </Tab>
        );
      })}
    </TabList>
  );
};
