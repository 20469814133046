import { useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { people } from '@microsoft/teams-js';
import { moduleName } from '.';
import { MAX_RECIPIENTS } from '../Utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Text } from '@fluentui/react-components';
import { Dismiss16Regular, Add16Regular, ReOrderDotsVertical24Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { Email, Languages } from '../DecoratedInputs';
import { PhoneNumberWithCode } from '../DecoratedInputs/PhoneNumberWithCode';
import { getPhonePrefixes } from 'shared-fe-components/src/api/notifications';
import { FormCheck } from 'shared-fe-components/src/common/ReactHookForm/FormCheck';
import { useUserContext } from 'contexts';
import './SortableRecipients.scss';

export const SortableRecipients = ({ deleteFirstIfEmpty = true, userEmailAddress, isDisabled, setIsFormDirty }) => {
  const [phoneCodes, setPhoneCodes] = useState([]);

  const formContext = useFormContext();
  const {
    getValues,
    trigger,
    formState: { submitCount },
  } = formContext;
  const { features } = useUserContext();

  const { fields, append, remove, move } = useFieldArray({
    control: formContext.control,
    name: moduleName,
  });

  const recipientsChecks = [
    // {
    //   key: 'validationAtLeastOneNotEmpty',
    //   fieldName: 'validationAtLeastOneNotEmpty',
    //   validateFn: () => {
    //     const hasNotEmptyRecipientRow = getValues(moduleName).some((row) => row.value?.length > 0);
    //     return hasNotEmptyRecipientRow || t({ id: 'Form.EmailInputs.Errors.AtLeastOneRequired' });
    //   },
    // },
    // Keeping this code, this feature might be used in the future
  ].map((checkProps) => <FormCheck {...checkProps} />);

  useEffect(() => {
    fields.length === 0 && append({ value: '' });
  }, []);

  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  const removeRecipient = (index) => {
    if (deleteFirstIfEmpty || fields.length > 1) {
      remove(index);
    }
    if (submitCount !== 0) trigger();
  };

  const addUserEmailAddress = () => {
    const recipientsFormContext = getValues(moduleName);
    if (recipientsFormContext.length <= 0) {
      return;
    }

    append({ value: userEmailAddress }, { shouldFocus: false });
  };

  const addFromOrganization = async () => {
    try {
      const selectedPeople = await people.selectPeople();
      const selectedEmails = selectedPeople.map((person) => ({ value: person.email }));
      append(selectedEmails, { shouldFocus: false });
    } catch (error) {
      // Cancelling throws an error, but it's not a problem in our case
      if (error.errorCode !== 8000) {
        console.error(error);
      }
    }
  };

  // fetch acceptable phone codes for phonenumber dropdown
  useEffect(() => {
    (async () => {
      const countryCodesPrefixes = await getPhonePrefixes();
      const countryIsoCodes = countryCodesPrefixes.map(({ countryCode }) => countryCode);
      setPhoneCodes(countryIsoCodes);
    })();
  }, []);

  const addNewEmailField = () => {
    append({ value: '' });
  };

  const emailConfig = {
    methods: {
      // addRecipient: () => {
      //   const hasEmptyRecipientRow = getValues(moduleName).some((row) => row.value === '');
      //   if (!hasEmptyRecipientRow) append({ value: '' });
      // },
      // Keeping this code, this feature might be used in the future
    },
    validation: {
      isRequired: true,
    },
  };

  return (
    <div>
      <div>
        <DragDropContext onDragEnd={handleDrag}>
          <ul className="sortable-rows-container">
            <div className="sortable-recipient-rows__headers-container">
              <div className="sortable-flex-space" />
              <div className="sortable-recipient-rows__headers">
                <Text className="sortable-recipient-rows__header--1" size="small">
                  {t({ id: 'Recipients.Header.Recipient' })}
                </Text>
                <Text className="sortable-recipient-rows__header--1" size="small">
                  {t({ id: 'Recipients.Header.Communication' })}
                </Text>
                {features.SignatureProtection && (
                  <Text className="sortable-recipient-rows__header--2" size="small">
                    {t({ id: 'Recipients.Header.PhoneNumber' })}
                  </Text>
                )}
              </div>

              <div className="sortable-recipient-rows__button-space"></div>
            </div>
            <Droppable droppableId="recipients-items">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((row, index) => {
                    return (
                      <Draggable key={`recipients[${index}]`} draggableId={`item-${index}`} index={index}>
                        {(provided, snapshot) => (
                          <li key={row.id} ref={provided.innerRef} {...provided.draggableProps}>
                            <div className="draggable-inputs__list">
                              <div className="sortable-flex-space">
                                <div className="draggable-inputs__list--hash">#</div>
                                <Text className="draggable-inputs__list--index" size="small">
                                  {index + 1}
                                </Text>
                                <div className="draggable-inputs__list--container" {...provided.dragHandleProps}>
                                  <ReOrderDotsVertical24Regular />
                                </div>
                              </div>
                              <div className="sortable-recipient-rows" key={row.id}>
                                <div className="sortable-recipient-rows__inputs">
                                  <Email
                                    isDisabled={isDisabled}
                                    index={index}
                                    config={emailConfig}
                                    moduleName={moduleName}
                                  />
                                  <Languages
                                    index={index}
                                    moduleName={moduleName}
                                    isDisabled={row?.status === 'Succeeded'}
                                    setIsFormDirty={setIsFormDirty}
                                  />
                                  {features.SignatureProtection && (
                                    <PhoneNumberWithCode
                                      index={index}
                                      moduleName={moduleName}
                                      isDisabled={row?.status === 'Succeeded'}
                                      phoneCodes={phoneCodes}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="draggable-inputs__list--dismiss">
                                <Button
                                  icon={<Dismiss16Regular />}
                                  appearance="transparent"
                                  disabled={isDisabled}
                                  onClick={() => removeRecipient(index)}
                                />
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </ul>
        </DragDropContext>
      </div>
      {recipientsChecks}
      {fields.length < MAX_RECIPIENTS && !isDisabled && (
        <Button
          className="sortable-recipients__action-button"
          appearance="transparent"
          icon={<Add16Regular />}
          onClick={addNewEmailField}
        >
          {t({ id: 'Form.EmailsInputs.AddField.Recipient' })}
        </Button>
      )}
      {userEmailAddress && fields.length < MAX_RECIPIENTS && !isDisabled && (
        <Button
          className="sortable-recipients__action-button"
          appearance="transparent"
          icon={<Add16Regular />}
          onClick={addUserEmailAddress}
        >
          {t({ id: 'Form.EmailsInputs.AddField.MyEmailAddress' })}
        </Button>
      )}
      {fields.length < MAX_RECIPIENTS && !isDisabled && (
        <Button
          className="sortable-recipients__action-button"
          appearance="transparent"
          icon={<Add16Regular />}
          onClick={addFromOrganization}
        >
          {t({ id: 'Form.EmailsInputs.AddField.FromOrganization' })}
        </Button>
      )}
    </div>
  );
};
