import { Flex } from '@fluentui/react-migration-v0-v9';
import {
  Button,
  Spinner,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@fluentui/react-components';
import { Breadcrumb, BreadcrumbDivider, BreadcrumbItem, BreadcrumbLink } from '@fluentui/react-breadcrumb-preview';
import { ArrowCounterclockwise24Regular } from '@fluentui/react-icons';
import { useGraph } from '../lib/useGraph';
import { useGraphConsent } from '../lib/useGraphConsent';
import * as microsoftTeams from '@microsoft/teams-js';
import { t } from '@lingui/macro';
import { useEffect, useState, useCallback, Fragment } from 'react';
import FileList from './FileList/FileList';
import { useTeamsContext, useThemeContext, useUserContext } from 'contexts';
import { LicenseError } from './License';
import { FILE_FORMATS_MAPPED } from 'shared-fe-components/src/utils/fileFormats';
import './Tab.scss';

export default function Tab() {
  const { context: teamsContext } = useTeamsContext();
  const { consentNeeded, consentAcquired } = useGraphConsent();
  const [files, setFiles] = useState(null);
  const [path, setPath] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(undefined);

  const [dialogOpen, setDialogOpen] = useState(false);

  const { hasLicense, user } = useUserContext();
  const { colorScheme } = useThemeContext();

  const { loading, reload } = useGraph(
    async (graph) => {
      try {
        if (teamsContext.channel.id) {
          if (!currentFolder) {
            const drive = await graph
              .api(
                `/sites/${teamsContext.sharePointSite.teamSiteDomain}:${teamsContext.sharePointSite.teamSitePath}:/drive`
              )
              .get();
            const filesFolder = await graph.api(`/drives/${drive.id}/root`).get();
            setCurrentFolder(filesFolder);
            setPath([filesFolder]);
          } else {
            const channelFiles = await graph
              .api(`/drives/${currentFolder.parentReference.driveId}/items/${currentFolder.id}/children`)
              .get();
            setFiles(channelFiles);
          }
        }
      } catch (error) {
        console.error(error);
      }
      return {};
    },
    {
      scope: ['User.Read', 'Files.ReadWrite.All'],
      initialState: { loading: true },
    }
  );

  useEffect(() => {
    if (consentAcquired) {
      reload();
    }
    // we don't want to reload() if reload() changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsContext, consentNeeded, consentAcquired, currentFolder]);

  const navigate = useCallback(
    (folder) => {
      setCurrentFolder(folder);

      let newPath = [...path];
      const index = newPath.indexOf(folder);
      if (index >= 0) {
        // user clicked a breadcrumb
        // so we remove the selection and everything later in the path
        newPath.splice(index);
      }
      setPath([...newPath, folder]);
    },
    [path]
  );

  const signFile = useCallback(
    async (file) => {
      if (file.file.mimeType !== FILE_FORMATS_MAPPED.PDF) {
        setDialogOpen(true);
        return;
      }

      const taskInfo = {
        url: `${window.location.origin}/tasks/self-sign/${file.parentReference.driveId}/${file.parentReference.id}/${file.id}?initialTheme=dark`,
        title: t({ id: 'Common.Catchphrase' }),
        size: {
          height: 530,
          width: 600,
        },
      };
      const submitHandler = ({ err, result }) => {
        if (result?.submitReason === 'success') {
          reload();
        }
      };

      microsoftTeams.dialog.url.open(taskInfo, submitHandler);
    },
    [reload]
  );

  const TabBreadcrumbs = () => (
    <div className="tab-breadcrumbs">
      <Breadcrumb className="tab-breadcrumbs__breadbrumb">
        {teamsContext.channel.id && (
          <BreadcrumbItem key={teamsContext.channel.id}>{teamsContext.channel.displayName}</BreadcrumbItem>
        )}
        {path.map((folder) => (
          <Fragment key={folder.id}>
            <BreadcrumbDivider />
            <BreadcrumbItem>
              <BreadcrumbLink as="button" onClick={() => navigate(folder)}>
                {folder.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Fragment>
        ))}
      </Breadcrumb>
      <Button
        icon={<ArrowCounterclockwise24Regular />}
        title={t({ id: 'Tab.Breadcrumbs.Refresh' })}
        appearance="transparent"
        onClick={() => reload()}
      />
    </div>
  );

  if (!hasLicense) {
    return (
      <Flex column style={{ height: '100%' }}>
        <LicenseError license={user?.license} />
      </Flex>
    );
  }

  return (
    <Flex column style={{ height: '100%', width: '100%' }}>
      <TabBreadcrumbs />
      {loading ? (
        <Spinner />
      ) : (
        <Flex column gap="gap.smaller">
          {teamsContext.channel.id && <FileList files={files} onFileClick={signFile} onFolderClick={navigate} />}
        </Flex>
      )}
      <Dialog open={dialogOpen}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{t({ id: 'Tab.UnsupportedFileTypeDialog.Header' })}</DialogTitle>
            <DialogContent>{t({ id: 'Tab.UnsupportedFileTypeDialog.Content' })}</DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" onClick={() => setDialogOpen(false)}>
                  {t({ id: 'Common.Cancel' })}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </Flex>
  );
}
