export class FileObject {
  constructor({ name, size, teamsFileSource, nativeFile }) {
    this.name = name;
    this.size = size;
    // { driveId, folderId, fileId }
    this.teamsFileSource = teamsFileSource;
    this.nativeFile = nativeFile;
  }

  static async createFromLocal({ name, size, nativeFile }) {
    return new this({ name, size, teamsFileSource: null, nativeFile });
  }

  static async createFromTeamsSource({ name, size, type, lastModified, downloadUrl, teamsFileSource }) {
    const downloadedFile = await fetch(downloadUrl)
      .then((res) => res.blob())
      .then((blob) => new File([blob], name, { type, lastModified }));
    return new this({ name, size, teamsFileSource, nativeFile: downloadedFile });
  }

  static async createFromFileBrowserFile(file) {
    if (file instanceof File) {
      return this.createFromLocal({
        name: file.name,
        size: file.size,
        nativeFile: file,
      });
    } else {
      return this.createFromTeamsSource({
        name: file.name,
        size: file.size,
        type: file.file.mimeType,
        lastModified: +new Date(file.lastModifiedDateTime),
        downloadUrl: file['@microsoft.graph.downloadUrl'],
        teamsFileSource: {
          driveId: file.parentReference.driveId,
          folderId: file.parentReference.id,
          fileId: file.id,
        },
      });
    }
  }
};
