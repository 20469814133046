import { createTableColumn } from '@fluentui/react-components';

export const columnsDetails = [
  { sortable: true, name: 'name', title: 'Dashboard.Table.Column.Name' },
  { sortable: false, name: 'status', title: 'Dashboard.Table.Column.Status' },
  { sortable: true, name: 'flowBeginningDate', title: 'Dashboard.Table.Column.Process.Start.Date' },
  { sortable: true, name: 'expirationTime', title: 'Dashboard.Table.Column.Due.Date', centered: true },
  { sortable: true, name: 'flowEndingDate', title: 'Dashboard.Table.Column.Process.End.Date' },
  { sortable: false, name: 'details', title: 'Dashboard.Table.Column.Details' },
];

export const columns = [
  createTableColumn({
    columnId: 'name',
    compare: (a, b) => a.name.label.localeCompare(b.name.label),
  }),
  createTableColumn({
    columnId: 'status',
  }),
  createTableColumn({
    columnId: 'flowBeginningDate',
    compare: (a, b) => b.flowBeginningDate.date - a.flowBeginningDate.date,
  }),
  createTableColumn({
    columnId: 'expirationTime',
    compare: (a, b) => b.expirationTime.date - a.expirationTime.date,
  }),
  createTableColumn({
    columnId: 'flowEndingDate',
    compare: (a, b) => b.flowEndingDate.date - a.flowEndingDate.date,
  }),
  createTableColumn({
    columnId: 'details',
  }),
];

export const statuses = [
  {
    value: 'Waiting',
    title: 'Dashboard.Table.Filter.Waiting',
    className: 'dashboard-table__status--pending',
    filtrable: false,
  },
  {
    value: 'WaitingForUser',
    title: 'Dashboard.Table.Filter.WaitingForUser',
    className: 'dashboard-table__status--pending',
    filtrable: true,
  },
  {
    value: 'WaitingForOther',
    title: 'Dashboard.Table.Filter.WaitingForOther',
    className: 'dashboard-table__status--pending',
    filtrable: true,
  },
  {
    value: 'Signed',
    title: 'Dashboard.Table.Filter.Signed',
    className: 'dashboard-table__status--signed',
    filtrable: true,
  },
  {
    value: 'NotSigned',
    title: 'Dashboard.Table.Filter.NotSigned',
    className: 'dashboard-table__status--unsigned',
    filtrable: true,
  },
  {
    value: 'Cancelled',
    title: 'Signing.Status.Cancelled',
    className: 'dashboard-table__status--cancelled',
    filtrable: false,
  },
  {
    value: 'Rejected',
    title: 'Signing.Status.Rejected',
    className: 'dashboard-table__status--rejected',
    filtrable: false,
  },
];

export const mainFilterStatuses = [
  { key: 'All', title: 'Dashboard.Table.Filter.All' },
  { key: 'SignedByUser', title: 'Dashboard.Table.Filter.SignedByYou' },
  { key: 'SentByUser', title: 'Dashboard.Table.Filter.SendForSigning' },
  { key: 'Archived', title: 'Dashboard.Table.Filter.Archive' },
];

export const dayAndHourFormatterOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const dayFormatterOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const config = {
  sortBy: 'FlowBeginningDate',
  sortingOrder: -1,
}
