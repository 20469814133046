import { Verification } from './Verification';
import { Signing } from './Signing';
import { Welcome } from './Welcome';
import { StageContextProvider, useStageContext } from './StageContext';
import './Stage.scss';

const StageInner = () => {
  const {
    stageState: { signingInProgress, verificationInProgress },
  } = useStageContext();

  if (verificationInProgress) {
    return <Verification />;
  }

  if (signingInProgress) {
    return <Signing />;
  }

  return <Welcome />;
};

export const Stage = () => {
  return (
    <div className="meeting-stage">
      <StageContextProvider>
        <StageInner />
      </StageContextProvider>
    </div>
  );
};
