import { Switch } from '@fluentui/react-switch';
import { t } from '@lingui/macro';

export const DetailsSwitch = ({ checked, onChange, disabled = false }) => {
  return (
    <Switch
      label={t({ id: 'VerificationSummaryTable.DetailsSwitch' })}
      labelPosition="before"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
