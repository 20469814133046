import { Button, Checkbox, Input, Text } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { Delete16Regular, Warning16Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { useState, useEffect } from 'react';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { FilesIcon } from 'shared-fe-components/src/common/FilesIcon';
import { extractFileFormat, extractFileName } from 'shared-fe-components/src/utils';
import { format as formatBytes } from 'bytes';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import './FileRepresentation.scss';

const applyFilenameConstraints = (filename: string) => {
  return extractFileName(filename.substring(0, 255));
};

interface Props {
  filename: string;
  errorMessage?: string | null;
  nativeFile: Blob;
  editing?: { fieldName: string; isEditing: boolean };
  selection?: { fieldName: string } | null;
  order?: { number: number; dragHandleProps: Object };
  onDelete: () => void;
}

export const FileRepresentation = ({ filename, nativeFile, editing, order, selection, onDelete }: Props) => {
  const [blobUrl, setBlobUrl] = useState(null);
  const formContext = useFormContext();
  const { register, getValues, setValue, control } = formContext;
  const isSelectedFormValue = useWatch({ control, name: selection?.fieldName });
  const currentFilenameInputValue = useWatch({ control, name: editing?.fieldName });
  const [fileFormat, setFileFormat] = useState('');

  const filenameInputErrorMessage =
    currentFilenameInputValue?.length === 0 ? t({ id: 'FilesMergeConvert.Error.FilenameEmpty' }) : null;

  useEffect(() => {
    setFileFormat(fileFormat);
  }, []);

  useEffect(() => {
    const blob = URL.createObjectURL(nativeFile);
    setBlobUrl(blob);
    return () => {
      URL.revokeObjectURL(blob);
    };
  }, [nativeFile]);

  const toggleIsSelected = () => {
    const currentValue = getValues(selection.fieldName);
    setValue(selection.fieldName, !currentValue);
  };

  return (
    <div className="file-representation">
      <div
        className={`file-representation__file ${filenameInputErrorMessage ? 'file-representation__file--error' : ''}`}
      >
        <div className="file-representation__columns">
          <div className="file-representation__left-column">
            {selection && (
              <div className="file-representation__selection">
                <input {...register(selection.fieldName)} hidden />
                <Checkbox checked={!!isSelectedFormValue} onClick={() => toggleIsSelected()} />
              </div>
            )}

            {order && (
              <div className="file-representation__order" {...order.dragHandleProps}>
                <div>#</div>
                <div>{order.number + 1}</div>
              </div>
            )}

            <div className="file-representation__left-column-content">
              <FilesIcon size="large" fileType={extractFileFormat(filename)} />
              <Text className="file-representation__file-size">{formatBytes(nativeFile.size)}</Text>
            </div>
          </div>

          <div className="file-representation__center-column">
            <div className="file-representation__center-column-content">
              <div className="file-representation__filename">
                {!editing?.isEditing && (
                  <div className="file-representation__filename-text">
                    <Text>{filename}</Text>
                  </div>
                )}
                {editing?.isEditing && (
                  <Controller
                    name={editing.fieldName}
                    control={control}
                    rules={{ required: { message: t({ id: 'FilesMergeConvert.Error.FilenameEmpty' }), value: true } }}
                    render={({ field }) => (
                      <Input {...field} value={applyFilenameConstraints(field.value)} inverted fluid />
                    )}
                  />
                )}
              </div>

              <Anchor className="file-representation__preview" href={blobUrl} download={filename}>
                {t({ id: 'Form.FilesInputs.PreviewFile' })}
              </Anchor>
            </div>
          </div>

          <div className="file-representation__right-column">
            <Button
              icon={<Delete16Regular />}
              title={t({ id: 'Form.FilesInputs.Delete' })}
              onClick={onDelete}
              appearance="transparent"
            />
          </div>
        </div>
      </div>

      {filenameInputErrorMessage && (
        <div className="file-representation__error">
          <Alert intent="error" icon={<Warning16Regular />}>
            {filenameInputErrorMessage}
          </Alert>
        </div>
      )}
    </div>
  );
};
