import { Flex } from '@fluentui/react-migration-v0-v9';
import { Button, Spinner, Text } from "@fluentui/react-components";
import { ChevronDown16Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { FileBrowserBreadcrumbs } from './FileBrowserBreadcrumbs';
import { FileBrowserFilesList } from './FileBrowserFilesList';
import { FileBrowserFilesError } from './FileBrowserFilesError';
import PropTypes from 'prop-types';
import './FileBrowserFiles.scss';

export const FileBrowserFiles = ({
  data = [],
  loading = false,
  error = null,
  selectedFolders = [],
  ordering = 1,
  allowedFileTypes = null,
  onRetry = null,
  onOrderingClick,
  onFileSelect,
  onBreadcrumbClick,
  onFolderSelect,
}) => (
  <div className="file-browser-files__container">
    <div>
      <FileBrowserBreadcrumbs folders={selectedFolders} onClick={onBreadcrumbClick} />
    </div>

    <div className="file-browser-files__scrollable">
      <div className="file-browser-files__content">
        {loading ? (
          <Flex fill vAlign="center" hAlign="center">
            <Spinner />
          </Flex>
        ) : error ? (
          <FileBrowserFilesError error={error} onRetry={onRetry} />
        ) : !data?.length ? (
          <Flex fill vAlign="center" hAlign="center">
            <Text size="400" className="file-browser-files__no-content">{t({ id: 'FileBrowser.NoContent' })}</Text>
          </Flex>
        ) : (
          <>
            <Button
              appearance="transparent"
              iconPosition="after"
              style={{justifyContent: 'flex-start'}}
              icon={<ChevronDown16Regular rotate={ordering > 0 ? 180 : 0} />}
              size="small"
              onClick={onOrderingClick}
            >
              {t({ id: 'FileBrowser.SortByName' })}
            </Button>

            <FileBrowserFilesList
              values={data}
              ordering={ordering}
              allowedFileTypes={allowedFileTypes}
              onFolderSelect={onFolderSelect}
              onFileSelect={onFileSelect}
            />
          </>
        )}
      </div>
    </div>
  </div>
);

FileBrowserFiles.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        file: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        folder: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ])
  ),
  loading: PropTypes.bool,
  error: PropTypes.object,
  selectedFolders: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ])
  ),
  ordering: PropTypes.number,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  onRetry: PropTypes.func,
  onOrderingClick: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onBreadcrumbClick: PropTypes.func.isRequired,
  onFolderSelect: PropTypes.func.isRequired,
};
