import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import './DashboardRequestSignatureEditModals.scss';
export const DashboardRequestSignatureEditLoader = () => (
  <>
    <div className="modal-overlay" />
    <div className="alert-wrapper">
      <LoadingScreen />
    </div>
  </>
);
