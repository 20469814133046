import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { useMeetingContext } from 'contexts';
import { meeting as teamsMeeting } from '@microsoft/teams-js';
import {
  buttonDisabled,
  buttonDisabledAndLoading,
  State,
  Action,
  SidePanelContextType,
} from './SidePanelContext.types';
import * as api from 'shared-fe-components/src/api';

const sidePanelInitialState: State = {
  startSigningButtonProps: buttonDisabledAndLoading,
  startVerificationButtonProps: buttonDisabledAndLoading,
  signingInProgress: false,
  verificationInProgress: false,
  signingStatusApiResponse: null,
  verificationStatusApiResponse: null,
  signingStatusSummary: null,
  verificationStatusSummary: null,
};

const sidePanelReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'IDLE':
      return {
        ...state,
        startSigningButtonProps: {},
        startVerificationButtonProps: {},
        signingInProgress: false,
        verificationInProgress: false,
      };
    case 'SIGNING_STATUS_UPDATE':
      return {
        ...state,
        signingStatusApiResponse: action.signingStatusApiResponse,
        signingStatusSummary: action.signingStatusSummary,
      };
    case 'VERIFICATION_STATUS_UPDATE':
      return {
        ...state,
        verificationStatusApiResponse: action.verificationStatusApiResponse,
        verificationStatusSummary: action.verificationStatusSummary,
      };
    case 'SIGNING_BUSY':
      return {
        ...state,
        startSigningButtonProps: buttonDisabledAndLoading,
        startVerificationButtonProps: buttonDisabled,
      };
    case 'VERIFICATION_BUSY':
      return {
        ...state,
        startVerificationButtonProps: buttonDisabledAndLoading,
        startSigningButtonProps: buttonDisabled,
      };
    case 'SIGNING_IN_PROGRESS':
      return {
        ...state,
        startSigningButtonProps: buttonDisabled,
        startVerificationButtonProps: buttonDisabled,
        signingInProgress: true,
      };
    case 'VERIFICATION_IN_PROGRESS':
      return {
        ...state,
        startSigningButtonProps: buttonDisabled,
        startVerificationButtonProps: buttonDisabled,
        verificationInProgress: true,
      };
  }
};

const SidePanelContext = createContext<SidePanelContextType | null>(null);

export const SidePanelContextProvider = ({ children }) => {
  const [sidePanelState, sidePanelDispatch] = useReducer(sidePanelReducer, sidePanelInitialState);
  const { meeting } = useMeetingContext() as any;

  const shareToStage = () => {
    teamsMeeting.shareAppContentToStage(() => {}, window.location.href);
  };

  const updateSigningStatus = useCallback(async (initialUpdate=false) => {
    const sessions = await api.meeting.getSigningSessions(meeting.id);
    const pendingSessions = sessions.filter((session) => ['NotStarted', 'InProgress'].includes(session?.status));
    const statuses = [...new Set(pendingSessions.map((session) => session.signatures).flat())];
    const summary = {
      pending: statuses?.filter((x: any) => !['Succeeded', 'Failed'].includes(x.status)).length,
      succeeded: statuses?.filter((x: any) => x.status === 'Succeeded').length,
      failed: statuses?.filter((x: any) => x.status === 'Failed').length,
    };
    sidePanelDispatch({
      type: 'SIGNING_STATUS_UPDATE',
      signingStatusApiResponse: sessions,
      signingStatusSummary: summary,
    });

    const pendingSessionsExist = pendingSessions.length > 0;
    if (!initialUpdate && !pendingSessionsExist) {
      sidePanelDispatch({ type: 'IDLE' });
    }

    return pendingSessionsExist;
  }, [meeting.id]);

  const updateVerificationStatus = useCallback(async (initialUpdate=false) => {
    const session = await api.meeting.getVerificationSession(meeting.id);
    const verifications = session?.verifications ?? [];
    const summary = {
      pending: verifications.filter((x: any) => !['Succeeded', 'Failed'].includes(x.status)).length,
      succeeded: verifications.filter((x: any) => x.status === 'Succeeded').length,
      failed: verifications.filter((x: any) => x.status === 'Failed').length,
    };
    sidePanelDispatch({
      type: 'VERIFICATION_STATUS_UPDATE',
      verificationStatusApiResponse: session,
      verificationStatusSummary: summary,
    });

    // checking if we're done
    const pendingSessionsExist = session?.status === 'InProgress';
    if (!initialUpdate && !pendingSessionsExist) {
      sidePanelDispatch({ type: 'IDLE' });
    }

    return pendingSessionsExist;
  }, [meeting.id]);

  useEffect(() => {
    Promise.all([updateVerificationStatus(true), updateSigningStatus(true)]).then(([verificationStarted, signingStarted]) => {
      if (verificationStarted) {
        sidePanelDispatch({ type: 'VERIFICATION_IN_PROGRESS' });
      } else if (signingStarted) {
        sidePanelDispatch({ type: 'SIGNING_IN_PROGRESS' });
      } else {
        sidePanelDispatch({ type: 'IDLE' });
      }
    });
  }, [updateSigningStatus, updateVerificationStatus]);

  useEffect(() => {
    if (sidePanelState.signingInProgress) {
      updateSigningStatus();
      const interval = setInterval(updateSigningStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [updateSigningStatus, sidePanelState.signingInProgress]);

  useEffect(() => {
    if (sidePanelState.verificationInProgress) {
      updateVerificationStatus();
      const interval = setInterval(updateVerificationStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [updateVerificationStatus, sidePanelState.verificationInProgress]);

  return (
    <SidePanelContext.Provider
      value={{ sidePanelState, sidePanelDispatch, updateSigningStatus, updateVerificationStatus, shareToStage }}
    >
      {children}
    </SidePanelContext.Provider>
  );
};

export const useSidePanelContext = () => {
  const ctx = useContext(SidePanelContext);
  if (!ctx) {
    throw new Error('Lack of SidePanelProvider in components tree');
  }
  return ctx;
};
