import { useEffect, useMemo, useState } from 'react';
import { useStageContext } from '../StageContext';
import { useDateFormatter } from 'lib/useDateFormatter';
import { InperlyLogo } from 'shared-fe-components/src/common/Icons';
import { Text } from 'shared-fe-components/src/common/FluentMigration';
import { DetailsSwitch, VerificationSummaryTable } from 'components/Meeting/VerificationSummaryTable';
import { OrganizerGuard } from 'components/Meeting/Utils';
import { t } from '@lingui/macro';
import './Verification.scss';

const dateFormatterOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const Verification = () => {
  const [verifications, setVerifications] = useState();
  const [expandDetails, setExpandDetails] = useState(false);
  const [switchDisabled, setSwitchDisabled] = useState(true);
  const {
    stageState: { verificationStatusApiResponse, verificationFetchTimestamp, verificationStatusSummary },
  } = useStageContext();

  useEffect(() => {
    if (verificationStatusApiResponse?.verifications) {
      setVerifications(verificationStatusApiResponse.verifications);
      setSwitchDisabled(verificationStatusSummary.succeeded === 0);
    }
  }, [verificationStatusApiResponse, verificationStatusSummary]);

  const dateFormatter = useDateFormatter(dateFormatterOptions);
  const updateTime = useMemo(
    () => (verificationFetchTimestamp ? dateFormatter(new Date(verificationFetchTimestamp)) : ''),
    [dateFormatter, verificationFetchTimestamp]
  );

  return (
    <div className="stage-verification">
      <div className="stage-verification__header">
        <InperlyLogo className="stage-verification__header-logo" />
        <Text as="h1" className="stage-verification__header-middle">
          {t({ id: 'Meeting.Stage.Verification.Title' })}
        </Text>
        <div>
          <OrganizerGuard>
            <DetailsSwitch
              checked={expandDetails}
              onChange={() => setExpandDetails((v) => !v)}
              disabled={switchDisabled}
            />
          </OrganizerGuard>
        </div>
      </div>
      {verifications && <VerificationSummaryTable verifications={verifications} expandDetails={expandDetails} />}
      <Text disabled>{updateTime}</Text>
    </div>
  );
};
