import { useEffect, useCallback, useRef } from 'react';
import { useGraph } from '../../../../lib/useGraph';
import { useGraphConsent } from '../../../../lib/useGraphConsent';

const fetchFileList = async (graph, source, folderId = null) => {
  let values = [];
  let next = folderId ? `/${source}/drive/items/${folderId}/children` : `/${source}/drive/root/children`;

  while (next) {
    const result = await graph.api(next).get();

    values = [...values, ...result.value];
    next = result['@odata.nextLink'];
  }

  return values;
};

const scope = ['Files.Read.All'];
const graphConsentOptions = {
  scope,
};
const graphOptions = {
  scope,
  initialState: { loading: true },
};

const useFileList = (source, folderId) => {
  const { consentAcquired } = useGraphConsent(graphConsentOptions);

  const fetchFileListBound = useCallback((graph) => fetchFileList(graph, source, folderId), [source, folderId]);

  const { loading, reload, data, error } = useGraph(fetchFileListBound, graphOptions);

  const reloadRef = useRef();
  reloadRef.current = reload;

  useEffect(() => {
    if (consentAcquired) {
      reloadRef.current();
    }
  }, [consentAcquired, source, folderId]);

  return { loading, reload, data, error };
};

export const useCloudFileList = (folderId) => useFileList('me', folderId);
export const useTeamChannelFileList = (teamId, folderId) => useFileList(`groups/${teamId}`, folderId);
