import { Button } from '@fluentui/react-components';
import { Dismiss16Regular, Add16Regular } from '@fluentui/react-icons';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { people } from '@microsoft/teams-js';
import { moduleName } from '.';
import { MAX_RECIPIENTS } from '../Utils';
import { t } from '@lingui/macro';
import { Email, Languages } from '../DecoratedInputs';
import './CCRecipients.scss';

export const CCRecipients = ({ deleteFirstIfEmpty = true, userEmailAddress, isDisabled, setIsFormDirty }) => {
  const formContext = useFormContext();
  const {
    trigger,
    formState: { submitCount },
  } = formContext;

  const { fields, append, remove } = useFieldArray({
    control: formContext.control,
    name: moduleName,
  });

  const removeRecipient = (index) => {
    if (deleteFirstIfEmpty || fields.length > 1) {
      remove(index);
    }
    if (submitCount !== 0) trigger();
  };

  const addFromOrganization = async () => {
    try {
      const selectedPeople = await people.selectPeople();
      const selectedEmails = selectedPeople.map((person) => ({ value: person.email }));
      append(selectedEmails, { shouldFocus: false });
    } catch (error) {
      // Cancelling throws an error, but it's not a problem in our case
      if (error.errorCode !== 8000) {
        console.error(error);
      }
    }
  };

  const addUserEmailAddress = () => {
    append({ value: userEmailAddress }, { shouldFocus: false });
  };

  const addNewEmailField = () => {
    append({ value: '' });
  };

  const emailConfig = {
    methods: {
      // addRecipient: () => {
      //   const hasEmptyRecipientRow = getValues(moduleName).some((row) => row.value === '');
      //   if (!hasEmptyRecipientRow) append({ value: '' });
      // },
      // Keeping this code, this feature might be used in the future
    },
    validation: {
      isRequired: true,
    },
  };

  return (
    <div>
      {fields.map((row, index) => {
        return (
          <div className="cc-recipient-rows" key={row.id}>
            <div className="cc-recipient-rows__inputs">
              <Email index={index} config={emailConfig} moduleName={moduleName} isDisabled={isDisabled} />
              <Languages index={index} moduleName={moduleName} setIsFormDirty={setIsFormDirty} />
            </div>
            <Button
              icon={<Dismiss16Regular />}
              appearance="transparent"
              iconOnly
              title={t({ id: 'Form.EmailsInputs.RemoveField' })}
              disabled={isDisabled}
              onClick={() => removeRecipient(index)}
            />
          </div>
        );
      })}
      {fields.length < MAX_RECIPIENTS && !isDisabled && (
        <Button
          className="cc-recipients__action-button"
          appearance="transparent"
          icon={<Add16Regular />}
          onClick={addNewEmailField}
        >
          {t({ id: 'Form.EmailsInputs.AddField.CcRecipient' })}
        </Button>
      )}
      {userEmailAddress && fields.length < MAX_RECIPIENTS && !isDisabled && (
        <Button
          className="cc-recipients__action-button"
          appearance="transparent"
          icon={<Add16Regular />}
          onClick={addUserEmailAddress}
        >
          {t({ id: 'Form.EmailsInputs.AddField.MyEmailAddress' })}
        </Button>
      )}
      {fields.length < MAX_RECIPIENTS && !isDisabled && (
        <Button
          className="cc-recipients__action-button"
          appearance="transparent"
          onClick={addFromOrganization}
          icon={<Add16Regular />}
        >
          {t({ id: 'Form.EmailsInputs.AddField.FromOrganization' })}
        </Button>
      )}
    </div>
  );
};
