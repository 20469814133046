import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { WizardTintedBox } from 'shared-fe-components/src/common/WizardBox';
import { CheckIcon } from 'shared-fe-components/src/common/Icons';

export const DashboardRequestSignatureEditSuccess = ({ onClose }: { onClose: () => void }) => (
  <>
    <div className="modal-overlay" />
    <div className="alert-wrapper alert-success">
      <WizardTintedBox color="brand" hideHeader={true} hideFooter={true} role="alert">
        <div></div>
        <div>
          <CheckIcon />
          <Text size={600} weight="semibold">
            {t({ id: 'EditSessionRequest.Success.Header' })}
          </Text>
          <Button appearance="outline" onClick={onClose}>
            {t({ id: 'Common.Close' })}
          </Button>
        </div>
        <div></div>
      </WizardTintedBox>
    </div>
  </>
);
