import * as api from 'shared-fe-components/src/api';

export const useUploadOnStorage = () => {
  const putItOnStorage = async (files) => {
    let isError = false;
    const fileNames = files.map((file) => ({ fileName: file.name }));

    const container = await api.merging.requestContainer({ documents: fileNames });

    const filesWithUrls = files.map((file, index) => ({
      fileData: file.nativeFile,
      url: container.documents[index].uploadUrl,
    }));

    const uploads = filesWithUrls.map((file) => api.merging.uploadFile(file.url, file.fileData));
    await Promise.all(uploads).catch((err) => {
      isError = true;
      console.error(err);
    });

    return { container, isError };
  };

  return { putItOnStorage };
};
