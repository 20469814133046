import { Button } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { t } from '@lingui/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { cloudOneDrive } from '../constants';
import { useTeamList } from '../../../../lib/useTeamList';
import './FileBrowserSidebar.scss';

const SidebarMenuItem = ({ active = false, size = 'normal', className = '', content = '', ...props }) => (
  <Button
    fluid
    className={[
      `file-browser-sidebar__menu-item`,
      active ? 'file-browser-sidebar__menu-item--active' : '',
      `file-browser-sidebar__menu-item--${size}`,
      className,
    ].join(' ')}
    {...props}
  >
    {content}
  </Button>
);

export const FileBrowserSidebar = ({
  activeGroup = null,
  onClickLocalMachine,
  onClickTeam,
  onClickCloud,
  className = '',
}) => {
  // const { loading, data } = useTeamList();

  return (
    <aside className={`file-browser-sidebar__container ${className}`}>
      <SidebarMenuItem
        active={!activeGroup}
        size="large"
        content={t({ id: 'FileBrowser.Sidebar.LocalMachine.Title' })}
        onClick={onClickLocalMachine}
      />

      {/* <Header as="h2" content={t({ id: 'FileBrowser.Sidebar.Teams.Title' })} />
      {loading ? (
        <LoadingScreen />
      ) : (
        <ul>
          {(data ?? []).map((team) => (
            <li key={team.id}>
              <SidebarMenuItem
                active={activeGroup === team}
                content={team.displayName}
                onClick={() => onClickTeam(team)}
              />
            </li>
          ))}
        </ul>
      )} */}

      {/* <Header as="h2" content={t({ id: 'FileBrowser.Sidebar.Cloud.Title' })} />
      <ul>
        <li key={cloudOneDrive.id}>
          <SidebarMenuItem
            active={activeGroup === cloudOneDrive}
            content={t({ id: 'FileBrowser.Sidebar.Cloud.OneDrive' })}
            onClick={() => onClickCloud(cloudOneDrive)}
          />
        </li>
      </ul> */}
    </aside>
  );
};

FileBrowserSidebar.propTypes = {
  activeGroup: PropTypes.object,
  className: PropTypes.string,
  onClickLocalMachine: PropTypes.func.isRequired,
  onClickTeam: PropTypes.func.isRequired,
  onClickCloud: PropTypes.func.isRequired,
};
