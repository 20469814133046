import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Text,
  Tooltip,
} from '@fluentui/react-components';
import { Edit24Regular } from '@fluentui/react-icons';
import { Props, Details, dateFormatterOptions, statusClasses, statuses } from './models';
import * as api from 'shared-fe-components/src/api';
import { t } from '@lingui/macro';
import { FilesIcon } from 'shared-fe-components/src/common/FilesIcon';
import {
  Info24Regular,
  CalendarWeekStart24Regular,
  CalendarLtr24Regular,
  Archive24Regular,
  BinRecycle24Regular,
  ArrowCounterclockwise24Regular,
} from '@fluentui/react-icons';
import { useSessionStatisticsContext } from 'contexts';
import { useDateFormatter } from 'lib/useDateFormatter';
import { extractFileFormat } from 'shared-fe-components/src/utils';
import { ProcessDetailsParticipantTable } from './ProcessDetailsParticipantTable';
import { Dismiss24Regular } from '@fluentui/react-icons';
import './ProcessDetailsBody.scss';
import { LoadingScreen } from 'components/Common/LoadingScreen';
import { EidEasyProviders } from 'shared-fe-components/src/providers/ProviderSelection/EidEasyService';

export const ProcessDetailsBody = ({
  sessionId,
  name,
  canCancel,
  startDate,
  fileName,
  setMadeChanges,
  onEditSignatureClick,
  closeDetails,
}: Props) => {
  const { fetchSessionStatistics } = useSessionStatisticsContext();
  const dateFormatter = useDateFormatter(dateFormatterOptions);

  const [details, setDetails] = useState<Details | null>(null);
  const [loadingLabel, setLoadingLabel] = useState<null | string>(null);
  const [actionResult, setActionResult] = useState<null | { action: string; message: string }>(null);

  const fetchProcessDetails = useCallback(async () => {
    const sessionState = await api.signing.getSessionState(sessionId);

    if (!sessionState) {
      console.warn('Lack of process details');
      return;
    }

    const { archived, status, signatures, endTime, expirationTime, result, signatureType, meetingId } = sessionState;
    setDetails({
      archived,
      status,
      signatures,
      endTime,
      expirationTime: expirationTime ? dateFormatter(new Date(expirationTime)) ?? '' : '',
      result,
      signatureType,
      meetingId,
    });
    fetchSessionStatistics();
  }, [sessionId]);

  useEffect(() => {
    fetchProcessDetails();
  }, []);

  const notEditable = useMemo(() => {
    return (
      ['Signed', 'Expired', 'Rejected', 'Cancelled', 'Finished', 'NotSigned'].includes(details?.status ?? '') ||
      details?.signatureType === 'SelfSign' ||
      details?.meetingId !== null
    );
  }, [details]);

  const signaturesFormatted = useMemo(() => {
    if (details === null || !details.signatures) return [];

    return details.signatures.map((signature: any) => {
      const eidEasyKey = signature.operationState?.signatureProvider?.eidEasyState?.selectedSigningMethod;
      const eidEasy = eidEasyKey ? EidEasyProviders?.[`EidEasy:${eidEasyKey}`]?.name : null;
      const regularProviderKey = signature.operationState?.signatureProvider?.type;
      const regularProvider =
        regularProviderKey && regularProviderKey !== 'EidEasy'
          ? t({ id: `Providers.${regularProviderKey}.Name` })
          : null;
      return {
        ...signature,
        provider: eidEasy ?? regularProvider ?? '',
        date:
          signature.status === 'Rejected' || signature.status === 'Cancelled'
            ? details.endTime
            : signature.status === 'Succeeded'
            ? signature.signatureDate
            : '',
        statusClass: statuses[signature.status],
      };
    });
  }, [details]);

  const onAction = async (actionType: string, sessionId: string) => {
    setLoadingLabel(t({ id: `ProcessDetails.Loading.${actionType}` }));
    try {
      switch (actionType) {
        case 'Archive': {
          await api.signing.archiveSigningSession(sessionId);
          await fetchProcessDetails();
          break;
        }
        case 'Unarchive': {
          await api.signing.unarchiveSigningSession(sessionId);
          await fetchProcessDetails();
          break;
        }
        case 'Delete': {
          await api.signing.deleteSigningSession(sessionId);
          closeDetails(true);
          break;
        }
        case 'Cancel': {
          await api.signing.cancelSigningSession(sessionId);
          await fetchProcessDetails();
          break;
        }
        default:
          console.warn(`Action ${actionType} does not exist.`);
      }
    } catch (err: any) {
      /**Must be any type cause all functions return Promise<any> */
      if (actionType === 'Cancel' && err.response?.status === 409) {
        setActionResult({ action: actionType, message: t({ id: 'ProcessDetails.Action.Cancel.409' }) });
      } else {
        setActionResult({ action: actionType, message: t({ id: `ProcessDetails.Action.${actionType}.Fail` }) });
      }
    } finally {
      setMadeChanges(true);
      setLoadingLabel(null);
    }
  };

  return details === null || !!loadingLabel ? (
    <DialogContent>
      <LoadingScreen label={loadingLabel ?? t({ id: 'ProcessDetails.Loading.Details' })} backgroundColor="none" />
    </DialogContent>
  ) : (
    <>
      <DialogTitle
        action={
          <DialogTrigger action="close">
            <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
          </DialogTrigger>
        }
      >
        <div className="process-details-body__header">
          <Info24Regular />
          <div className="process-details-body__header-subtitle">
            <Text size={600} weight="bold">
              {name}
            </Text>
            <Text size={300} weight="regular">
              {t({ id: 'ProcessDetails.Header.Subtitle' })}
            </Text>
          </div>

          <Button
            icon={<Edit24Regular />}
            appearance="transparent"
            disabled={notEditable}
            onClick={() => onEditSignatureClick(sessionId)}
          />

          <Button className="process-details__pill" appearance="outline" size="small" shape="circular">
            <Text
              size={200}
              weight="semibold"
              className={`process-details-body__header-pill--${
                details.result ? statusClasses[details.result] : statusClasses[details.status]
              }`}
            >
              {t({ id: `ProcessDetails.Status.${details.result ?? details.status}` })}
            </Text>
          </Button>

          {details.archived && (
            <Button appearance="outline" size="small" shape="circular">
              <Text size={200} weight="semibold">
                {t({ id: 'ProcessDetails.Status.Archived' })}
              </Text>
            </Button>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="process-details-body__container">
          <div className="process-details-body__sign-type">
            <Text size={300} weight="semibold">
              {t({ id: 'ProcessDetails.SignatureModeLabel' })}
            </Text>
            <Text size={300} weight="regular">
              {t({ id: `ProcessDetails.SignatureMode.${details.signatureType}` })}
            </Text>

            {canCancel && details.status !== 'Cancelled' && (
              <Dialog>
                <DialogTrigger>
                  <Button appearance="outline" size="small" className="process-details-body__sign-type-cancel">
                    <Text size={200} weight="semibold">
                      {t({ id: 'CancelSignProcess.TriggerButton' })}
                    </Text>
                  </Button>
                </DialogTrigger>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle>{t({ id: 'CancelSignProcess.Header' })}</DialogTitle>
                    <DialogContent>
                      {<p style={{ whiteSpace: 'pre-wrap' }}>{t({ id: 'CancelSignProcess.Content' })}</p>}
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button appearance="secondary">{t({ id: 'Common.Cancel' })}</Button>
                      </DialogTrigger>
                      <Button appearance="primary" onClick={() => onAction('Cancel', sessionId)}>
                        {t({ id: 'CancelSignProcess.ConfirmButton' })}
                      </Button>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            )}
          </div>

          <div className="process-details-body__spacing">
            <Text size={300} weight="semibold">
              {`${t({ id: 'ProcessDetails.ProcessIdLabel' })} ${sessionId}`}
            </Text>
          </div>

          <div className="process-details-body__spacing">
            <Text size={300} weight="semibold">
              {t({ id: 'ProcessDetails.SectionName.TimeFrame' })}
            </Text>
          </div>

          <div className="process-details-body__spacing process-details-body__time-container">
            <div className="process-details-body__time">
              <CalendarWeekStart24Regular className="process-details-body__primary" />
              <Text className="process-details-body__primary" size={300} weight="semibold">
                {t({ id: 'ProcessDetails.TimeFrame.ProcessStarted' })}
              </Text>
              <Text size={300} weight="regular">
                {dateFormatter(startDate)}
              </Text>
            </div>

            {details.signatureType === 'SelfSign' ||
            details.meetingId !== null ||
            ['Signed', 'Cancelled'].includes(details.status) ? (
              <></>
            ) : (
              <div className="process-details-body__time">
                <CalendarLtr24Regular className="process-details-body__primary" />
                <Text className="process-details-body__primary" size={300} weight="semibold">
                  {t({ id: 'ProcessDetails.TimeFrame.PlannedEndDate' })}
                </Text>
                <Text size={300} weight="regular">
                  {details.expirationTime}
                </Text>
              </div>
            )}
          </div>

          <div className="process-details-body__spacing">
            <Text size={300} weight="semibold">
              {t({ id: 'ProcessDetails.SectionName.Files' })}
            </Text>
          </div>

          <div className="process-details-body__file process-details-body__spacing">
            <FilesIcon fileType={extractFileFormat(fileName)} />
            <Text className="process-details-body__file-name" title={fileName}>
              {fileName}
            </Text>
          </div>

          <div className="process-details-body__spacing">
            <Text size={300} weight="semibold">
              {t({ id: 'ProcessDetails.SectionName.Recipients' })}
            </Text>
          </div>

          <div className="process-details__participant-table process-details-body__spacing">
            <ProcessDetailsParticipantTable sessionId={sessionId} items={signaturesFormatted} />
          </div>
        </div>
      </DialogContent>

      <Dialog open={actionResult !== null}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{t({ id: 'ProcessDetails.Action.Header' })}</DialogTitle>
            <DialogContent>{actionResult?.message}</DialogContent>
            <DialogActions>
              <DialogTrigger>
                <Button appearance="secondary" onClick={() => setActionResult(null)}>
                  {t({ id: 'Common.OK' })}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <DialogActions>
        <div className="process-details-body__buttons">
          {details.archived ? (
            <Tooltip content={t({ id: 'ProcessDetails.UnarchiveButton' })} relationship="label">
              <Button
                appearance="transparent"
                icon={<ArrowCounterclockwise24Regular />}
                onClick={() => onAction('Unarchive', sessionId)}
              />
            </Tooltip>
          ) : (
            <Tooltip content={t({ id: 'ProcessDetails.ArchiveButton' })} relationship="label">
              <Button
                appearance="transparent"
                disabled={details.status === 'InProgress'}
                icon={<Archive24Regular />}
                onClick={() => onAction('Archive', sessionId)}
              />
            </Tooltip>
          )}
          <Dialog>
            <DialogTrigger>
              <Tooltip content={t({ id: 'ProcessDetails.DeleteButton' })} relationship="label">
                <Button
                  disabled={details.status === 'InProgress'}
                  appearance="transparent"
                  icon={<BinRecycle24Regular />}
                />
              </Tooltip>
            </DialogTrigger>
            <DialogSurface>
              <DialogBody>
                <DialogTitle>{t({ id: 'ProcessDetails.DeleteDialogHeader' })}</DialogTitle>
                <DialogContent>{t({ id: 'ProcessDetails.DeleteDialogMessage', values: { name } })}</DialogContent>
                <DialogActions>
                  <DialogTrigger>
                    <Button appearance="secondary">{t({ id: 'Common.Cancel' })}</Button>
                  </DialogTrigger>
                  <Button appearance="primary" onClick={() => onAction('Delete', sessionId)}>
                    {t({ id: 'ProcessDetails.DeleteConfirm' })}
                  </Button>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        </div>
      </DialogActions>
    </>
  );
};
