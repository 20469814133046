import { useFormContext } from 'react-hook-form';
import { Alert } from '@fluentui/react-components/unstable';
import { Warning16Regular } from '@fluentui/react-icons';


interface FormCheckProps {
    fieldName: string,
    validateFn: () => void
}
export const FormCheck = ({ fieldName, validateFn }: FormCheckProps) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <>
            <input {...register(fieldName, { validate: (v: unknown) => validateFn() })} hidden />
            {errors?.[fieldName]?.message && (
                <Alert intent="error" icon={<Warning16Regular />}>{errors?.[fieldName]?.message}</Alert>
            )}
        </>
    );
};