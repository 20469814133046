import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { usePromptQueue } from '../../lib/retriable';

export function RetryPrompts() {
  const queue = usePromptQueue();

  return (
    <>
      {queue.map(({ key, onCancel, onConfirm }) => (
        <Dialog key={key} open={true} modalType="alert">
          <DialogSurface>
            <DialogBody>
              <DialogTitle>{t({ id: 'Retriable.Prompt.Header' })}</DialogTitle>
              <DialogContent>{t({ id: 'Retriable.Prompt.Content' })}</DialogContent>
              <DialogActions>
                <Button appearance="secondary" onCancel={onCancel}>
                  {t({ id: 'Common.Cancel' })}
                </Button>
                <Button appearance="primary" onCancel={onConfirm}>
                  {t({ id: 'Common.Retry' })}
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      ))}
    </>
  );
}
