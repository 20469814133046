import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { VerificationErrorIcon } from 'shared-fe-components/src/common/Icons';
import PropTypes from 'prop-types';

export const DashboardFileSigningError = ({ errorDescription, onClose }) => {
  return (
    <>
      <div className="file-signing-error-screen">
        <VerificationErrorIcon />
        <Text as="h1" size={600} weight="semibold">
          {t({ id: 'Dashboard.Signing.Headers.ErrorOccured' })}
        </Text>
        <Text size={400} weight="regular">
          {errorDescription}
        </Text>
      </div>
      <div className="file-signing-error-screen__button">
        <Button onClick={onClose}>{t({ id: 'Dashboard.Signing.Button.BackToDashboard' })}</Button>
      </div>
    </>
  );
};

DashboardFileSigningError.propTypes = {
  onClose: PropTypes.func.isRequired,
};
