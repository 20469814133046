import { useRef } from 'react';
import { DatePicker as Datepicker } from '@fluentui/react-datepicker-compat';
import { t } from '@lingui/macro';
import { useCallback } from 'react';
import { CalendarRtlRegular } from '@fluentui/react-icons';

interface DatePickerProps {
  autoWidth: boolean;
  rest?: any;
}
export const DatePicker = ({ autoWidth = false, ...rest }: DatePickerProps) => {
  /**
   * This function fixes widths of all nested elements
   * as the `fluid` prop doesn't work in the Fluent's Datepicker
   * and we want the input to take up the whole space.
   * It also disables auto-completion for the input element.
   *
   * It looks for the input element, and walks up the DOM tree
   * to the element that got the ref from React.
   * For each of the visited node an inline style of "width: 100%" is set.
   */
  const refCallback = useCallback((node: any) => {
    if (node === null) return;
    const inputElement = node.querySelector('input');
    if (inputElement === null) return;
    inputElement.setAttribute('autocomplete', 'off');
    let currentElement = inputElement;
    while (currentElement !== node) {
      currentElement.style.width = '100%';
      currentElement = currentElement.parentElement;
    }
  }, []);

  const localizationStrings = {
    months: [
      t({ id: 'DatePicker.Months.January' }),
      t({ id: 'DatePicker.Months.February' }),
      t({ id: 'DatePicker.Months.March' }),
      t({ id: 'DatePicker.Months.April' }),
      t({ id: 'DatePicker.Months.May' }),
      t({ id: 'DatePicker.Months.June' }),
      t({ id: 'DatePicker.Months.July' }),
      t({ id: 'DatePicker.Months.August' }),
      t({ id: 'DatePicker.Months.September' }),
      t({ id: 'DatePicker.Months.October' }),
      t({ id: 'DatePicker.Months.November' }),
      t({ id: 'DatePicker.Months.December' }),
    ],
    shortMonths: [
      t({ id: 'DatePicker.Months.Short.January' }),
      t({ id: 'DatePicker.Months.Short.February' }),
      t({ id: 'DatePicker.Months.Short.March' }),
      t({ id: 'DatePicker.Months.Short.April' }),
      t({ id: 'DatePicker.Months.Short.May' }),
      t({ id: 'DatePicker.Months.Short.June' }),
      t({ id: 'DatePicker.Months.Short.July' }),
      t({ id: 'DatePicker.Months.Short.August' }),
      t({ id: 'DatePicker.Months.Short.September' }),
      t({ id: 'DatePicker.Months.Short.October' }),
      t({ id: 'DatePicker.Months.Short.November' }),
      t({ id: 'DatePicker.Months.Short.December' }),
    ],
    days: [
      t({ id: 'DatePicker.Days.Sunday' }),
      t({ id: 'DatePicker.Days.Monday' }),
      t({ id: 'DatePicker.Days.Tuesday' }),
      t({ id: 'DatePicker.Days.Wednesday' }),
      t({ id: 'DatePicker.Days.Thursday' }),
      t({ id: 'DatePicker.Days.Friday' }),
      t({ id: 'DatePicker.Days.Saturday' }),
    ],
    shortDays: [
      t({ id: 'DatePicker.Days.Short.Sunday' }),
      t({ id: 'DatePicker.Days.Short.Monday' }),
      t({ id: 'DatePicker.Days.Short.Tuesday' }),
      t({ id: 'DatePicker.Days.Short.Wednesday' }),
      t({ id: 'DatePicker.Days.Short.Thursday' }),
      t({ id: 'DatePicker.Days.Short.Friday' }),
      t({ id: 'DatePicker.Days.Short.Saturday' }),
    ],
    goToToday: t({ id: 'DatePicker.GoToToday' }),
    prevMonthAriaLabel: t({ id: 'DatePicker.PrevMonthAriaLabel' }),
    nextMonthAriaLabel: t({ id: 'DatePicker.NextMonthAriaLabel' }),
    prevYearAriaLabel: t({ id: 'DatePicker.PrevYearAriaLabel' }),
    nextYearAriaLabel: t({ id: 'DatePicker.NextYearAriaLabel' }),
  } as const;

  const datePickerRef = useRef<HTMLSpanElement | null>(null);

  const openCalendar = () => {
    datePickerRef.current?.querySelector('input')?.click();
  };

  return (
    <Datepicker
      strings={localizationStrings}
      contentAfter={<CalendarRtlRegular onClick={openCalendar} />}
      ref={(el: HTMLSpanElement) => {
        autoWidth && refCallback(el);
        datePickerRef.current = el;
      }}
      isMonthPickerVisible={false}
      {...rest}
    />
  );
};

export const RequestSignatureOffset = 7;
