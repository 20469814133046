import { useCallback, useRef, useState } from 'react';

export const useDebouncedState = (initialValue, timeout) => {
  const [value, setValue] = useState(initialValue);

  const timerRef = useRef(null);

  const setValueDebounced = useCallback((newValue) => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setValue(newValue);

      timerRef.current = null;
    }, timeout);
  }, [timeout]);

  return [value, setValueDebounced];
};
