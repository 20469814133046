import { useTeamsContext } from 'contexts';
import { Stage } from './Stage';
import { SidePanel } from './SidePanel';
import { CalendarDetails } from './CalendarDetails';

export const Meeting = () => {
  const { context: teamsContext } = useTeamsContext();

  const frameContext = teamsContext.page.frameContext;

  if (frameContext === 'sidePanel') {
    // available through side panel after clicking Inperly in the meeting apps
    return <SidePanel />;
  } else if (frameContext === 'content' && teamsContext.sharePointSite.teamSiteDomain) {
    // available through Inperly tab in meeting details in Teams Calendar
    return <CalendarDetails />;
  }

  // this is displayed after clicking / triggering "Present" on our app
  return <Stage />;
};
