import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { EmailInput } from '../../../Common/EmailInput/EmailInput';

export const Email = ({ index, config = {}, moduleName, isRequired = false, isDisabled }) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const fieldName = `${moduleName}.${index}.value`;

  const emailConfig = useMemo(() => {
    const { methods, validation } = config;

    return {
      onBlur: () => {
        if (getValues(fieldName) === '' && methods?.removeRecipient) {
          methods.removeRecipient(index);
        }

        if(getValues(fieldName) !== '' && methods?.addRecipient) {
          methods.addRecipient();
        }
      },
      validation
    };
  }, [config, index, getValues, fieldName]);

  return (
    <EmailInput
      config={emailConfig}
      fieldName={fieldName}
      isRequired={isRequired}
      errorMessage={errors?.[moduleName]?.[index]?.value?.message ?? ''}
      isDisabled={isDisabled}
    />
  );
};
