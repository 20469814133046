import { useEffect, useState } from 'react';
import { ErrorScreen } from 'shared-fe-components/src/common/StatusScreens';
import * as microsoftTeams from '@microsoft/teams-js';
import * as api from 'shared-fe-components/src/api';
import {
  SimplySignIntro,
  SimplySignAuthorization,
  SimplySignSignDocument,
  SimplySignDownload,
} from 'shared-fe-components/src/providers/SimplySign/';
import { useConfigurationContext, useLocalizationContext } from 'contexts';
import { useSignTaskContext } from '../SignTaskContext';

export const SimplySignFlow = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [pinNumber, setPinNumber] = useState('');
  const [cards, setCards] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [signingStarted, setSigningStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { providers: providersConfiguration } = useConfigurationContext();
  const { onCancel, goToProviderSelection, overlay, sign, getSignatureStatus, showPrivacyPolicy } =
    useSignTaskContext();

  const [step, setStep] = useState('intro');

  const goToIntro = () => setStep('intro');
  const goToAuth = () => setStep('authorization');

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
      setIsLoading(false);
      setSigningStarted(false);
    }
  }, [overlay]);

  const { locale } = useLocalizationContext();

  const onSignDocument = async () => {
    setIsLoading(true);
    setSigningStarted(true);

    const additionalParameters = {
      simplySignParameters: {
        cardData: {
          cardNo: cardNumber.replace(/ /g, ''),
          pin: pinNumber.replace(/ /g, ''),
        },
        accessToken: accessToken,
      },
      culture: locale,
    };

    await sign({ additionalParameters });
  };

  // status refetching effect
  useEffect(() => {
    const interval = setInterval(async () => {
      if (signingStarted) {
        await getSignatureStatus({});
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [getSignatureStatus, signingStarted]);

  const handleSignBack = () => {
    goToIntro();
    setCardNumber('');
    setPinNumber('');
  };

  const onSimplySignAuth = async () => {
    setIsLoading(true);
    try {
      const result = await microsoftTeams.authentication.authenticate({
        url: `${window.location.origin}/providers/asseco/simplysign/auth-start.html?endpoint=${encodeURIComponent(
          providersConfiguration.simplySign.authorizationEndpoint
        )}`,
        width: 600,
        height: 535,
      });
      try {
        const tokenData = await api.simplySign.getAccessToken(result.code);
        setAccessToken(tokenData.accessToken);
        try {
          const cards = await api.simplySign.getCards(tokenData.accessToken);
          setCards(cards.data);
          setStep('sign');
        } catch {
          setStep('error');
          setIsLoading(false);
        }
      } catch {
        setStep('error');
        setIsLoading(false);
      }

    } catch {
      setStep('error');
    } finally {
      setIsLoading(false);
    }
  };

  if (step === 'intro') {
    return (
      <SimplySignIntro
        onCancel={onCancel}
        onBack={goToProviderSelection}
        onContinue={goToAuth}
        onShowPrivacyPolicy={showPrivacyPolicy}
        onShowDownload={() => setStep('download')}
      />
    );
  } else if (step === 'download') {
    return <SimplySignDownload onCancel={onCancel} onContinue={goToAuth} onBack={() => setStep('intro')} />;
  } else if (step === 'authorization') {
    return (
      <SimplySignAuthorization
        onSimplySignAuth={onSimplySignAuth}
        isLoading={isLoading}
        onCancel={onCancel}
        onBack={() => setStep('intro')}
      />
    );
  } else if (step === 'error') {
    return <ErrorScreen processType="SimplySign" errorCode="SimplySign" onCancel={onCancel} onRetry={goToAuth} />;
  } else if (step === 'sign') {
    return (
      <SimplySignSignDocument
        onCancel={onCancel}
        onSignBack={handleSignBack}
        onSignDocument={onSignDocument}
        isLoading={isLoading}
        cardNumber={cardNumber}
        pinNumber={pinNumber}
        cards={cards}
        onPinCodeChange={setPinNumber}
        onCardNumberChange={setCardNumber}
      />
    );
  }
};
