import { FileObject } from 'lib/fileObject';

export interface MergeConvertStateDialogProps {
  state: MergeConvertState;
  onCancel: () => void;
  onConfirm: (result?: Partial<{ mergedFilename: string }>) => void;
  file?: File | null;
}

export enum MergeConvertState {
  Idle,
  Merging,
  MergeSuccess,
  MergeError,
}

export interface ValidationError {
  message: string;
  type: FileValidationErrorTypes;
}

export enum FileValidationErrorTypes {
  AtLeastTwoFiles,
  MaximumSize,
  IsEditing,
  FilenameEmpty,
}

export interface FileRow {
  value: FileObject;
  isSelected: boolean;
  editFilename: string;
}
