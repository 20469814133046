import { ReactNode } from 'react';

/**Consts */

/**
 * colors with _context_ prefix are for different frameContexts. available values are:
 * settings, task, meetingStage, sidePanel, content
 */
const baseTheme = {
  edoRed: '#C81224',
  edoWhite: '#fff',
  edoBlue: '#282E6F',
  idnow: '#ff6b40',
  inperly: '#4D49E1',
  dashboardBackground: '#141d3e',
  simplySignBlue: '#11a4e0',
  _sidePanel_background: '#1f1f1f',
} as const;

export const whiteTheme: Record<string, string> = {
  ...baseTheme,
  _settings_background: '#ffffff',
  _task_background: '#ffffff',
  dashboardBackground: '#4160D0',
} as const;

// dark colors overrides defaults
export const darkTheme: Record<string, string> = {
  ...baseTheme,
  _settings_background: '#292929',
  _task_background: '#292929',
} as const;

/**Types */
export type Theme = typeof whiteTheme | typeof darkTheme;

export interface ThemeObject {
  theme: any;
  customColors: Theme;
  themeName: string;
}

export interface ThemeProps {
  children: ReactNode;
}
