import { Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { InperlyLogo } from 'shared-fe-components/src/common/Icons';
import './Welcome.scss';

export const Welcome = () => {
  return (
    <div className="meeting-stage-welcome">
      <div className="meeting-stage-welcome__content">
        <InperlyLogo />
        <Text size={400} weight="medium">{t({ id: 'Meeting.Stage.Welcome' })}</Text>
      </div>
    </div>
  );
};
