export const FILE_FORMATS = {
    'application/pdf': 'PDF',
    'text/xml': 'XML',
} as const;

export const FILE_FORMATS_MAPPED = {
    'PDF': 'application/pdf',
    'XML': 'text/xml',
} as const;

export const FILE_FORMATS_EXTENSIONS = {
    'application/pdf': ['.pdf'],
    'text/xml': ['.xml'],
} as const;

export const FILE_FORMATS_SHORT = ['PDF', 'XML'] as const;
export const FILE_FORMATS_LONG = ['application/pdf', 'text/xml'] as const;