import { useMemo, useState } from 'react';
import { Checkbox, Text } from '@fluentui/react-components';
import { Button } from 'shared-fe-components/src/common/FluentMigration';
import { t } from '@lingui/macro';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { MeetingParticipant } from './MeetingParticipant';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './PeopleSelection.scss';

export const PeopleSelection = ({ meetingParticipants, selectedPeople, onPeopleSelected }) => {
  const [people, setPeople] = useState(() => [...selectedPeople]);

  const continueButtonDisabled = useMemo(() => people.length === 0, [people]);

  const togglePeople = (participant) => {
    if (people.includes(participant)) {
      setPeople([...people].filter((p) => p !== participant));
    } else {
      setPeople([...people, participant]);
    }
  };

  return (
    <WizardBox className="people-selection">
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'StartSignTask.PeopleSelection.Header' })}
        </Text>
      </div>
      <div>
        <Text>{t({ id: 'StartSignTask.PeopleSelection.ParticipantsList' })}</Text>

        {meetingParticipants.map((participant) => (
          <Checkbox
            key={participant.id}
            checked={!!people.includes(participant)}
            label={<MeetingParticipant participant={participant} />}
            onChange={() => togglePeople(participant)}
          />
        ))}
      </div>
      <div>
        <div>
          <ButtonBack onClick={() => onPeopleSelected(people, false)} />
        </div>
        <div>
          <Button primary onClick={() => onPeopleSelected(people, true)} disabled={continueButtonDisabled}>
            {t({ id: 'Common.Continue' })}
          </Button>
        </div>
      </div>
    </WizardBox>
  );
};
