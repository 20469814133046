import { BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom';
import {
  TeamsContextProvider,
  LocalizationProvider,
  ConfigurationProvider,
  UserContextProvider,
  MeetingContextProvider,
  GraphConsentsContextProvider,
} from 'contexts';
import { RetryDialogs } from './components/Retriable';

import Tab from './components/Tab';
import { Dashboard } from './components/Dashboard';
import { TabConfig } from './components/Config';
import { Meeting } from './components/Meeting';
import { SignTask, SignNotRequested } from './components/Signing';
import { VerifyTask } from './components/Verification/VerifyTask';
import { StartSigningTask } from './components/Meeting/StartSigningTask/StartSigningTask';
import { LicenseReload } from 'components/License/LicenseReload';

import './lib/setupApi';

export default function App() {
  return (
    <TeamsContextProvider>
      <LocalizationProvider>
        <RetryDialogs />

        <ConfigurationProvider>
          <UserContextProvider>
            {/* <GraphConsentsContextProvider> */}
              <MeetingContextProvider>
                <Router>
                  <Routes>
                    <Route path="/" element={<Navigate to="tab" replace />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/tab" element={<Tab />} />
                    <Route path="/meeting" element={<Meeting />} />
                    <Route path="/config/tab" element={<TabConfig />} />
                    <Route path="/tasks/verify/:sessionId/:verificationId" element={<VerifyTask />} />
                    <Route path="/tasks/sign/:sessionId/:signatureId" element={<SignTask />} />
                    <Route path="/tasks/sign/not-requested" element={<SignNotRequested />} />
                    <Route path="/tasks/self-sign/:driveId/:folderId/:fileId" element={<SignTask />} />
                    <Route path="/tasks/self-sign-container/:containerId/:fileId" element={<SignTask />} />
                    <Route path="/tasks/meeting/sign" element={<StartSigningTask />} />
                    <Route path="/reload" element={<LicenseReload />} />
                  </Routes>
                </Router>
              </MeetingContextProvider>
            {/* </GraphConsentsContextProvider> */}
          </UserContextProvider>
        </ConfigurationProvider>
      </LocalizationProvider>
    </TeamsContextProvider>
  );
}
