import { useContext, useMemo } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useTeamsContext } from 'contexts';
import { LocalizationContext, selectLocale } from 'shared-fe-components/src/contexts/LocalizationContext';
import { LocalizationProviderProps } from './models';

export const LocalizationProvider = ({ locale = null, children }: LocalizationProviderProps) => {
  const { context: teamsContext } = useTeamsContext();

  /**use useMemo instead of useEffect to activate locale before rendering the rest of the app */
  useMemo(() => {
    i18n.activate(selectLocale(locale ?? teamsContext.app.locale));
  }, [locale, teamsContext.app.locale]);

  return (
    <LocalizationContext.Provider value={{ locale: i18n.locale }}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </LocalizationContext.Provider>
  );
};

export const useLocalizationContext = () => {
  const ctx = useContext(LocalizationContext);

  if (!ctx) {
    throw new Error('Lack of LocalizationProvider in components tree');
  }

  return ctx;
};
