import { Text } from '@fluentui/react-components';
import { PersonCircle24Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { GenericTable } from 'shared-fe-components/src/common/GenericTable';
import { ReminderFormDialog } from '../ReminderFormDialog/ReminderFormDialog';
import { useDateFormatter } from 'lib/useDateFormatter';
import { dateFormatterOptions } from './models';

interface Props {
  sessionId: string;
  items: {
    provider: string;
    recipient: {
      culture: string;
      email: string;
      id: string;
      meetingParticipantId: string | null;
      phoneNumber: {
        prefix: number;
        number: string;
        countryCode: string;
      } | null;
      type: string;
      userId: string | null;
    };
    date: string;
    status: string;
    manualReminderTime: Date | null;
    id: string;
    rejectionMessage: string | null;
    statusClass: string;
  }[];
}

export const ProcessDetailsParticipantTable = ({ items, sessionId }: Props) => {
  const dateFormatter = useDateFormatter(dateFormatterOptions);

  const rows = items.map((item) => {
    const providerName = item.provider;
    const phoneNumber =
      item?.recipient?.phoneNumber !== null &&
      `+${item.recipient.phoneNumber.prefix} ${item.recipient.phoneNumber.number}`;

    const isGuest = item.recipient.type === 'Guest';
    const isMeetingParticipant = item.recipient.meetingParticipantId !== null;

    return {
      user: (
        <div className="process-details__participant-user">
          <div className="process-details__participant-photo">
            <PersonCircle24Regular />
          </div>
          <Text>{isGuest ? `(${t({ id: 'VerificationSummaryTable.Origin.Guest' })})` : item.recipient.email}</Text>
        </div>
      ),
      ...(item.date
        ? {
            date: (
              <Text className="process-details__date" block>
                {dateFormatter(new Date(item.date))}
              </Text>
            ),
          }
        : {}),
      status: (
        <Text
          className={`process-details__participant-status process-details__participant-status--${item.statusClass}`}
          block
        >
          {t({ id: `ProcessDetails.Status.${item.status}` })}
        </Text>
      ),
      ...(item.status === 'Succeeded'
        ? {
            provider: <Text className={'process-details__data'}>{`${providerName}`}</Text>,
          }
        : {}),
      ...(item.recipient.phoneNumber
        ? {
            phone: (
              <div className={'process-details__adjusted'}>
                <Text className={'process-details__data'} block>
                  {phoneNumber}
                </Text>
              </div>
            ),
          }
        : {}),
      ...(item.status === 'NotStarted' && !isMeetingParticipant
        ? {
            reminder: (
              <ReminderFormDialog
                recipient={item.recipient}
                sessionId={sessionId}
                lastRemind={item.manualReminderTime}
                signatureId={item.id}
              />
            ),
          }
        : {}),
      ...(item.rejectionMessage
        ? { message: <div className="process-details__rejection-reason">{item.rejectionMessage}</div> }
        : {}),
    };
  });

  const columns = {
    user: t({ id: 'ProcessDetails.Table.Recipient' }),
    status: t({ id: 'ProcessDetails.Table.Status' }),
    date: t({ id: 'ProcessDetails.Table.Date' }),
    provider: t({ id: 'ProcessDetails.Table.Provider' }),
    phone: t({ id: 'ProcessDetails.Table.Phone.Number' }),
    reminder: t({ id: 'ProcessDetails.Table.Reminder' }),
    message: t({ id: 'ProcessDetails.Table.Message' }),
  };

  return <GenericTable rows={rows} columns={columns} rowGap={8} colGap={8} visibleColumnHeader />;
};
