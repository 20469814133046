import { Input as FluentInput } from '@fluentui/react-components';
import { useController, useFormContext } from 'react-hook-form';

interface InputProps {
  fieldName: string;
  rules?: {};
  rest?: any;
}
export const Input = ({ fieldName, rules = {}, ...rest }: InputProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  const fluentProps = {
    onChange: (event: any) => {
      onChange(event.target.value);
    },
    value,
    input: { name: name, ref: ref, onBlur: onBlur },
  };

  return <FluentInput {...fluentProps} {...rest} />;
};
