import { XmlIcon } from '../Icons';
import { ExternalXmlSignature, InternalXmlSignature, SurroundingXmlSignature } from './utils/xml-icons';
import { WizardBox } from '../WizardBox';
import { Button, Text } from '@fluentui/react-components';
import { ActionableSection } from 'shared-fe-components/src/common/ActionableSection';
import { ButtonBack } from '../Buttons';
import { t } from '@lingui/macro';
import './XmlSelectionModal.scss';

interface XmlSelectionModalProps {
  onBack: () => void;
  onCancel: () => void;
  onContinue: (type: string) => void;
  hideXmlTypeSign?: boolean;
}
export const XmlSelectionModal = ({ onBack, onCancel, onContinue, hideXmlTypeSign }: XmlSelectionModalProps) => {
  return (
    <WizardBox>
      <div></div>
      <div className="xml-selection-content-wrapper">
        <XmlIcon />
        <Text weight="semi-bold" size={600}>
          {t({ id: 'Signing.Xml.Type.Select.Title' })}
        </Text>
        <Text size={500}>{t({ id: 'Signing.Xml.Type.Select.Description' })}</Text>
        <div>
          <ActionableSection
            action={t({ id: 'Common.Proceed' })}
            onClick={() => onContinue('Enveloped')}
            icon={<InternalXmlSignature />}
          >
            <Text weight="medium" size={400}>
              {t({ id: 'Signing.Xml.Type.Select.Internal.Header' })}
            </Text>
            <br />
            <Text weight="regular" size={300}>
              {t({ id: 'Signing.Xml.Type.Select.Internal.Description' })}
            </Text>
          </ActionableSection>
          {!hideXmlTypeSign && (
            <ActionableSection
              action={t({ id: 'Common.Proceed' })}
              onClick={() => onContinue('Enveloping')}
              icon={<SurroundingXmlSignature />}
            >
              <Text weight="medium" size={400}>
                {t({ id: 'Signing.Xml.Type.Select.Surrounding.Header' })}
              </Text>
              <br />
              <Text weight="regular" size={300}>
                {t({ id: 'Signing.Xml.Type.Select.Surrounding.Description' })}
              </Text>
            </ActionableSection>
          )}
          {!hideXmlTypeSign && (
          <ActionableSection
            action={t({ id: 'Common.Proceed' })}
            onClick={() => onContinue('Detached')}
            icon={<ExternalXmlSignature />}
          >
            <Text weight="medium" size={400}>
              {t({ id: 'Signing.Xml.Type.Select.External.Header' })}
            </Text>
            <br />
            <Text weight="regular" size={300}>
              {t({ id: 'Signing.Xml.Type.Select.External.Description' })}
            </Text>
          </ActionableSection>
          )}
        </div>
      </div>
      <div>
        <div>{onBack && <ButtonBack onClick={onBack} />}</div>
        <div>{onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}</div>
      </div>
    </WizardBox>
  );
};
