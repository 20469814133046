import { useState } from 'react';
import { DashboardTable } from './DashboardTable/DashboardTable';
import { DashboardTiles } from './DashboardTiles/DashboardTiles';
import { DashboardFileSigning } from './DashboardFileSigning/DashboardFileSigning';
import { DashboardSendForSigning } from './DashboardSendForSigning/DashboardSendForSigning';
import { LicenseError } from '../License';
import { useUserContext, SessionStatisticsProvider } from 'contexts';
import { InperlyLogoSlogan } from 'shared-fe-components/src/common/Icons';
import { DashboardRequestSignatureEdit } from './DashboardRequestSignatureEdit/DashboardRequestSignatureEdit';
import { FreemiumBanner } from 'components/License/FreemiumBanner';
import './Dashboard.scss';

const DashboardHeader = () => {
  return (
    <div className="dashboard-header">
      <InperlyLogoSlogan alt="Inperly Logo" className="dashboard-header__logo" />
    </div>
  );
};

export const Dashboard = ({ children }) => {
  const [screen, setScreen] = useState('table');
  const [sessionId, setSessionId] = useState('');
  const { hasLicense, user, freemiumStatus } = useUserContext();

  const onCloseInChildren = () => {
    setScreen('table');
  };

  const handleEditClick = (sessionId) => {
    setSessionId(sessionId);
    setScreen('edit');
  };

  if (!hasLicense) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <LicenseError license={user?.license} />
      </div>
    );
  }

  return (
    <SessionStatisticsProvider>
      {freemiumStatus?.isActive && <FreemiumBanner />}
      <div className="dashboard-wrapper">
        <div className="dashboard-background-color-1"></div>
        <div className="dashboard-background-image"></div>
        <div className="dashboard-background-color-2">
          <div></div>
        </div>
        <div className="dashboard-content-wrapper">
          <DashboardHeader />
          <div className="dashboard-content">
            {screen === 'table' && (
              <>
                <div className="dashboard-content-columns">
                  <DashboardTiles onSignTileClick={() => setScreen('sign')} onSendTileClick={() => setScreen('send')} />
                  <DashboardTable onEditSignatureClick={handleEditClick} />
                </div>
              </>
            )}
            {screen === 'sign' && <DashboardFileSigning onClose={onCloseInChildren} />}
            {screen === 'edit' && <DashboardRequestSignatureEdit sessionId={sessionId} onClose={onCloseInChildren} />}
            {screen === 'send' && <DashboardSendForSigning onClose={onCloseInChildren} />}
          </div>
        </div>
      </div>
    </SessionStatisticsProvider>
  );
};
