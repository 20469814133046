import { useState, useEffect } from 'react';
import { t } from '@lingui/macro';
import { Text, Button, Spinner } from '@fluentui/react-components';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { useSignTaskContext } from '../SignTaskContext';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { useLocalizationContext, useUserContext } from 'contexts';

export const NativeSign = ({ additionalParameters, onBack, onCancel, signatureType, signerData }) => {
  const [signingStarted, setSigningStarted] = useState(false);
  const [loadingState, setLoadingState] = useState({ isLoading: false, label: null });
  const setIsLoading = (isLoading, label = null) => {
    setLoadingState({ isLoading, label });
  }

  const { file, sign, getSignatureStatus } = useSignTaskContext();
  const { user } = useUserContext();
  const { locale } = useLocalizationContext();

  const onSign = async () => {
    setIsLoading(true, t({ id: 'Providers.Common.Spinner.Signing' }));
    setSigningStarted(true);

    const additionalParameters = {
      nativeParameters: {
        signatureType,
        phoneNumberToken: signerData.phoneNumberToken,
        signerName: signerData.fullName,
      },
      culture: locale,
    };

    sign({ additionalParameters });
  }

  // status refetching effect
  useEffect(() => {
    const fetchSignatureStatus = async () => {
      if (signingStarted) {
        await getSignatureStatus({});
      }
    };

    fetchSignatureStatus();

    const interval = setInterval(fetchSignatureStatus, 5000);

    return () => clearInterval(interval);
  }, [signingStarted, getSignatureStatus]);

  return loadingState.isLoading ? (
    <LoadingScreen label={loadingState.label} />
  ) : (
    <WizardBox className="native-sign">
      <div className='inperly__header'>
        <Text as="h1" weight="bold">
          {t({ id: 'Signing.SignHeader' })}
        </Text>
      </div>
      <div className="native-sign-summary">
        <Text className="native-sign-form__label">
          {t({ id: 'Providers.Native.Sign.Description' })}
        </Text>
        <div className="native-sign-summary__container">
          <Text>{t({ id: 'Form.Inputs.FullName' })}</Text>
          <Text>{signerData.fullName}</Text>
          <Text>{t({ id: 'Form.Inputs.Email' })}</Text>
          <Text>{user.email}</Text>
          {signerData.phoneNumber && <>
            <Text>{t({ id: 'Form.Inputs.PhoneNumber' })}</Text>
            <Text>{signerData.phoneNumber}</Text>
          </>}
        </div>
      </div>
      <div>
        <div>{onBack && <ButtonBack onClick={onBack} />}</div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
          <Button appearance="primary" onClick={onSign}>
            {t({ id: 'Common.Sign' })}
          </Button>
        </div>
      </div>
    </WizardBox>
  );
};
