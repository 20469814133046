import { useEffect, useRef, useState } from 'react';
import { Button } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import * as api from 'shared-fe-components/src/api';
import { t } from '@lingui/macro';
import { DashboardSendForSigningRequestSignature } from '../DashboardSendForSigning/DashboardSendForSigningRequestSignature';
import { ProtectionType } from 'shared-fe-components/src/common/Constants';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import dayjs from 'dayjs';
import { useSessionStatisticsContext } from 'contexts';
import {
  DashboardRequestSignatureEditError,
  DashboardRequestSignatureEditLoader,
  DashboardRequestSignatureEditSuccess,
} from './Modals';
import {
  sessionState,
  DashboardRequestSignatureEditProps,
  ReminderFrequency,
  RecipientValues,
  CCRecipientsValues, recipientsRef, CCRecipientsRef,
} from './models';
import { UpdateSigningSessionBody } from 'shared-fe-components/src/api/signing/models';
import '../DashboardSendForSigning/DashboardSendForSigning.scss';

export const DashboardRequestSignatureEdit = ({ onClose, sessionId }: DashboardRequestSignatureEditProps) => {
  const { fetchSessionStatistics } = useSessionStatisticsContext();
  const [step, setStep] = useState('');
  const [requestedSignatureData, setRequestedSignatureData] = useState<sessionState>({} as sessionState);
  const [errorDescription, setErrorDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isInOrder, setIsInOrder] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const recipients = useRef<recipientsRef[]>([]);
  const ccRecipients = useRef<CCRecipientsRef[]>([]);
  const message = useRef('');
  const processName = useRef('');
  const files = useRef([]);
  const reminderFrequency = useRef<ReminderFrequency | null>();
  const expirationDate = useRef<Date | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const sessionState = await api.signing.getSessionState(sessionId, true);
        sessionState && setRequestedSignatureData(sessionState);
        setIsLoading(false);
      } catch {}
    })();
  }, []);

  const onChosenData = (
    emittedRecipients: any,
    emittedCcRecipients: any,
    emittedMessage: any,
    emittedProcessName: any,
    emittedFiles: any,
    emittedExpirationDate: any,
    emittedReminderFrequency: any
  ) => {
    recipients.current = emittedRecipients;
    ccRecipients.current = emittedCcRecipients;
    message.current = emittedMessage;
    processName.current = emittedProcessName;
    files.current = emittedFiles;
    expirationDate.current = emittedExpirationDate;
    reminderFrequency.current = emittedReminderFrequency;
  };

  const sendForSigning = async () => {
    const recipientsList = recipients.current
      .filter((recipient) => recipient.value?.length > 0)
      .map((recipient) => {
        const otpData =
          recipient.mobilephoneNumber
            ? {
                protectionType: ProtectionType.OneTimePassword,
                phoneNumber: {
                  prefix: Number(recipient.mobilephoneCode?.split('-')?.[1]),
                  number: recipient.mobilephoneNumber,
                  countryCode: recipient.mobilephoneCode?.split('-')?.[0],
                },
              }
            : {};

        return {
          type: 'External',
          email: recipient.value,
          culture: recipient.lang,
          ...otpData,
        };
      });

    const ccRecipientsList = ccRecipients.current
      .filter((ccRecipient) => ccRecipient.value?.length > 0)
      .map((ccRecipient) => ({
        email: ccRecipient.value,
        culture: ccRecipient.lang,
      }));

    const bodyObj = {
      recipients: recipientsList,
      carbonCopyRecipients: ccRecipientsList,
      message: message.current,
      name: processName.current,
      reminderFrequency: reminderFrequency.current,
      expirationTime: expirationDate.current && dayjs(expirationDate.current).endOf('day').format(),
    } as UpdateSigningSessionBody;


    try {
      const resp = await api.signing.updateSigningSession(sessionId, bodyObj);
      return [true, resp];
    } catch (error) {
      return [false, error];
    }
  };

  const onSendInFilesAndDate = async () => {
    setStep('Loader');
    const result = await sendForSigning();
    if (result && result[0]) {
      setStep('Success');
      await fetchSessionStatistics();
    } else {
      setErrorDescription(t({ id: 'EditSessionRequest.Error.Info' }));
      setStep('Error');
    }
  };

  const recipientsValues = requestedSignatureData?.signatures?.map((signature: RecipientValues) => {
    return {
      value: signature.recipient.email,
      lang: signature.recipient.culture,
      mobilephoneCode: signature.recipient.phoneNumber
        ? `${signature.recipient.phoneNumber.countryCode}-${signature.recipient.phoneNumber.prefix}`
        : null,
      mobilephoneNumber: signature.recipient.phoneNumber ? signature.recipient.phoneNumber.number : null,
      status: signature.status,
    };
  });

  const ccRecipientsValues = requestedSignatureData?.carbonCopyRecipients?.map(
    ({ email, culture }: CCRecipientsValues) => {
      return {
        value: email,
        lang: culture,
      };
    }
  );

  const handleCloseModal = () => {
    setStep('');
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <WizardBox className="send-for-signing" growContent={false}>
          <div>
            <Header as="h1">{t({ id: 'Dashboard.Signing.Headers.EditSignature' })}</Header>
          </div>
          <div>
            <DashboardSendForSigningRequestSignature
              isEditMode={true}
              recipients={recipientsValues}
              ccRecipients={ccRecipientsValues}
              message={requestedSignatureData?.message}
              processName={requestedSignatureData?.name}
              reminderFrequency={requestedSignatureData?.reminderFrequency}
              files={requestedSignatureData?.documents}
              expirationDate={new Date(requestedSignatureData.expirationTime)}
              isInOrder={requestedSignatureData?.signatureType === 'Sequential'}
              setIsInOrder={setIsInOrder}
              onChosenData={onChosenData}
              onSendInFilesAndDate={onSendInFilesAndDate}
              setIsConfirmed={setIsConfirmed}
              isConfirmed={isConfirmed}
              setIsFormDirty={setIsFormDirty}
            />
          </div>
          <div>
            <span className="send-for-signing__two-column-view">
              <div className="send-for-signing__two-column-view-left-column">
                <div className="send-for-signing__control-action-buttons">
                  <Button onClick={onClose}>{t({ id: 'Common.Cancel' })}</Button>
                  <Button disabled={!isFormDirty} appearance="primary" onClick={() => setIsConfirmed(true)}>
                    {t({ id: 'Common.Proceed' })}
                  </Button>
                </div>
              </div>
            </span>
          </div>
        </WizardBox>
      )}
      {step === 'Loader' && <DashboardRequestSignatureEditLoader />}
      {step === 'Success' && <DashboardRequestSignatureEditSuccess onClose={onClose} />}
      {step === 'Error' && (
        <DashboardRequestSignatureEditError
          errorDescription={errorDescription}
          handleCloseModal={handleCloseModal}
          onClose={onClose}
          onSendInFilesAndDate={onSendInFilesAndDate}
        />
      )}
    </>
  );
};
