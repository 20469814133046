import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { Button } from "@fluentui/react-components";
import { t } from '@lingui/macro';

export const FileBrowserLocalFiles = ({ allowedFileTypes = null, onFileSelect }) => {
  const inputRef = useRef();

  const onClick = useCallback(() => {
    if (inputRef.current instanceof HTMLInputElement) {
      inputRef.current.click();
    }
  }, []);

  const onInput = useCallback(
    (event) => {
      if (event.target.files?.[0]) {
        onFileSelect(event.target.files[0]);
      }
    },
    [onFileSelect]
  );

  useLayoutEffect(() => {
    if (inputRef.current instanceof HTMLInputElement) {
      inputRef.current.value = '';
    }
  }, []);

  const accept = useMemo(() => {
    return allowedFileTypes ? allowedFileTypes.join(',') : undefined;
  }, [allowedFileTypes]);

  return (
    <Flex fill vAlign="center" hAlign="center" style={{height: "100%"}}>
      <input accept={accept} type="file" ref={inputRef} onInput={onInput} style={{ display: 'none' }} />
      <Button appearance="primary" onClick={onClick}>{t({ id: 'FileBrowser.LocalFiles.SelectFile' })}</Button>
    </Flex>
  );
};

FileBrowserLocalFiles.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  onFileSelect: PropTypes.func.isRequired,
};
