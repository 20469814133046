import { useRef, useEffect, useReducer, useCallback } from "react";

export function useData(asyncFn, options) {
  const { auto, initialState } = { auto: true, initialState: {}, ...options };
  
  const counterRef = useRef(0);

  const [{ data, loading, error }, dispatch] = useReducer(
    (state, { type, data, error }) => {
      switch (type) {
        case "loading":
          return state.loading ? state : { data: state.state, loading: true, error: null };
        case "result":
          return { data, loading: false, error: null };
        case "error":
          return { data: null, loading: false, error };
        default:
          return {};
      }
    },
    { data: null, loading: !!auto, error: null, ...initialState }
  );

  const reload = useCallback(() => {
    if (typeof asyncFn !== "function") {
      throw new Error("invalid argument to useData, a function is required");
    }
    
    const counter = ++counterRef.current;
    dispatch({ type: "loading" });

    Promise.resolve()
      .then(asyncFn)
      .then((data) => {
        if (counter === counterRef.current) {
          dispatch({ type: "result", data });
        }
      })
      .catch((error) => {
        if (counter === counterRef.current) {
          dispatch({ type: "error", error });
        }
      });
  }, [asyncFn]);

  useEffect(() => {
    if (auto) reload();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return { data, loading, error, reload };
}
