import { Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import './DashboardTiles.scss';
import PaperSignIcon from './assets/paper-sign-icon.svg';
import SendToSignIcon from './assets/send-to-sign-icon.svg';
import { useSessionStatisticsContext } from 'contexts';

const IconTile = ({ icon, text, onClick }) => {
  return (
    <div className="tile-wrapper">
      <div className="icon-tile" onClick={onClick}>
        <div className="icon-tile__image-container">
          <img alt="" src={icon} />
        </div>
        <div className="icon-tile__text">
          <Text weight="semibold">{text}</Text>
        </div>
      </div>
    </div>
  );
};

const InfoTile = () => {
  const { pendingCount, unsignedCount } = useSessionStatisticsContext();

  return (
    <div className="tile-wrapper">
      <div className="info-tile">
        <div className="info-tile__count info-tile__pending">{pendingCount}</div>
        <div className="info-tile__color-bar info-tile__pending"></div>
        <div className="info-tile__text">
          <Text weight="semibold" size="small">
            {t({ id: 'Dashboard.Tile.Pending' })}
          </Text>
        </div>
        <div className="info-tile__count info-tile__unsigned">{unsignedCount}</div>
        <div className="info-tile__color-bar info-tile__unsigned"></div>
        <div className="info-tile__text">
          <Text weight="semibold" size="small">
            {t({ id: 'Dashboard.Tile.Unsigned' })}
          </Text>
        </div>
      </div>
    </div>
  );
};

export const DashboardTiles = ({ onSignTileClick, onSendTileClick }) => {
  return (
    <div className="tile-container">
      <IconTile icon={PaperSignIcon} text={t({ id: 'Dashboard.Tile.Sign' })} onClick={onSignTileClick} />
      <IconTile icon={SendToSignIcon} text={t({ id: 'Dashboard.Tile.Send' })} onClick={onSendTileClick} />
      <InfoTile />
    </div>
  );
};
