import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { configurationTeams } from 'shared-fe-components/src/api';
import { t } from '@lingui/macro';
import { LoadingScreen } from '../../components/Common/LoadingScreen';
import { checkContract, ConfigurationApp, type ConfigurationAppType } from 'shared-fe-components/src/api/contracts';

// make sure to update ConfigurationAppType when new configuration properties are returned from backend
const Context = createContext<ConfigurationAppType | null>(null);

export const ConfigurationProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [configuration, setConfiguration] = useState<ConfigurationAppType | null>(null);

  useEffect(() => {
    (async () => {
      const configuration = checkContract(await configurationTeams.getConfiguration(), ConfigurationApp);
      setConfiguration(configuration);
      setIsLoading(false);
    })();
  }, []);

  return (
    <Context.Provider value={configuration}>
      {isLoading ? <LoadingScreen label={t({ id: 'Loading.Configuration' })} /> : <>{children}</>}
    </Context.Provider>
  );
};

export const useConfigurationContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Lack of ConfigurationProvider in components tree');
  }

  return ctx;
};
