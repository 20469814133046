import { Button, Spinner, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import * as api from 'shared-fe-components/src/api';
import { useEffect, useState, useCallback, useReducer } from 'react';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { Scrollable } from 'shared-fe-components/src/common/Scrollable';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';

const initialState = {
  loaded: false,
  content: null,
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'success': {
      return {
        ...state,
        loaded: true,
        content: action.content,
      };
    }
    case 'error': {
      return {
        ...state,
        loaded: true,
        content: null,
      };
    }
    default: {
      throw new Error('unknown action');
    }
  }
};

const useGdprAdministrator = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    api.gdpr.get().then(
      (content) => dispatch({ type: 'success', content }),
      (error) => dispatch({ type: 'error', error })
    );
  }, []);

  return state;
};

export const PrivacyPolicy = ({ required, onClose, onAccept }) => {
  const [allowAccept, setAllowAccept] = useState(false);
  const state = useGdprAdministrator();

  const onScrolledToEnd = useCallback(() => {
    if (state.loaded && !allowAccept) {
      setAllowAccept(true);
    }
  }, [state.loaded, allowAccept]);

  return (
    <WizardBox>
      <div>
        <Text as="h1" weight="bold">{t({ id: 'PrivacyPolicy.Header' })}</Text>
      </div>
      <div>
        <div>
          {!state.loaded && <Spinner />}
          {state.loaded && (
            <Scrollable onScrolledToEnd={onScrolledToEnd}>
              <Text as="h2" weight="semibold" block>
                {t({ id: 'PrivacyPolicy.Content.Header' })}
              </Text>
              <Text as="p" block>
                {t({
                  id: 'PrivacyPolicy.Content.Paragraph0',
                  values: { administrator: state.content?.adminName },
                })}
              </Text>
              <Text as="p" block>
                {t({
                  id: 'PrivacyPolicy.Content.Paragraph1',
                  values: { contact: state.content?.adminContact, address: state.content?.adminAddress },
                })}
              </Text>
              <Text as="p" block>{t({ id: 'PrivacyPolicy.Content.Paragraph2' })}</Text>
              <Text as="p" block>{t({ id: 'PrivacyPolicy.Content.Paragraph3' })} </Text>

              <Text as="p" block>
                <Trans
                  id="PrivacyPolicy.Content.Paragraph4"
                  components={[
                    <Anchor href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer" />,
                  ]}
                />
              </Text>
            </Scrollable>
          )}
        </div>
      </div>
      <div>
        <div>
          {!required && <Button onClick={onClose}>{t({ id: 'Common.Close' })}</Button>}
          {required && <Button onClick={onClose}>{t({ id: 'Common.Cancel' })}</Button>}
        </div>
        <div>
          {required && (
            <Button appearance="primary" disabled={!allowAccept} onClick={onAccept}>
              {t({ id: 'Common.Accept' })}
            </Button>
          )}
        </div>
      </div>
    </WizardBox>
  );
};
