import { Button } from '@fluentui/react-components';
import { InperlyLogoSloganColor } from 'shared-fe-components/src/common/Icons';
import { WizardTintedBox } from 'shared-fe-components/src/common/WizardBox/WizardTintedBox';
import { Alert } from 'shared-fe-components/src/common/Alert';
import { t } from '@lingui/macro';
import { Dismiss16Regular, Warning28Filled } from '@fluentui/react-icons';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';

interface DashboardRequestSignatureEditError {
  handleCloseModal: () => void;
  onClose: () => void;
  onSendInFilesAndDate: () => void;
  errorDescription?: string;
}

export const DashboardRequestSignatureEditError = ({
  handleCloseModal,
  onClose,
  onSendInFilesAndDate,
  errorDescription
}: DashboardRequestSignatureEditError) => (
  <>
    <div className="modal-overlay" />
    <div className="alert-wrapper alert-error">
      <WizardTintedBox color="red" role="alert">
        <div className="alert-error__header">
          <InperlyLogoSloganColor alt="Inperly Logo" className="alert-error__header__logo" />
          <Button
            icon={<Dismiss16Regular />}
            appearance="transparent"
            title={t({ id: 'Dashboard.Modal.Close' })}
            onClick={handleCloseModal}
          />
        </div>
        <div className="alert-error__content">
          <Alert
            icon={<Warning28Filled />}
            type="error"
            title={t({ id: 'EditSessionRequest.Error.Header' })}
            description={errorDescription ?? t({ id: 'EditSessionRequest.Error.Info' })}
          />
        </div>
        <div>
          <div>
            <ButtonBack onClick={handleCloseModal} />
          </div>
          <div>
            <Button appearance="secondary" onClick={onClose}>
              {t({ id: 'Common.Cancel' })}
            </Button>
            <Button appearance="primary" onClick={onSendInFilesAndDate}>
              {t({ id: 'Common.Retry' })}
            </Button>
          </div>
        </div>
      </WizardTintedBox>
    </div>
  </>
);
