import { Flex } from '@fluentui/react-migration-v0-v9';
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Button,
  Text,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover
} from "@fluentui/react-components";
import { MoreHorizontal24Filled, Edit16Filled, Eye16Regular } from '@fluentui/react-icons';
import { useMemo } from 'react';
import { t } from '@lingui/macro';
import DriveItemIcon from './DriveItemIcon';

import './FileList.css';
import { useDateFormatter } from '../../lib/useDateFormatter';
import { FILE_FORMATS_MAPPED } from 'shared-fe-components/src/utils/fileFormats';

const Icon = ({ entry }) => (
  <>
    <div style={{ width: '1.5em' }}>
      <DriveItemIcon isFolder={!!entry.folder} mimeType={entry.file?.mimeType} />
    </div>
  </>
);

const MoreOptions = ({ onSign, onPreview /*, onDownload*/ }) => {
  if (!onSign && !onPreview /* && !onDownload */) {
    return null;
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button icon={<MoreHorizontal24Filled />} appearance="transparent" />
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {onSign && <MenuItem onClick={onSign} icon={<Edit16Filled />}>{t({ id: 'FileList.Menu.Sign' })}</MenuItem>}
          <MenuItem onClick={onPreview} icon={<Eye16Regular />}>{t({ id: 'FileList.Menu.Preview' })}</MenuItem>
          {/*TODO: enable in next sprint*/}
          {/*<MenuItem onClick={onDownload} icon={<ArrowDownload16Regular />}>{t({ id: 'FileList.Menu.Download' })}</MenuItem>*/}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

const openFilePreview = (file) => window.open(file.webUrl, '_blank');

const isFileSignable = (file) => file.mimeType === FILE_FORMATS_MAPPED.PDF;

const buildNameCell = (entry, { onFolderClick }) => ({
  key: `${entry.id}-name`,
  className: 'file-list__table-cell-with-flex',
  content: (
    <Flex fill gap="gap.small" vAlign="center">
      <Icon entry={entry} />
      <>
        {entry.folder ? (
          <Text
            onClick={() => onFolderClick(entry)}
            tabIndex={0}
            className="file-list__folder-link"
          >{entry.name}</Text>
        ) : (
          <Text>{entry.name}</Text>
        )}
      </>
    </Flex>
  ),
});

const buildOptionsCell = (entry, { onFileClick }) => ({
  key: `${entry.id}-options`,
  content: (
    <div className="file-list__options-cell">
      {entry.file && (
        <MoreOptions
          {...(isFileSignable(entry.file) && { onSign: () => onFileClick(entry) })}
          onPreview={() => openFilePreview(entry)}
        />
      )}
    </div>
  ),
});

const buildDateCell = (entry, { dateFormatter }) => ({
  key: `${entry.id}-lastModifiedDateTime`,
  content: dateFormatter(new Date(entry.lastModifiedDateTime)),
});

const buildAuthorCell = (entry) => ({
  key: `${entry.id}-author`,
  content: entry.createdBy?.user?.displayName,
});

const dateFormatterOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

const FileList = ({ files, onFolderClick, onFileClick }) => {
  const header = [
    t({ id: 'FileList.Header.Name' }),
    '', // options
    t({ id: 'FileList.Header.LastModifiedDate' }),
    t({ id: 'FileList.Header.Author' }),
  ];
  const dateFormatter = useDateFormatter(dateFormatterOptions);
  const rows = useMemo(() => {
    return (Array.isArray(files?.value) ? [...files.value] : []).map((entry) => ({
      key: entry.id,
      items: [
        buildNameCell(entry, { onFolderClick }),
        buildOptionsCell(entry, { onFileClick }),
        buildDateCell(entry, { dateFormatter }),
        buildAuthorCell(entry),
      ],
    }));
  }, [files, onFolderClick, onFileClick]);


  return (
    <Table>
      <TableHeader>
        <TableRow>
          {header.map((column, index) => (
            <TableHeaderCell key={`header-${index}`}>
              {column}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={`row-${index}`}>
            {row.items.map((item) => (
              <TableCell key={item.key}>
                {item.content}
              </TableCell>
            ))}
          </TableRow>))}
      </TableBody>
    </Table>
  )
};

export default FileList;
