import { Button, Text } from '@fluentui/react-components';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { ArrowClockwise48Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import * as microsoftTeams from '@microsoft/teams-js';
import './LicenseReload.scss';

export const LicenseReload = () => {
  const submitTaskSuccess = () => microsoftTeams.dialog.url.submit('reload');

  return (
    <WizardBox className="license-reload-window">
      <div></div>
      <div className="license-reload-window__content">
        <ArrowClockwise48Regular className="license-reload-window__icon" />
        <Text size={500} weight="bold" className="license-reload-window__header">
          {t({ id: 'Common.Refresh' })}
        </Text>
        <Text size={400} className="license-reload-window__description">
          {t({ id: 'Freemium.Refresh.Description' })}
        </Text>
      </div>
      <div className="license-reload-window__button">
        <div></div>
        <Button appearance="primary" onClick={submitTaskSuccess}>
          {t({ id: 'Common.Refresh' })}
        </Button>
      </div>
    </WizardBox>
  );
};
