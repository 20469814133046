import { useState } from 'react';
import { Dialog, DialogTrigger, DialogSurface, DialogBody, Link } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { ProcessDetailsBody } from './ProcessDetailsBody';
import './ProcessDetails.scss';

export const ProcessDetails = ({
  sessionId,
  name,
  fileName,
  startDate,
  canCancel,
  onMadeChanges,
  onEditSignatureClick,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [madeChanges, setMadeChanges] = useState(false);

  const closeDetails = (withReload = false) => {
    if (madeChanges || withReload) {
      onMadeChanges();
    }
  };

  const changeDialogStateHandle = (state) => {
    setIsDialogOpen(state);

    if (!state) {
      closeDetails();
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={(_, data) => changeDialogStateHandle(data.open)}>
      <DialogTrigger>
        <Link>{t({ id: 'Dashboard.Table.Column.Details.Display' })}</Link>
      </DialogTrigger>
      <DialogSurface className="process-details-dialog">
        <DialogBody>
          <ProcessDetailsBody
            sessionId={sessionId}
            name={name}
            canCancel={canCancel}
            startDate={startDate}
            fileName={fileName}
            setMadeChanges={setMadeChanges}
            onEditSignatureClick={onEditSignatureClick}
            closeDetails={closeDetails}
          />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
