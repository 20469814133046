import { createContext, useContext, useEffect, useState } from 'react';
import { meeting as meetingApi } from 'shared-fe-components/src/api';
import { useTeamsContext, useUserContext } from 'contexts';
import { TeamsUserCredential } from '@microsoft/teamsfx';
import { useLatestCallable } from '../../lib/useLatestCallable';
import { LoadingScreen } from '../../components/Common/LoadingScreen';
import { MeetingObject, Meeting, User, MeetingProviderProps } from './models';

const Context = createContext<MeetingObject | null>(null);

export const MeetingContextProvider = ({ children }: MeetingProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [meeting, setMeeting] = useState<Meeting | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const { context: teamsContext } = useTeamsContext();
  const { customToken } = useUserContext();

  const isAuthenticatedUser = useLatestCallable(async () => {
    if (customToken) {
      return true;
    }

    try {
      await new TeamsUserCredential({
        initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
        clientId: process.env.REACT_APP_CLIENT_ID
      }).getUserInfo();
      return true;
    } catch (err) {
      return false;
    }
  });

  const updateContextAsync = useLatestCallable(async () => {
    const meeting = await meetingApi.getMeetingByTeamsMeetingId(teamsContext.meeting.id);
    if (!meeting) {
      setTimeout(updateContextAsync, 5000);
      return;
    }

    let user = null;
    if (await isAuthenticatedUser()) {
      user = await meetingApi.getCurrentMeetingParticipant(meeting.id);
      setUser(user);
    }

    setMeeting(meeting);
    setLoading(false);
  });

  useEffect(() => {
    (async () => {
      if (teamsContext.meeting?.id && (await isAuthenticatedUser())) {
        await updateContextAsync();
      } else {
        setLoading(false);
      }
    })();
  }, [teamsContext, customToken, isAuthenticatedUser, updateContextAsync]);

  return (
    <Context.Provider value={{ meeting, user, token: customToken }}>
      {loading ? <LoadingScreen /> : <>{children}</>}
    </Context.Provider>
  );
};

export const useMeetingContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Lack of MeetingProvider in components tree');
  }

  return ctx;
};
