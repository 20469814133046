import { useReducer, useRef, useCallback, useEffect } from 'react';

const typeLoadingWithParams = Symbol('loading (with-params)');
const typeLoadedWithParams = Symbol('loaded (with-params)');
const typeErrorWithParams = Symbol('error (with-params)');
const typeLoadingWithToken = Symbol('loading (with-token)');
const typeLoadedWithToken = Symbol('loaded (with-token)');
const typeErrorWithToken = Symbol('error (with-token)');

const actionLoadingWithParams = (counter, params) => ({ type: typeLoadingWithParams, counter, params });
const actionLoadedWithParams = (counter, list, token) => ({ type: typeLoadedWithParams, counter, list, token });
const actionErrorWithParams = (counter, error) => ({ type: typeErrorWithParams, counter, error });
const actionLoadingWithToken = (counter) => ({ type: typeLoadingWithToken, counter });
const actionLoadedWithToken = (counter, list, token) => ({ type: typeLoadedWithToken, counter, list, token });
const actionErrorWithToken = (counter, error) => ({ type: typeErrorWithToken, counter, error });

const initialState = {
  counter: -1,
  loading: false,
  error: null,
  errorOrigin: null,
  list: [],
  token: null,
  params: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case typeLoadingWithParams:
      return {
        ...state,
        counter: action.counter,
        loading: true,
        list: [],
        token: null,
        params: action.params,
      };
    case typeLoadedWithParams: {
      if (state.counter !== action.counter) {
        // stale response, ignore
        return state;
      }

      return {
        ...state,
        loading: false,
        error: null,
        errorOrigin: null,
        list: action.list,
        token: action.token,
      };
    }
    case typeErrorWithParams: {
      if (state.counter !== action.counter) {
        // stale response, ignore
        return state;
      }

      return {
        ...state,
        loading: false,
        error: action.error,
        errorOrigin: 'params',
      };
    }
    case typeLoadingWithToken:
      return {
        ...state,
        counter: action.counter,
        loading: true,
        token: null
      };
      case typeLoadedWithToken: {
      if (state.counter !== action.counter) {
        // stale response, ignore
        return state;
      }

      return {
        ...state,
        loading: false,
        error: null,
        errorOrigin: null,
        list: [...state.list, ...action.list],
        token: action.token,
      };
    }
    case typeErrorWithToken: {
      if (state.counter !== action.counter) {
        // stale response, ignore
        return state;
      }

      return {
        ...state,
        loading: false,
        error: action.error,
        errorOrigin: 'token'
      };
    }
    default:
      return state;
  }
};

/*
Example usage:
// this function will be called each time the params change
const fetchWithParams = ({ param1, param2 }) => {
  return fetch('https://example.com', { body: JSON.stringify({ param1, param2 }) })
    .then((response) => response.json())
    // result must be returned as `[*array of values*, *continuation token*]`
    .then((result) => [result.values, result.token]);
};
// this function will be called when `loadMore` is called and while other related fetch request is in progress
const fetchWithToken = (token, params) => {
  return fetch('https://example.com', { body: JSON.stringify({ token, params }) })
    .then((response) => response.json())
    // result must be returned as `[*array of values*, *continuation token*]`
    .then((result) => [result.values, result.token]);
};

const useExampleData = createUseContinuousData(fetchWithParams, fetchWithToken);

const Component = () => {
  ...

  const params = useMemo(() => ({ param1, param2 }), [param1, param2]);
  const { list, error, errorOrigin, loading, hasMore, loadMore, retry } = useExampleData(params);

  ...
};
*/
export const createUseContinuousData = (fetchWithParams, fetchWithToken) => (params) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const counterRef = useRef(0);

  // loads fresh list
  const load = useCallback((params) => {
    const promise = fetchWithParams(params);
    
    const counter = ++counterRef.current;
    dispatch(actionLoadingWithParams(counter, params));

    promise
      .then(([list, token]) => dispatch(actionLoadedWithParams(counter, list, token)))
      .catch((error) => dispatch(actionErrorWithParams(counter, error)));

    return true;
  }, []);

  // loads and appends more data to current list
  const loadMore = useCallback(() => {
    if (state.loading || !state.token) {
      // do not allow fetching more data if any other request is in progress
      return false;
    }

    const promise = fetchWithToken(state.token, state.params);
    
    const counter = ++counterRef.current;
    dispatch(actionLoadingWithToken(counter));

    promise
      .then(([list, token]) => dispatch(actionLoadedWithToken(counter, list, token)))
      .catch((error) => dispatch(actionErrorWithToken(counter, error)));

    return true;
  }, [state.loading, state.token, state.params]);

  const retry = useCallback(() => {
    if (state.errorOrigin === 'params') {
      return load(state.params);
    }

    if (state.errorOrigin === 'token') {
      return loadMore();
    }

    return false;
  }, [load, loadMore, state.params, state.errorOrigin]);

  // refresh list when params change
  // IMPORTANT: "params" needs to be stable enough, so that it does not trigger loading on each component rendering
  useEffect(() => {
    load(params);
  }, [load, params]);

  return {
    loading: state.loading,
    list: state.list,
    error: state.error,
    errorOrigin: state.errorOrigin,
    hasMore: !!state.token,
    load,
    loadMore,
    retry,
  };
};
