import * as client from "./client";

export const getMeetingByTeamsMeetingId = async (teamsMeetingId: any) =>
  await client.getSafe(`meeting/teams/${teamsMeetingId}`);

export const getMeeting = async (meetingId: any) =>
  await client.getSafe(`meeting/${meetingId}`);

export const getCurrentMeetingParticipant = async (meetingId: any) =>
  await client.getSafe(`meeting/${meetingId}/participants/me`);

export const getMeetingParticipants = async (meetingId: any) =>
  await client.getSafe(`meeting/${meetingId}/participants`);

export const getVerificationSession = async (meetingId: any) =>
  await client.getSafe(`meeting/${meetingId}/verification-session`);

export const getSigningSession = async (meetingId: any, sessionId: any) =>
  await client.getSafe(`meeting/${meetingId}/signing-sessions/${sessionId}`);

export const getSigningSessions = async (meetingId: any) =>
  await client.getSafe(`meeting/${meetingId}/signing-sessions`);

export const getVerificationStatus = async (meetingId: any) =>
  await client.getSafe(`meeting/${meetingId}/verification-session/status`);

export const startVerification = async (meetingId: any) =>
    await client.post(`meeting/${meetingId}/verification-session/start`, {});

export const getVerificationSummary = async (meetingId: any) =>
  await client.getSafe(`meeting/${meetingId}/verification-session/summary`);
