import { Divider, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { useConfigurationContext, useUserContext } from 'contexts';
import * as microsoftTeams from '@microsoft/teams-js';
import { Trans } from '@lingui/react';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import './FreemiumBanner.scss';

const CONTACT_US_LINK =
  'https://outlook.office365.com/owa/calendar/InperlyweryfikacjatosamociipodpiswMSTeams@billennium.com/bookings/s/4Bn1c1lQEU2z8r-nFXwGoQ2';

export const FreemiumBanner = ({ onMeeting }: { onMeeting?: boolean }) => {
  const { freemiumStatus } = useUserContext();
  const { appId } = useConfigurationContext();

  const { limits, validUntil } = { ...freemiumStatus };
  const remainingUsage = limits?.remainingUsage;
  const usageLimit = limits?.usageLimit;

  const header = (
    <Text size={300} className="freemium-banner__header">
      {t({ id: 'Freemium.Banner.Header.Title' })}
    </Text>
  );

  const validDays = validUntil
    ? Math.floor(
        (new Date(new Date(validUntil).toDateString()).getTime() - new Date(new Date().toDateString()).getTime()) /
          (1000 * 3600 * 24)
      )
    : '-';

  const onPurchaseSubscription = async () => {
    await microsoftTeams.appInstallDialog.openAppInstallDialog({
      appId: appId,
    });
  };

  const content = (
    <div className="freemium-banner__limit">
      <div className="limit--item">
        <Text className="limit--item-usage" size={800}>
          {remainingUsage}
        </Text>
        <Text className="limit--item-label">{t({ id: 'Freemium.Banner.Limit.Signatures' })}</Text>
      </div>
      <Divider vertical={!onMeeting} />
      <div className="limit--item">
        <Text className="limit--item-usage" size={800}>
          {t({ id: 'Freemium.Banner.Limit.LongTermCertificates.Unlimited' })}
        </Text>
        <Text className="limit--item-label">{t({ id: 'Freemium.Banner.Limit.LongTermCertificates' })}</Text>
      </div>
      <Divider vertical={!onMeeting} />
      <div className="limit--item">
        <Text className="limit--item-usage" size={800}>
          {validDays}
        </Text>
        <Text className="limit--item-label">{t({ id: 'Freemium.Banner.Limit.Days' })}</Text>
      </div>
    </div>
  );

  const footer = (
    <div className="freemium-banner__footer">
      <Text>{t({ id: 'Freemium.Banner.Footer.Header' })}</Text>
      <Text>
        <Trans
          id="Freemium.Banner.Footer.Contact"
          components={[
            <Anchor href={CONTACT_US_LINK} target="_blank" rel="noreferrer noopener" />,
            <Anchor onClick={onPurchaseSubscription} />,
          ]}
        />
      </Text>
      <Text size={200}>{t({ id: 'Freemium.Banner.Footer.Transfer' })}</Text>
    </div>
  );

  return (
    <div className={`freemium-banner freemium-banner${onMeeting ? '__meeting' : '__dashboard'}`}>
      {header}
      {content}
      {footer}
    </div>
  );
};
