import { useEffect, useState } from 'react';
import { TeamsUserCredential } from "@microsoft/teamsfx";

export function useGraphConsent(options) {
  const [consentNeeded, setConsentNeeded] = useState(undefined);
  const [consentAcquired, setConsentAcquired] = useState(undefined);

  const { scope } = {
    scope: [
      "User.Read",
      "User.ReadBasic.All",
      "Files.Read.All",
      "Files.ReadWrite.All",
      "Team.ReadBasic.All"
    ],
    ...options
  };

  useEffect(() => {
    const func = async () => {
      if (consentNeeded === undefined) {
        const consentNeeded = await checkConsentNeeded();
        setConsentNeeded(consentNeeded);
        if (!consentNeeded) {
          setConsentAcquired(true);
        }
      } else if (consentNeeded) {
        if (!options?.skipConsent) {
          await consent();
        }
      }
    };

    func();
  }, [consentNeeded]);

  const checkConsentNeeded = async () => {
    try {
      const credential = new TeamsUserCredential({
        initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
        clientId: process.env.REACT_APP_CLIENT_ID
      });
      await credential.getToken(scope);
      return false;
    } catch (err) {
      return true;
    }
  };

  const consent = async () => {
    try {
      const credential = new TeamsUserCredential({
        initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
        clientId: process.env.REACT_APP_CLIENT_ID
      });
      await credential.login(scope);
      setConsentAcquired(true);
      setConsentNeeded(false);
    } catch (error) {
      if (error.code === 'ConsentFailed') {
        setConsentAcquired(false);
        if (error.message.includes('FailedToOpenWindow')) {
          //TODO: Tell user to allow popups in order to be able to give permissions for the app
        }
      }
    }
  };

  return { consentNeeded, consentAcquired, consent };
}
