import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileBrowserModal } from '../../Common/FileBrowser/FileBrowser';
import { PeopleOrder, PeopleSelection } from './People';
import { useConfigurationContext, useMeetingContext } from 'contexts';
import { XmlSelectionModal } from 'shared-fe-components/src/common/XmlSelectionModal';
import { TaskContainer } from '../../Common/TaskContainer';
import { ErrorScreen } from 'shared-fe-components/src/common/StatusScreens';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { t } from '@lingui/macro';
import { FileObject } from '../../../lib/fileObject';
import * as api from 'shared-fe-components/src/api';
import * as microsoftTeams from '@microsoft/teams-js';
import './StartSigningTask.scss';
import { FILE_FORMATS_MAPPED } from 'shared-fe-components/src/utils/fileFormats';
import { useUploadOnStorage } from 'lib/useUploadOnStorage';

export const StartSigningTask = () => {
  const [file, setFile] = useState(null);
  const [step, setStep] = useState('loading');
  const { signing } = useConfigurationContext();
  const { meeting } = useMeetingContext();
  const selectedPeople = useRef([]);
  const meetingParticipants = useRef([]);
  const { putItOnStorage } = useUploadOnStorage();

  const submitTaskSuccess = () => microsoftTeams.dialog.url.submit({ submitReason: 'success' });
  const submitTaskCancelled = () => microsoftTeams.dialog.url.submit({ submitReason: 'cancel' });

  const goToAddFile = () => setStep('add-file');
  const goToPeopleSelection = () => setStep('people-selection');
  const goToPeopleOrder = () => setStep('people-order');
  const goToLoading = () => setStep('loading');
  const goToNoConsent = () => setStep('no-consent');
  const goToError = () => setStep('error');
  const goToLoadingError = () => setStep('loading-error');

  const meetingId = useMemo(() => meeting.id, [meeting.id]);

  // initital meeting info loading and photo loading
  useEffect(() => {
    let participants = [];
    if (meetingId) {
      (async () => {
        try {
          participants = await api.meeting.getMeetingParticipants(meetingId);
          meetingParticipants.current = participants;
          goToAddFile();
        } catch (err) {
          goToLoadingError();
        }
      })();
    }
    return () => {
      for (const participant of participants) {
        URL.revokeObjectURL(participant.photoUrl);
      }
    };
  }, [meetingId]);

  const onFileChosen = async (file) => {
    goToLoading();
    const fileObj = await FileObject.createFromFileBrowserFile(file);
    setFile(fileObj);
    goToPeopleSelection();
  };

  const onPeopleSelected = (incomingPeople, goForward = true) => {
    // go over incomingPeople and append if not found in what we have
    // this preserves order and adds new signees to the end

    // remove those who were removed
    const people = [...selectedPeople.current].filter((p) => incomingPeople.includes(p));
    // append those who were added new
    for (const p of incomingPeople) {
      if (!people.includes(p)) {
        people.push(p);
      }
    }
    selectedPeople.current = people;
    if (goForward) {
      goToPeopleOrder();
    } else {
      goToAddFile();
    }
  };

  const onPeopleOrdered = (people, goForward = true) => {
    selectedPeople.current = people;
    if (goForward) {
      if (file.name.endsWith('.xml')) {
        setStep('xml-selection');
      } else {
        handleStartSigning();
      }
    } else {
      goToPeopleSelection();
    }
  };

  const onXmlTypeSelect = async (xadesFormat) => {
    await handleStartSigning(xadesFormat);
  };

  const handleStartSigning = async (xadesFormat) => {
    goToLoading();

    try {
      let bodyObj = {};
      if (file.teamsFileSource === null) {
        const { container, isError } = await putItOnStorage([file]);

        if (isError) return goToError();

        bodyObj = {
          sources: container.documents.map((file) => ({
            filename: file.fileName,
            type: 'StorageContainer',
            StorageContainerSource: { ContainerId: container.id, FileId: file.id },
          })),
          xadesFormat: xadesFormat,
        };
      } else {
        const sources = [{ teamsFileSource: { ...file.teamsFileSource } }];

        bodyObj = {
          sources: sources,
          xadesFormat: xadesFormat,
        };
      }

      const recipients = selectedPeople.current.map((participant) => ({
        meetingParticipantId: participant.id,
        type: participant.teamsMeetingParticipant.origin,
        email: participant.email,
        userId: participant.teamsMeetingParticipant.userId,
      }));

      bodyObj = { ...bodyObj, signatureType: 'Sequential', recipients, meetingId };

      await api.signing.requestSignature(bodyObj);
      submitTaskSuccess();
    } catch {
      goToError();
    }
  };

  return (
    <TaskContainer className="start-signing-task">
      {step === 'add-file' && (
        <FileBrowserModal
          allowedFileTypes={[FILE_FORMATS_MAPPED.PDF, FILE_FORMATS_MAPPED.XML]}
          allowedFileSize={signing.maxFileSize}
          onFileSelect={onFileChosen}
          onCancel={submitTaskCancelled}
        />
      )}
      {step === 'people-selection' && (
        <PeopleSelection
          meetingParticipants={meetingParticipants.current}
          selectedPeople={selectedPeople.current}
          onPeopleSelected={onPeopleSelected}
        />
      )}
      {step === 'people-order' && (
        <PeopleOrder selectedPeople={selectedPeople.current} onPeopleOrdered={onPeopleOrdered} />
      )}
      {step === 'xml-selection' && (
        <XmlSelectionModal onBack={() => setStep('people-order')} onCancel={submitTaskCancelled} onContinue={onXmlTypeSelect} />
      )}
      {step === 'loading' && <LoadingScreen />}
      {step === 'no-consent' && (
        <ErrorScreen message={t({ id: 'Dashboard.Signing.Errors.NoConsent' })} onCancel={submitTaskCancelled} />
      )}
      {step === 'error' && (
        <ErrorScreen errorCode="SomethingWentWrong" onCancel={submitTaskCancelled} onRetry={goToPeopleOrder} />
      )}
      {step === 'loading-error' && (
        <ErrorScreen message={t({ id: 'StartSignTask.LoadingError' })} onCancel={submitTaskCancelled} />
      )}
    </TaskContainer>
  );
};
