import { useEffect, useState, useMemo } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  Link,
  Text,
} from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { Button } from 'shared-fe-components/src/common/FluentMigration';
import {
  Checkmark20Filled,
  Dismiss20Filled,
  Edit20Regular,
  Warning16Regular,
  Merge24Filled,
} from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { FileRepresentation } from '../../Common/FileRepresentation/';
import { FileRow, FileValidationErrorTypes, MergeConvertState, ValidationError } from './models';
import { FileBrowser } from '../../Common/FileBrowser';
import { format as formatBytes } from 'bytes';
import { FILE_FORMATS_MAPPED } from 'shared-fe-components/src/utils/fileFormats';
import { useConfigurationContext, useUserContext } from 'contexts';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FileObject } from '../../../lib/fileObject';
import { merging } from 'shared-fe-components/src/api';
import { FileDropper } from 'components/Common/FileDropper';
import { MergeConvertStateDialog } from './MergeConvertStateDialog';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Header } from 'shared-fe-components/src/common/Header';
import { FormCheck } from 'shared-fe-components/src/common/ReactHookForm/FormCheck';
import { checkPdfHasSignature, extractFileFormat } from 'shared-fe-components/src/utils';
import './FilesMergeConvert.scss';

const appendFilename = (originalName: string): string => {
  const extensionIndex = originalName.lastIndexOf('.');
  let result = originalName;
  if (extensionIndex !== -1) {
    const nameWithoutExtension = originalName.slice(0, extensionIndex);
    result = nameWithoutExtension;
  }
  return result + '_MERGED.pdf';
};

const validateMerge = (checks) => {
  const validation = checks.map((check) => ({ message: check.validateFn(), type: check.type }));
  const errors = validation.filter((validationResult) => validationResult.message !== true);
  return errors;
};

const isMergableFileType = (filename: string) => filename.toLowerCase().endsWith('.pdf');

const FILE_COUNT_LIMIT = 25;

export const FilesMergeConvert = () => {
  const [isFileBrowserOpen, setIsFileBrowserOpen] = useState(false);
  const [mergeSessionId, setMergeSessionId] = useState(null);
  const [mergeConvertState, setMergeConvertState] = useState<MergeConvertState>(MergeConvertState.Idle);
  const [pendingMergedFile, setPendingMergedFile] = useState<File | null>(null);
  const [isEditingFilenames, setIsEditingFilenames] = useState<boolean>(false);
  const [isValidatingMerging, setIsValidatingMerging] = useState<boolean>(false);
  const [mergeValidationErrors, setMergeValidationErrors] = useState<ValidationError[]>([]);
  const [isMergeSignedDialogVisible, setIsMergeSignedDialogVisible] = useState<boolean>(false);
  const [isFileLimitErrorVisible, setIsFileLimitErrorVisible] = useState<boolean>(false);
  const formContext = useFormContext();
  const { signing } = useConfigurationContext();
  const { features } = useUserContext();

  const {
    control,
    setValue,
    getValues,
    register,
    trigger,
    formState: { errors, submitCount },
  } = formContext;

  const mergeFileChecks = [
    {
      key: 'AtLeastTwoFiles',
      type: FileValidationErrorTypes.AtLeastTwoFiles,
      validateFn: () =>
        getValues('files').filter((file) => file.isSelected === true).length > 1
          ? true
          : t({ id: 'Dashboard.Signing.Errors.AtLeastTwoFiles' }),
    },
    {
      key: 'MaximumSize',
      type: FileValidationErrorTypes.MaximumSize,
      validateFn: () => {
        const fileSizeSum = getValues('files')
          .filter((file) => file.isSelected === true)
          .reduce((sum, { value }) => sum + value.size, 0);
        return fileSizeSum >= fileSizeLimitInBytes
          ? t({ id: 'Dashboard.Signing.Errors.TooBigFiles', values: { maximumFilesSize: fileSizeLimitInHuman } })
          : true;
      },
    },
    {
      type: FileValidationErrorTypes.IsEditing,
      validateFn: () => (isEditingFilenames ? t({ id: 'FilesMergeConvert.Error.IsEditing' }) : true),
    },
    {
      type: FileValidationErrorTypes.FilenameEmpty,
      validateFn: () => {
        const selectedFiles = getValues('files').filter((file) => file.isSelected === true);
        return selectedFiles.some((file) => file.value.name === '')
          ? t({ id: 'FilesMergeConvert.Error.MergeFilenameEmpty' })
          : true;
      },
    },
  ];

  const formFileChecks = [
    {
      key: 'validationOneFile',
      fieldName: 'validationOneFile',
      validateFn: () => (getValues('files').length === 1 ? true : t({ id: 'Dashboard.Signing.Errors.OneFile' })),
    },
    {
      key: 'validationEachFileSmallerThan',
      fieldName: 'validationEachFileSmallerThan',
      validateFn: () => {
        const tooBigFiles = getValues('files')
          .map((entry: FileRow) => entry.value)
          .filter((file: FileObject) => file.size >= fileSizeLimitInBytes)
          .map((file: FileObject) => file.name);
        const fileList = tooBigFiles.join(', ');
        if (tooBigFiles.length === 0) {
          return true;
        } else if (tooBigFiles.length === 1) {
          return t({ id: 'Dashboard.Signing.Errors.FileTooBig', values: { file: fileList } });
        } else {
          return t({ id: 'Dashboard.Signing.Errors.FilesTooBig', values: { files: fileList } });
        }
      },
    },
    {
      key: 'validationIsEditingFilenames',
      fieldName: 'validationIsEditingFilenames',
      validateFn: () => (isEditingFilenames ? t({ id: 'Dashboard.Signing.Errors.IsEditing' }) : true),
    },
    {
      key: 'validationEmptyFilenames',
      fieldName: 'validationEmptyFilenames',
      validateFn: () => {
        const hasEmptyFilenames = getValues('files').some(({ value }: FileRow) => value.name === '');
        return hasEmptyFilenames ? t({ id: 'FilesMergeConvert.Error.FilenameEmpty' }) : true;
      },
    },
  ].map((checkProps) => <FormCheck {...checkProps} />);

  const files = useWatch({ name: 'files', control });

  const { fields, append, remove, move, insert, prepend } = useFieldArray({
    control: formContext.control,
    name: 'files',
  });

  const fileSizeLimitInBytes = signing.maxFileSize;
  const fileSizeLimitInHuman = formatBytes(fileSizeLimitInBytes);

  const replaceMergedFiles = async (resultFile: File) => {
    const addedFiles = getValues('files');
    const indicesToDelete = addedFiles.reduce((indices, currentFile, currentIndex) => {
      if (currentFile.isSelected === true) {
        indices.push(currentIndex);
      }
      return indices;
    }, []);
    remove(indicesToDelete);

    addFilesToForm([
      await FileObject.createFromLocal({
        name: resultFile.name,
        nativeFile: resultFile,
        size: resultFile.size,
      }),
    ]);

    setIsValidatingMerging(false);
    setMergeValidationErrors([]);
  };

  useEffect(() => {
    if (!mergeSessionId) return;

    const interval = setInterval(async () => {
      try {
        const mergeInfo = await merging.getMergeSession(mergeSessionId);
        if (mergeInfo.status === 'Finished') {
          clearInterval(interval);
          const mergedFileResponse = await merging.getMergedFile(mergeInfo.tasks[0].downloadUrl);
          const mergedFileBlob = await mergedFileResponse.blob();
          const mergedFileObject = new File([mergedFileBlob], mergeInfo.tasks[0].outputFileName, {
            type: FILE_FORMATS_MAPPED.PDF,
          });
          setPendingMergedFile(mergedFileObject);
          setMergeConvertState(MergeConvertState.MergeSuccess);
        }
      } catch (err) {
        clearInterval(interval);
        console.error(err);
        setMergeConvertState(MergeConvertState.MergeError);
      }
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, [mergeSessionId]);

  const addFilesToForm = (files: FileObject[]) => {
    const filesForForm = files.map((file) => ({
      value: file,
      isSelected: false,
      editFilename: file.name,
    }));
    const allowedFilesLeft = FILE_COUNT_LIMIT - fields.length;
    const filesToAdd = filesForForm.slice(0, allowedFilesLeft);

    const hasReachedFileLimit = filesForForm.length > allowedFilesLeft;
    setIsFileLimitErrorVisible(hasReachedFileLimit);

    const pdfFiles = filesToAdd.filter(({ value }) => extractFileFormat(value.name) === 'pdf');
    const xmlFiles = filesToAdd.filter(({ value }) => extractFileFormat(value.name) === 'xml');

    const lastPdfIndex = getValues('files').findLastIndex(
      ({ value }: FileRow) => extractFileFormat(value.name) === 'pdf'
    );

    if (lastPdfIndex === -1) {
      prepend(pdfFiles);
      append(xmlFiles);
    } else {
      insert(lastPdfIndex + 1, pdfFiles);
      append(xmlFiles);
    }

    if (submitCount !== 0) trigger();
  };

  const addFileFromFileBrowser = async (file: File) => {
    setIsFileBrowserOpen(false);
    const newFile = await FileObject.createFromFileBrowserFile(file);
    addFilesToForm([newFile]);
  };

  useEffect(() => {
    if (isValidatingMerging) {
      const errors = validateMerge(mergeFileChecks);
      setMergeValidationErrors(errors);
    }
  }, [isValidatingMerging, files, isEditingFilenames]);

  const checkFilesHaveSignature = async (): Promise<boolean> => {
    const selectedFiles = getValues('files').filter((file) => file.isSelected === true);
    const results = await Promise.all(
      selectedFiles.map(async ({ value }) => await checkPdfHasSignature(value.nativeFile))
    );
    return results.includes(true);
  };

  const handleMergeClick = async () => {
    if (await checkFilesHaveSignature()) {
      setIsMergeSignedDialogVisible(true);
    } else {
      startMerge();
    }
  };

  const handleMergeSignedDialogResult = (hasConfirmed: boolean) => {
    if (hasConfirmed) {
      startMerge();
    }
    setIsMergeSignedDialogVisible(false);
  };

  const startMerge = async () => {
    setIsValidatingMerging(true);
    const errors = validateMerge(mergeFileChecks);
    setMergeValidationErrors(errors);
    if (errors.length > 0) {
      return;
    }

    const formData = getValues();
    const selectedFiles = formData.files.filter((file) => file.isSelected === true);

    const fileNames = selectedFiles.map((file) => ({ fileName: file.value.name }));
    setMergeConvertState(MergeConvertState.Merging);
    try {
      // 1. Get new container
      const container = await merging.requestContainer({ documents: fileNames });
      const filesWithUrls = selectedFiles.map((file, index) => ({
        fileData: file.value.nativeFile,
        url: container.documents[index].uploadUrl,
      }));

      // 2. Upload files to the container
      for (const file of filesWithUrls) {
        await merging.uploadFile(file.url, file.fileData);
      }

      const mergeData = {
        mergeTasks: [
          {
            outputFileName: appendFilename(filesWithUrls[0].fileData.name),
            sources: container.documents.map((file) => ({
              filename: file.fileName,
              type: 'StorageContainer',
              StorageContainerSource: { ContainerId: container.id, FileId: file.id },
            })),
          },
        ],
      };

      // 3. Request merge
      const mergeSession = await merging.mergePdf(mergeData);

      // 4. Wait until merged
      setMergeSessionId(mergeSession.sessionId);
    } catch (err) {
      console.error(err);
      setMergeConvertState(MergeConvertState.MergeError);
    }
  };

  const addedFilesProps = useMemo(
    () =>
      fields.map((field, index) => {
        const inputFileName = `files.${index}.value`;
        const inputCheckboxName = `files.${index}.isSelected`;
        const inputEditName = `files.${index}.editFilename`;
        const key = field.id;
        const fileObj = new FileObject(getValues(inputFileName));
        const filename = fileObj?.name;
        const nativeFile = fileObj?.nativeFile;
        const errorMessage = (errors?.['files']?.[index]?.value?.message ||
          errors?.['files']?.[index]?.editFilename?.message) as unknown as string;

        const onDelete = () => {
          remove(index);
          setIsFileLimitErrorVisible(false);
          if (submitCount !== 0) trigger();
        };

        const inputFileProps = {
          ...register(inputFileName),
          readOnly: true,
          hidden: true,
        };

        const fileRepProps = {
          key,
          filename,
          inputFileProps,
          selection: { fieldName: inputCheckboxName },
          errorMessage,
          nativeFile,
          editing: { fieldName: inputEditName },
          onDelete,
        };
        return fileRepProps;
      }),
    [fields, errors]
  );

  const onAddFiles = async (files: File[]) => {
    const filesPromises = files.map((file) =>
      FileObject.createFromLocal({ name: file.name, size: file.size, nativeFile: file })
    );
    const filesResolvedPromises = await Promise.all(filesPromises);
    addFilesToForm(filesResolvedPromises);
  };

  const fileInputs = useMemo(() => {
    return addedFilesProps.map((file, index) => {
      const inputCheckboxName = `files.${index}.isSelected`;
      const selection =
        features.MergingFiles && isMergableFileType(file.filename) ? { fieldName: inputCheckboxName } : null;
      return (
        <Draggable index={index} draggableId={file.key} key={file.key}>
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <input {...file.inputFileProps} />
              <FileRepresentation
                filename={file.filename}
                nativeFile={file.nativeFile}
                onDelete={file.onDelete}
                selection={selection}
                order={{ number: index, dragHandleProps: provided.dragHandleProps }}
                editing={{
                  fieldName: file.editing.fieldName,
                  isEditing: isEditingFilenames,
                }}
              />
            </div>
          )}
        </Draggable>
      );
    });
  }, [addedFilesProps, features, isEditingFilenames]);

  const acceptedFileTypes = { 'application/pdf': ['.pdf'], 'application/xml': ['.xml'] };

  const onInputClick = () => setIsFileBrowserOpen(true);

  const handleOnDragEnd = ({ destination, source }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  const mergeCheckValue = useMemo(() => {
    if (files.filter(({ value }) => value.name.toLowerCase().endsWith('.pdf')).every((file) => file.isSelected)) {
      return true;
    }
    if (files.filter(({ value }) => value.name.toLowerCase().endsWith('.pdf')).some((file) => file.isSelected)) {
      return 'mixed';
    }
    return false;
  }, [files]);

  const toggleMergeCheck = () => {
    const newIsSelectedValue = mergeCheckValue !== true;
    const newFilesValue = files.map((file) => {
      const isPdf = file.value.name.toLowerCase().endsWith('.pdf');
      return isPdf ? { ...file, isSelected: newIsSelectedValue } : file;
    });
    setValue('files', newFilesValue);
  };

  const handleConfirmMerge = ({ mergedFilename }) => {
    if (mergedFilename && mergedFilename.length > 0) {
      const renamedFile = new File([pendingMergedFile!], mergedFilename);
      replaceMergedFiles(renamedFile);
      setPendingMergedFile(null);
    }
    setMergeConvertState(MergeConvertState.Idle);
  };

  const handleCancelMerge = () => {
    setPendingMergedFile(null);
    setMergeConvertState(MergeConvertState.Idle);
  };

  const handleEditNames = () => {
    const hasEmptyFilenames = files.some((file) => file.editFilename.length === 0);
    if (hasEmptyFilenames) {
      return;
    }

    const renamedFiles = files.map((file) => {
      let newFilename = file.editFilename;
      const fileFormat = extractFileFormat(file.value.name);
      if (fileFormat && newFilename !== file.value.name) {
        newFilename += `.${fileFormat}`;
      }
      const newNativeFile = new File([file.value.nativeFile], newFilename);
      return {
        ...file,
        value: new FileObject({ ...file.value, name: newFilename, nativeFile: newNativeFile }),
      };
    });
    setValue('files', renamedFiles);
    setIsEditingFilenames(false);
  };

  const handleCancelEditNames = () => {
    const oldFilenames = files.map((file) => ({
      ...file,
      editFilename: file.value.name,
    }));
    setValue('files', oldFilenames);
    setIsEditingFilenames(false);
  };

  const mergeValidationErrorAlerts = mergeValidationErrors.map((error) => (
    <Alert key={`merge-alert-${error.type}`} intent="error" icon={<Warning16Regular />}>
      {error.message}
    </Alert>
  ));

  const canMerge = useMemo(
    () => features.MergingFiles && addedFilesProps.filter((file) => isMergableFileType(file.filename)).length > 1,
    [features.MergingFiles, addedFilesProps]
  );

  const canMove = useMemo(() => addedFilesProps.length > 1, [features.MergingFiles, addedFilesProps]);

  return (
    <>
      <div
        className={`files-merge-convert ${canMerge ? '' : 'files-merge-convert__no-merge'} ${
          canMove ? '' : 'files-merge-convert__no-move'
        }`}
      >
        <div className="files-merge-convert__file-dropper">
          <FileDropper {...{ onAddFiles, acceptedFileTypes, onInputClick }} />
          <Text size={200}>
            {t({ id: 'Dashboard.Signing.MaxFileSize', values: { maxFileSize: fileSizeLimitInHuman } })}
          </Text>
          {formFileChecks}
        </div>
        {fileInputs.length > 0 && (
          <>
            <Header as="h2" className="files-merge-convert__header">
              {t({ id: 'FilesMergeConvert.Header.SelectMerge' })}
            </Header>
            <div className="files-merge-convert__merge-header">
              <div className="files-merge-convert__merge-header-select">
                {canMerge && <Checkbox checked={mergeCheckValue} onClick={toggleMergeCheck} />}
              </div>
              <div className="files-merge-convert__merge-header-name">
                <Text>{t({ id: 'FilesMergeConvert.TableHeader.Name' })}</Text>
                {isEditingFilenames ? (
                  <>
                    <Link onClick={handleEditNames}>{<Checkmark20Filled />}</Link>
                    <Link onClick={handleCancelEditNames}>{<Dismiss20Filled />}</Link>
                  </>
                ) : (
                  <Link
                    onClick={() => setIsEditingFilenames(true)}
                    title={t({ id: 'FilesMergeConvert.TableHeader.Edit' })}
                  >
                    <Edit20Regular />
                  </Link>
                )}
              </div>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="files">
                {(provided: any) => (
                  <div
                    className="files-merge-convert__merge-files"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {fileInputs}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {mergeValidationErrorAlerts}
            {isFileLimitErrorVisible && (
              <Alert intent="error" icon={<Warning16Regular />}>
                {t({
                  id: 'Dashboard.Signing.Errors.TooManyFiles',
                  values: { fileCountLimit: FILE_COUNT_LIMIT },
                })}
              </Alert>
            )}
            {canMerge && (
              <div>
                <Button icon={<Merge24Filled />} primary size="small" onClick={handleMergeClick}>
                  {t({ id: 'Dashboard.Signing.Button.MergeFiles' })}
                </Button>
              </div>
            )}
          </>
        )}
        <FileBrowser
          open={isFileBrowserOpen}
          allowedFileSize={fileSizeLimitInBytes}
          allowedFileTypes={[FILE_FORMATS_MAPPED.PDF, FILE_FORMATS_MAPPED.XML]}
          onCancel={() => setIsFileBrowserOpen(false)}
          onFileSelect={addFileFromFileBrowser}
        />
        <MergeConvertStateDialog
          state={mergeConvertState}
          file={pendingMergedFile}
          onCancel={handleCancelMerge}
          onConfirm={handleConfirmMerge}
        />
        <SignaturesRemovalWarningDialog
          open={isMergeSignedDialogVisible}
          onConfirm={() => handleMergeSignedDialogResult(true)}
          onCancel={() => handleMergeSignedDialogResult(false)}
        />
      </div>
    </>
  );
};

interface SignaturesRemovalWarningDialogProps {
  open: boolean;
  onCancel: () => unknown;
  onConfirm: () => unknown;
}

const SignaturesRemovalWarningDialog = ({ open, onCancel, onConfirm }: SignaturesRemovalWarningDialogProps) => (
  <Dialog open={open}>
    <DialogSurface>
      <DialogBody>
        <DialogContent>{t({ id: 'FilesMergeConvert.Content.SignaturesRemovalWarning' })}</DialogContent>
        <DialogActions>
          <Button appearance="secondary" onClick={onCancel}>
            {t({ id: 'Common.Cancel' })}
          </Button>
          <Button appearance="primary" onClick={onConfirm}>
            {t({ id: 'Common.Proceed' })}
          </Button>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
);
