import { cloneElement } from 'react';
import { Props } from './models';
import { Text } from '@fluentui/react-components';

import './Alert.scss';

export const Alert = ({ icon, type = 'default', title, description }: Props) => {
  const Title = () => (
    <div className={`alert-type__${type}`}>
      <Text className="alert-title" weight="semibold" size={600}>{title}</Text>
    </div>
  );

  const Description = () => (
    <div className={`alert-type__${type} alert-spacing`}>
      {typeof description === 'string' ? (
        <Text className="alert-description" weight="regular" size={400}>{description}</Text>
      ) : (
        description
      )}
    </div>
  );

  const Icon = () => {
    const { className } = icon.props;
    return (
      <div className="alert-icon">
        {cloneElement(icon, { size: 'largest', className: `alert-type__${type} ${className ? className : ''}` })}
      </div>
    );
  };

  return (
    <div className="alert-container">
      <Icon />
      {title && <Title />}
      {description && <Description />}
    </div>
  );
};
