import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { cloudOneDrive } from '../constants';
import { FileBrowserFiles } from './FileBrowserFiles';
import { useCloudFileList } from './useFileList';
import { last, take } from './utils';

const initialSelectedFolders = [cloudOneDrive];

export const FileBrowserCloudFiles = ({ allowedFileTypes = null, onFileSelect }) => {
  const [ordering, setOrdering] = useState(1);
  const [selectedFolders, setSelectedFolders] = useState(initialSelectedFolders);
  const { loading, data, error, reload } = useCloudFileList(last(selectedFolders)?.id);

  const onOrderingClick = useCallback(() => setOrdering((value) => -value), []);

  const onFolderSelect = useCallback((folder) => {
    setSelectedFolders((current) => [...current, folder]);
  }, []);

  const onBreadcrumbClick = useCallback((index) => {
    setSelectedFolders((current) => take(current, index + 1));
  }, []);

  return (
    <FileBrowserFiles
      data={data}
      loading={loading}
      error={error}
      selectedFolders={selectedFolders}
      ordering={ordering}
      allowedFileTypes={allowedFileTypes}
      onRetry={reload}
      onOrderingClick={onOrderingClick}
      onFileSelect={onFileSelect}
      onBreadcrumbClick={onBreadcrumbClick}
      onFolderSelect={onFolderSelect}
    />
  );
};

FileBrowserCloudFiles.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  onFileSelect: PropTypes.func.isRequired,
};
