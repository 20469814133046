import { Button, Text } from '@fluentui/react-components';
import { Input } from 'shared-fe-components/src/common/ReactHookForm';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTeamsContext } from 'contexts';
import { FileObject } from '../../../lib/fileObject';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { FilesMergeConvert } from '../FilesMergeConvert/FilesMergeConvert';
import { Header } from 'shared-fe-components/src/common/Header';

export const DashboardFileSigningChooseFiles = (props) => {
  const formMethods = useForm({
    criteriaMode: 'all',
    defaultValues: {
      files: props.files.map((file) => ({ value: file, isSelected: false, editFilename: file.name })),
      processName: props.processName,
    },
  });
  const { handleSubmit } = formMethods;
  const { onCancel, onSignInChooseFiles } = props;
  const { context: teamsContext } = useTeamsContext();
  const signee = teamsContext.user.userPrincipalName;

  const onConfirm = handleSubmit((data) => {
    onSignInChooseFiles(
      data.files.map((file) => new FileObject(file.value)),
      data.processName
    );
  });

  return (
    <FormProvider {...formMethods}>
      <WizardBox className="file-signing" growContent={false}>
        <div>
          <Text as="h1" size={500} weight="bold">
            {t({ id: 'Dashboard.Signing.Headers.SelectDocToBeSigned' })}
          </Text>
        </div>

        <div>
          <div className="file-signing__two-column-view">
            <div>
              <div className="file-signing__choose-files-inner">
                <div>
                  <Text as="h2" size={300} weight="semibold">
                    {t({ id: 'Dashboard.Signing.Headers.Recipient' })}
                  </Text>
                  <div className="file-signing__recipient">
                    <Text size={300} weight="regular">
                      {signee}
                    </Text>
                  </div>
                </div>
                <div className="file-signing__input">
                  <Input
                    appearance="filled-darker"
                    maxLength="50"
                    fluid
                    placeholder={t({ id: 'Dashboard.Signing.Placeholders.ProcessName' })}
                    fieldName="processName"
                  />
                </div>
                <div className="file-signing__column-with-gaps">
                  <Header as="h2">{t({ id: 'Dashboard.Signing.Headers.Files' })}</Header>
                  <div className="file-signing__choose-files">
                    <FilesMergeConvert />
                  </div>

                  {/* TODO: uncomment, and remove the other FileBrowser below, when conversion will be done on FE 
                    <FileBrowser
                      open={isFileBrowserOpen}
                      allowedFileSize={fileSizeLimitInBytes}
                      allowedFileTypes={signing.supportedFileTypes.map(x => x.mimeType)}
                      onCancel={() => setIsFileBrowserOpen(false)}
                      onFileSelect={addFileFromFileBrowser}
                    /> */}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
        <div className="file-signing__step-buttons">
          <div></div>
          <div>
            <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
            <Button appearance="primary" onClick={onConfirm}>
              {t({ id: 'Common.Sign' })}
            </Button>
          </div>
        </div>
      </WizardBox>
    </FormProvider>
  );
};

DashboardFileSigningChooseFiles.propTypes = {
  files: PropTypes.arrayOf(PropTypes.instanceOf(FileObject)).isRequired,
  processName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSignInChooseFiles: PropTypes.func.isRequired,
};
