import { Button, Text, Link } from '@fluentui/react-components';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import { CheckIcon } from 'shared-fe-components/src/common/Icons';
import PropTypes from 'prop-types';

export const DashboardFileSigningSuccess = ({ documentName, onClose, signedFileUrl }) => {
  return (
    <div className="file-signing-success-screen">
      <div className="file-signing-success-screen__header">
        <CheckIcon />
        <h1>{t({ id: 'Dashboard.Signing.Headers.DocumentsSigned' })}</h1>
      </div>

      <Text as="h2" weight="bold" className="file-signing-success-screen__text" block>
        {t({ id: 'Dashboard.Signing.DocumentsSigned', values: { documentName } })}
      </Text>
      {signedFileUrl && (
        <div>
          <Trans
            id="DashboardSignSuccess.OneDrive.Link"
            components={[<Link href={signedFileUrl} target="_blank" inline />]}
          />
        </div>
      )}
      <div>
        <Button onClick={onClose}>{t({ id: 'Dashboard.Signing.Button.BackToDashboard' })}</Button>
      </div>
    </div>
  );
};

DashboardFileSigningSuccess.propTypes = {
  documentName: PropTypes.string.isRequired,
  signedFileUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
