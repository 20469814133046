import { useState, useEffect } from 'react';
import { MszafirIntro } from 'shared-fe-components/src/providers/MSzafir/MszafirIntro';
import { MszafirSign } from './MszafirSign';
import { useLocalizationContext, useUserContext } from 'contexts';
import { useSignTaskContext } from '../SignTaskContext';

export const MszafirFlow = () => {
  const [step, setStep] = useState('intro');
  const [additionalParameters, setAdditionalParameters] = useState();
  const { features } = useUserContext();
  const { file, onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useSignTaskContext();

  const { locale } = useLocalizationContext();

  const goToIntro = () => setStep('intro');

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  const onSelectCertificate = (certType) => {
    setAdditionalParameters({
      mszafirParameters: {
        signingMode: certType,
      },
      culture: locale,
    });
    setStep('sign');
  };

  return (
    <>
      {step === 'intro' && (
        <MszafirIntro
          features={features}
          fileName={file?.name}
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onSelectCertificate={onSelectCertificate}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'sign' && (
        <MszafirSign additionalParameters={additionalParameters} onBack={goToIntro} onCancel={onCancel} />
      )}
    </>
  );
};
