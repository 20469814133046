import { Verification } from './Verification/Verification';
import { Signing } from './Signing/Signing';
import { useUserContext } from 'contexts';
import { SidePanelContextProvider } from './SidePanelContext';
import { OrganizerGuard } from '../Utils';
import { FreemiumBanner } from '../../License/FreemiumBanner';
import './SidePanel.scss';

export const SidePanel = () => {
  const { features, freemiumStatus  }: any = useUserContext();

  return (
    <SidePanelContextProvider>
      <div className="side-panel background-side-panel">
        <OrganizerGuard>
          {freemiumStatus?.isActive && <FreemiumBanner onMeeting={true} />}
        </OrganizerGuard>
        <Verification />
        {features.SigningOnMeetings && <Signing />}
      </div>
    </SidePanelContextProvider>
  );
};
