import { useMemo } from 'react';
import { Props } from './models';
import './GenericTable.scss';

export const GenericTable = ({ rows, columns, rowGap, colGap, visibleColumnHeader }: Props) => {
  // *Display only columns where data is
   const filteredColumns = useMemo(() => {
     const keys: string[] = rows.reduce((acc: string[], curr: { [key: string]: React.ReactNode }) => {
       return [...Object.keys(curr), ...acc];
     }, []);
     return Object.fromEntries(Object.entries(columns).filter(([colKey]) => keys.includes(colKey)))
   }, [rows]);


  return (
    <table className="generic-table__table">
      <tbody>
      <tr>
        {visibleColumnHeader && Object.values(filteredColumns).map((column, colIndex) => <th key={'column_header_' + colIndex}>{column}</th>)}
      </tr>
        {rows.map((row, rowIndex) => {
          return (
            <tr key={'row_' + rowIndex}>
              {Object.keys(filteredColumns).map((column, colIndex) => {
                return (
                  <td
                    key={'column_' + colIndex}
                    style={{
                      ...(rowGap && rowIndex !== rows.length - 1 ? { paddingBottom: `${rowGap}px` } : {}),
                      ...(colGap && colIndex !== Object.keys(filteredColumns).length - 1 ? { paddingRight: `${colGap}px` } : {}),
                    }}
                  >
                    {row[column] ?? ''}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
