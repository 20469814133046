import { useContext, createContext, useCallback, useReducer, useMemo } from 'react';
import { ErrorScreen, TimeoutScreen } from 'shared-fe-components/src/common/StatusScreens';
import { State, Action, SignTaskContextType, ErrorProps } from './SignTaskContext.types';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import * as api from 'shared-fe-components/src/api';
import './SignTaskContext.scss';

const signTaskInitialState: State = {
  overlay: null,
  selectedProvider: null,
  errorProps: null,
  showPrivacyPolicy: false,
};

const signTaskReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'PROVIDER_SELECTION':
      return {
        ...signTaskInitialState,
      };
    case 'PROVIDER':
      return {
        ...state,
        selectedProvider: action.selectedProvider,
      };
    case 'ERROR':
      return {
        ...state,
        overlay: 'error',
        errorProps: action.errorProps,
      };
    case 'TIMEOUT':
      return {
        ...state,
        overlay: 'timeout',
      };
    case 'HIDE_OVERLAY':
      return {
        ...state,
        overlay: null,
        errorProps: null,
      };
    case 'SIGNATURE_LOCKED':
      return {
        ...state,
        selectedProvider: '_dummy:SignatureLocked',
      };
    case 'SHOW_PRIVACY_POLICY':
      return {
        ...state,
        showPrivacyPolicy: true,
      };
    case 'HIDE_PRIVACY_POLICY':
      return {
        ...state,
        showPrivacyPolicy: false,
      };
  }
};

const SignTaskContext = createContext<SignTaskContextType | null>(null);

export const SignTaskContextProvider = ({
  file,
  signParameters,
  selfSignParameters,
  goToSuccess,
  onBack,
  onCancel,
  initialProvider,
  xadesFormat,
  children,
}: any) => {
  const [signTaskState, signTaskDispatch] = useReducer(signTaskReducer, {
    ...signTaskInitialState,
    selectedProvider: initialProvider,
  });

  const [privacyPolicyAcceptance, setPrivacyPolicyAcceptance] = usePersistentStorage('privacy-policy-acceptance');
  const isPrivacyPolicyVisible = useMemo(() => {
    if (!privacyPolicyAcceptance && signTaskState.selectedProvider !== null) {
      // user hasn't accepted privacy policy and has to do it to proceed
      return true;
    }
    if (signTaskState.showPrivacyPolicy) {
      // user wants to read it for some reason
      return true;
    }

    return false;
  }, [privacyPolicyAcceptance, signTaskState.selectedProvider, signTaskState.showPrivacyPolicy]);

  const acceptPrivacyPolicy = useCallback(() => {
    setPrivacyPolicyAcceptance(true);
    signTaskDispatch({ type: 'HIDE_PRIVACY_POLICY' });
  }, [setPrivacyPolicyAcceptance]);

  const closePrivacyPolicy = useCallback(() => {
    if (!privacyPolicyAcceptance) {
      onCancel();
    } else {
      signTaskDispatch({ type: 'HIDE_PRIVACY_POLICY' });
    }
  }, [privacyPolicyAcceptance, onCancel]);

  const showPrivacyPolicy = () => signTaskDispatch({ type: 'SHOW_PRIVACY_POLICY' });

  const overlay = useMemo(() => signTaskState.overlay, [signTaskState.overlay]);
  const selectedProvider = useMemo(() => signTaskState.selectedProvider, [signTaskState.selectedProvider]);
  const setSelectedProvider = (selectedProvider: string) => signTaskDispatch({ type: 'PROVIDER', selectedProvider });
  const goToProviderSelection = () => signTaskDispatch({ type: 'PROVIDER_SELECTION' });
  const goToSignatureLockedScreen = () => signTaskDispatch({ type: 'SIGNATURE_LOCKED' });
  const onError = (errorProps: ErrorProps) => signTaskDispatch({ type: 'ERROR', errorProps });
  const onTimeout = () => signTaskDispatch({ type: 'TIMEOUT' });
  const hideOverlay = () => signTaskDispatch({ type: 'HIDE_OVERLAY' });

  const sign = useCallback(
    async ({ additionalParameters = {} }) => {
      try {
        const response = await api.signing.signOrSelfSign({
          ...(signParameters.current === null ? selfSignParameters.current : signParameters.current),
          signatureProviderType: selectedProvider!.split(':')[0],
          additionalParameters,
        });
        if (signParameters.current === null && response?.sessionId && response?.signatureId) {
          signParameters.current = {
            selfSign: false,
            sessionId: response?.sessionId,
            signatureId: response?.signatureId,
            documentIds: [file.documentId],
          };
        }
        return response;
      } catch (error: any) {
        try {
          const errorResponse = await error.response.json();
          const respError = errorResponse.error;
          const respErrorCode = errorResponse.errorCode;
          if (respErrorCode === 'SignatureLocked') {
            goToSignatureLockedScreen();
          } else if (respErrorCode) {
            onError({ errorCode: respErrorCode });
          } else if (respError) {
            onError({ message: respError });
          } else {
            onError({});
          }
        } catch (errorParsingError) {
          onError({});
        }
      }
    },
    [goToSignatureLockedScreen, selectedProvider, selfSignParameters, signParameters]
  );

  const getSignatureStatus = useCallback(
    async ({ onProviderPending }) => {
      if (!signParameters.current?.sessionId || !signParameters.current?.signatureId) return;
      let data, error;
      try {
        data = await api.signing.getSignatureStatus(
          signParameters.current.sessionId,
          signParameters.current.signatureId
        );
      } catch (err) {
        error = err;
      }

      const desc = api.signing.describeSignatureFromGetSignatureStatus({ data, error });

      if (desc.success) {
        goToSuccess(data?.document?.signedFileDownloadInfo?.fileUrl ?? data?.document?.downloadUrl);
      } else if (desc.providerPending) {
        onProviderPending?.();
      } else if (desc.signatureLocked) {
        goToSignatureLockedScreen();
      } else if (desc.timeout) {
        onTimeout();
      } else if (desc.errorCode !== null) {
        onError({ errorCode: desc.errorCode });
      } else if (desc.failed) {
        onError({});
      }

      return { data, error };
    },
    [goToSignatureLockedScreen, goToSuccess, signParameters]
  );

  const providerValues = {
    file,
    selectedProvider,
    xadesFormat,
    overlay,
    onBack,
    onCancel,
    setSelectedProvider,
    goToProviderSelection,
    sign,
    getSignatureStatus,
    onError,
    onTimeout,
    showPrivacyPolicy,
  };

  return (
    <SignTaskContext.Provider value={providerValues}>
      <div className="layered-container">
        {children}
        {signTaskState.overlay !== null && <div className="background-task"></div>}
        {signTaskState.overlay === 'error' && (
          <ErrorScreen {...signTaskState.errorProps} onCancel={onCancel} onRetry={hideOverlay} />
        )}
        {signTaskState.overlay === 'timeout' && <TimeoutScreen onCancel={onCancel} onRetry={hideOverlay} />}
        {isPrivacyPolicyVisible && (
          <>
            <div className="background-task"></div>
            <PrivacyPolicy
              onAccept={acceptPrivacyPolicy}
              onClose={closePrivacyPolicy}
              required={!privacyPolicyAcceptance}
            />
          </>
        )}
      </div>
    </SignTaskContext.Provider>
  );
};

export const useSignTaskContext = () => {
  const ctx = useContext(SignTaskContext);
  if (!ctx) {
    throw new Error('Lack of SignTaskContextProvider in components tree');
  }
  return ctx;
};
