import { createContext, useContext, useState, useEffect } from 'react';
import { signing } from 'shared-fe-components/src/api';
import { SessionStatisticsProviderProps, SessionStatisticsObject } from './models';

const Context = createContext<SessionStatisticsObject | null>(null);

export const SessionStatisticsProvider = ({ children }: SessionStatisticsProviderProps) => {
  const [statistics, setStatistics] = useState<{
    pendingCount: string;
    unsignedCount: string;
  }>({
    pendingCount: '',
    unsignedCount: '',
  });

  useEffect(() => {
    fetchSessionStatistics();
  }, []);

  const fetchSessionStatistics = async () => {
    const data = await signing.getSessionsStatistics();
    const { waitingDocuments, notSignedDocuments } = data;

    setStatistics((state) => ({
      pendingCount: waitingDocuments ?? state.pendingCount,
      unsignedCount: notSignedDocuments ?? state.unsignedCount,
    }));
  };

  return (
    <Context.Provider
      value={{
        ...statistics,
        fetchSessionStatistics,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSessionStatisticsContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Lack of SessionStatisticsProvider in components tree');
  }

  return ctx;
};
