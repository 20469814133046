import { Intro } from './Intro';
import { Dashboard } from './Dashboard';
import { useSidePanelContext } from '../SidePanelContext';

export const Verification = () => {
  const { sidePanelState } = useSidePanelContext();

  if (sidePanelState.verificationInProgress) {
    return <Dashboard />;
  }
  return <Intro />;
};
