import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  Input,
  Spinner,
  Text,
} from '@fluentui/react-components';
import { Warning24Regular, DocumentErrorRegular } from '@fluentui/react-icons';
import { Alert } from '@fluentui/react-components/unstable';
import { Header } from 'shared-fe-components/src/common/Header';
import { MergeConvertState, MergeConvertStateDialogProps } from './models';
import { t } from '@lingui/macro';
import { FilesSuccessIcon } from 'shared-fe-components/src/common/Icons';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { extractFileName } from 'shared-fe-components/src/utils';
import './MergeConvertStateDialog.scss';

const applyFilenameConstraints = (filename: string) => {
  return filename.substring(0, 255);
};

const MergingState = () => {
  return (
    <div className="merge-convert-state-dialog__merging">
      <Header className="merge-convert-state-dialog__header" as="h2">
        {t({ id: 'FilesMergeConvert.Header.MergingFiles' })}
      </Header>
      <Spinner label={t({ id: 'FilesMergeConvert.Content.MergingInProgress' })} labelPosition="above" />
    </div>
  );
};

const MergeSuccessState = ({ blobUrl, file, control }) => {
  return (
    <div className="merge-convert-state-dialog__merge-success">
      <Header className="merge-convert-state-dialog__header" as="h2">
        {t({ id: 'FilesMergeConvert.Header.MergingSuccessful' })}
      </Header>
      <FilesSuccessIcon />
      <Anchor className="merge-convert-state-dialog__preview" href={blobUrl} download={file.name}>
        {t({ id: 'FilesMergeConvert.Button.Preview' })}
      </Anchor>
      <div className="merge-convert-state-dialog__outcome-name">
        <Text size={200} className="merge-convert-state-dialog__outcome-label">
          {t({ id: 'FilesMergeConvert.Input.OutcomeName' })}
        </Text>
        <Controller
          control={control}
          name="filename"
          rules={{ required: { value: true, message: t({ id: 'Form.Errors.RequiredField' }) } }}
          render={({ field, fieldState }) => (
            <>
              <Input {...field} value={applyFilenameConstraints(field.value)} />
              {fieldState.error && (
                <Alert intent="error" icon={<Warning24Regular />}>
                  {fieldState.error.message}
                </Alert>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};

const MergeErrorState = () => {
  return (
    <div className="merge-convert-state-dialog__merge-error">
      <Header className="merge-convert-state-dialog__header" as="h2">
        {t({ id: 'FilesMergeConvert.Header.MergingFailed' })}
      </Header>
      <DocumentErrorRegular className="merge-convert-state-dialog__merge-error-icon" />
      <div>
        <Text>{t({ id: 'FilesMergeConvert.Content.MergingGenericError' })}</Text>
      </div>
    </div>
  );
};

export const MergeConvertStateDialog = ({ state, onCancel, onConfirm, file }: MergeConvertStateDialogProps) => {
  const form = useForm({ defaultValues: { filename: '' }, mode: 'onChange' });
  const [blobUrl, setBlobUrl] = useState(null);
  const {
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = form;

  useEffect(() => {
    if (file) {
      const blob = URL.createObjectURL(file);
      setBlobUrl(blob);
      return () => {
        URL.revokeObjectURL(blob);
      };
    }
  }, [file]);

  useEffect(() => {
    if (file) {
      setValue('filename', extractFileName(file.name), { shouldValidate: true });
    }
  }, [file]);

  const handleConfirmClick = async () => {
    if (isValid) {
      onConfirm({ mergedFilename: getValues('filename') + '.pdf' });
    }
  };

  const dialogContent = (
    <div className="merge-convert-state-dialog__content">
      {state === MergeConvertState.Merging && <MergingState />}
      {state === MergeConvertState.MergeSuccess && (
        <MergeSuccessState blobUrl={blobUrl} file={file} control={control} />
      )}
      {state === MergeConvertState.MergeError && <MergeErrorState />}
    </div>
  );

  return (
    <Dialog open={state !== MergeConvertState.Idle}>
      <DialogSurface>
        <DialogBody>
          <DialogContent>{dialogContent}</DialogContent>
          <DialogActions>
            {(state === MergeConvertState.MergeSuccess || state === MergeConvertState.MergeError) && (
              <Button appearance="secondary" onClick={onCancel}>
                {t({ id: 'Common.Cancel' })}
              </Button>
            )}
            {state === MergeConvertState.MergeSuccess && (
              <Button appearance="primary" onClick={handleConfirmClick}>
                {t({ id: 'Common.Proceed' })}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
