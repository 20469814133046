import { Button, Text } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { t } from '@lingui/macro';
import { Send48Regular } from '@fluentui/react-icons';

import PropTypes from 'prop-types';

export const DashboardSendForSigningSuccess = ({ filenames, onClose }) => {
  const filenamesJoined = filenames.join(', ');

  return (
    <div className="send-for-signing-success-screen">
      <div className="send-for-signing-success-screen__header">
        <Send48Regular style={{ zoom: 2 }} />
        <h1>{t({ id: 'Dashboard.Signing.Headers.DocumentSent' })}</h1>
      </div>

      <Text as="h2" weight="bold">
        {t({ id: 'Dashboard.Signing.DocumentsSent', values: { documents: filenamesJoined } })}
      </Text>

      <Text>{t({ id: 'Dashboard.Signing.EmailNotificationWhenAllSigned' })}</Text>

      <Button onClick={onClose}>{t({ id: 'Dashboard.Signing.Button.BackToDashboard' })}</Button>
    </div>
  );
};

DashboardSendForSigningSuccess.propTypes = {
  filenames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};
