export interface Details {
  archived: boolean;
  status: string;
  signatures: {}[];
  endTime: Date | null;
  expirationTime: string;
  result: string | null;
  signatureType: string;
  meetingId: string | null;
}

export interface Props {
  sessionId: string;
  name: string;
  canCancel: boolean;
  startDate: Date;
  fileName: string;
  setMadeChanges: (state: boolean) => void;
  onEditSignatureClick: (sessionId: string) => void;
  closeDetails: (withReload?: boolean) => void;
}

export const dateFormatterOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
} as const;

export const statuses: Record<string, string> = {
  InProgress: 'waiting',
  NotStarted: 'waiting',
  Queued: 'waiting',
  ProviderPending: 'waiting',
  Rejected: 'rejected',
  Succeeded: 'signed',
  Cancelled: 'cancelled',
  Failed: 'rejected',
} as const;

export const statusClasses: Record<string, string> = {
  InProgress: 'waiting',
  NotStarted: 'waiting',
  Queued: 'waiting',
  ProviderPending: 'waiting',
  Rejected: 'rejected',
  Succeeded: 'signed',
  Cancelled: 'cancelled',
  Failed: 'rejected',
} as const;
