import { Button } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { VerificationErrorIcon } from 'shared-fe-components/src/common/Icons';
import PropTypes from 'prop-types';

export const DashboardSendForSigningError = ({ errorDescription, onClose }) => {
  return (
    <div className="send-for-signing-error-screen">
      <div className="send-for-signing-error-screen__error-container">
        <VerificationErrorIcon />
        <h2>{t({ id: 'Dashboard.Signing.Headers.ErrorOccured' })}</h2>
        <p>{errorDescription}</p>
        <Button onClick={onClose}>{t({ id: 'Dashboard.Signing.Button.BackToDashboard' })}</Button>
      </div>
    </div>
  );
};

DashboardSendForSigningError.propTypes = {
  errorDescription: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
