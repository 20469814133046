import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Text } from '@fluentui/react-components';
import * as microsoftTeams from '@microsoft/teams-js';
import { t } from '@lingui/macro';
import { TaskContainer } from '../Common/TaskContainer';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { VerifyTaskContextProvider } from './VerifyTaskContext';
import { CancelSigningProcessIcon } from 'shared-fe-components/src/common/Icons';
import { SuccessScreen } from 'shared-fe-components/src/common/StatusScreens';
import { ProcessType } from 'shared-fe-components/src/common/StatusScreens';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { ArrowForward24Filled, DismissCircle48Filled } from '@fluentui/react-icons';
import { useConfigurationContext } from '../../contexts';
import './VerifyTask.scss';

const CancelConfirmation = ({ hideCancelConfirmation, submitTaskCancel }) => {
  return (
    <WizardBox className="verify-task-cancel-confirmation-screen background-task">
      <div />
      <div className="prompt-content-wrapper">
        <div className="prompt-content">
          <CancelSigningProcessIcon color="var(--colorScheme-colorNeutralForeground1)" />
          <div className="prompt-content-message">
            <Text as="h1" weight="bold">
              {t({ id: 'CancelConfirmation.Verify.Title' })}
            </Text>
            <Text size={400}>{t({ id: 'CancelConfirmation.WontBeSaved' })}</Text>
          </div>
        </div>
      </div>
      <div>
        <Button onClick={hideCancelConfirmation}>{t({ id: 'Common.No' })}</Button>
        <Button appearance="primary" onClick={submitTaskCancel}>
          {t({ id: 'CancelConfirmation.Verify.CancelButton' })}
        </Button>
      </div>
    </WizardBox>
  );
};

export const VerifyTask = () => {
  const { sessionId, verificationId } = useParams();
  const [cancelConfirmationVisible, setCancelConfirmationVisible] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('redirect');

  const submitTaskCancel = () => {
    microsoftTeams.dialog.url.submit({ submitReason: 'cancel' });
  };

  const hideCancelConfirmation = () => {
    setCancelConfirmationVisible(false);
  };

  const goToSuccess = () => {
    setCurrentScreen('success');
  };

  const goToRejected = () => {
    setCurrentScreen('rejected');
  };

  const contextProps = useMemo(
    () => ({
      sessionId,
      verificationId,
      goToRejected: goToRejected,
      goToSuccess: goToSuccess,
    }),
    [sessionId, verificationId]
  );

  const VerifyRedirect = () => {
    const { webAppUrl } = useConfigurationContext();
    const [token, setToken] = useState('');

    useEffect(() => {
      (async function () {
        try {
          const fetchedToken = await window.getToken();
          setToken(fetchedToken);
        } catch (error) {
          console.error('Error fetching token:', error);
        }
      })();
    }, []);

    const handleCloseModal = () => {
      microsoftTeams.dialog.url.submit();
    };

    return (
      <VerifyTaskContextProvider {...contextProps}>
        <div className="redirect-wrapper">
          <ArrowForward24Filled style={{ zoom: 2 }} />
          <div className="redirect-wrapper__content">
            <Text as="h1" weight="bold">
              {t({ id: 'Verification.Redirect.Header' })}
            </Text>
            <Text>{t({ id: 'Verification.Redirect.Description' })}</Text>
          </div>
          {token && (
            <Anchor href={`${webAppUrl}/verify/${sessionId}/${verificationId}?token=${token}`} target="_blank">
              <Button onClick={handleCloseModal} appearance="primary">{t({ id: 'Verification.Redirect.Button' })}</Button>
            </Anchor>
          )}
        </div>
      </VerifyTaskContextProvider>
    );
  };

  return (
    <TaskContainer className="verify-task-container">
      {currentScreen === 'redirect' && <VerifyRedirect />}
      {currentScreen === 'success' && <SuccessScreen processType={ProcessType.Verify} />}
      {currentScreen === 'rejected' && (
        <div className="reject-verification-completed">
          <DismissCircle48Filled style={{ zoom: 1.5 }} />
          <Text as="h1" weight="bold">{t({ id: 'Webportal.Verification.Deny.Complete.Header' })}</Text>
          <Text>{t({ id: 'Webportal.Verification.Deny.Complete.Description' })}</Text>
        </div>
      )}
      {cancelConfirmationVisible && <CancelConfirmation {...{ hideCancelConfirmation, submitTaskCancel }} />}
    </TaskContainer>
  );
};
