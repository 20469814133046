import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';
import { InputWithError } from 'shared-fe-components/src/common/WithError';
import './EmailInput.scss';

export const EmailInput = ({ config = {}, fieldName, errorMessage, isDisabled }) => {
  const formContext = useFormContext();
  const { register, getValues } = formContext;

  const inputProps = useMemo(() => {
    const requiredValidation = config.validation?.isRequired
      ? {
          required: {
            value: true,
            message: t({ id: 'Form.Errors.RequiredField' }),
          },
        }
      : {};

    return {
      type: 'email',
      fluid: true,
      placeholder: t({ id: 'Form.EmailsInputs.Email.Placeholder' }),
      ...register(fieldName, {
        ...requiredValidation,
        pattern: {
          value: /\S+@\S+/,
          message: t({ id: 'Form.Errors.IncorrectEmail' }),
        },
      }),
      ...config,
    };
  }, [register, fieldName, config]);

  return (
    <div className="email-input-container">
      <InputWithError
        {...inputProps}
        appearance="filled-darker"
        autoComplete="off"
        aria-autocomplete="both"
        aria-haspopup="false"
        disabled={isDisabled}
        defaultValue={getValues(fieldName)}
        errorMessage={errorMessage}
      />
    </div>
  );
};
