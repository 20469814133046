import { InperlyLogo, SuccessfullySignedDocument } from 'shared-fe-components/src/common/Icons';
import { useStageContext } from '../StageContext';
import { Text } from 'shared-fe-components/src/common/FluentMigration';
import { t } from '@lingui/macro';
import './Signing.scss';

export const Signing = () => {
  const {
    stageState: { finished },
  } = useStageContext();

  return (
    <div className="meeting-stage-signing">
      <div className="meeting-stage-signing__header">
        <InperlyLogo />
        <Text as="h1" className="meeting-stage-signing__header-middle">
          {t({ id: 'Meeting.Stage.Signing.Title' })}
        </Text>
      </div>
      <div className="meeting-stage-signing__content">
        {!finished && (
          <Text size={500} weight="bold">
            {t({ id: 'Meeting.Stage.Signing.InProgress' })}
          </Text>
        )}
        {finished && (
          <>
            <Text success>
              <SuccessfullySignedDocument />
            </Text>
            <Text size={500} success>
              {t({ id: 'Meeting.Stage.Signing.Success' })}
            </Text>
          </>
        )}
      </div>
      <div>{/* empty element so space-between works nicely */}</div>
    </div>
  );
};
