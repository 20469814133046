import { Text as FluentText } from '@fluentui/react-components';
import './Text.scss';

type FluentTextType = typeof FluentText;
interface Props extends FluentTextType {
  success?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const Text = ({ success, error, disabled, children, ...rest }: Props) => {
  const props = {
    ...rest,
    className: [
      'migrtext',
      ...(success ? ['migrtext--success'] : []),
      ...(error ? ['migrtext--error'] : []),
      ...(disabled ? ['migrtext--disabled'] : []),
      ...(rest?.className ? [rest?.className] : []),
    ].join(' '),
  };

  return <FluentText {...props}>{children}</FluentText>;
};
