import { Text } from '@fluentui/react-components';
import { Button } from 'shared-fe-components/src/common/FluentMigration';
import { t } from '@lingui/macro';
import { Dashboard } from './Dashboard';
import { NotOrganizerGuard, OrganizerGuard } from '../../Utils';
import { useSidePanelContext } from '../SidePanelContext';
import * as microsoftTeams from '@microsoft/teams-js';

export const Signing = () => {
  const { sidePanelState, sidePanelDispatch, shareToStage } = useSidePanelContext();

  const startSigningTask = () => {
    sidePanelDispatch({ type: 'SIGNING_BUSY' });
    const taskInfo = {
      url: `${window.location.origin}/tasks/meeting/sign`,
      title: t({ id: 'Common.Catchphrase' }),
      size: {
        height: 530,
        width: 600,
      },
    };

    const submitHandler: any = ({ err, result }) => {
      if (result?.submitReason === 'success') {
        sidePanelDispatch({ type: 'SIGNING_IN_PROGRESS' });
        shareToStage();
      } else if (result?.submitReason === 'cancel' || err?.includes('closed the task')) {
        sidePanelDispatch({ type: 'IDLE' });
      }
    };

    microsoftTeams.dialog.url.open(taskInfo, submitHandler);
  };

  return (
    <>
      {!sidePanelState.signingInProgress && (
        <>
          <NotOrganizerGuard>
            <Text className="side-panel__section-title">
              {t({ id: 'Meeting.SidePanel.Signing.NotOrganizerWarning' })}
            </Text>
          </NotOrganizerGuard>
          <OrganizerGuard>
            <div className="side-panel__intro">
              <Text className="side-panel__section-title" weight="bold">
                {t({ id: 'Meeting.SidePanel.Signing.Header' })}
              </Text>
              <Text>{t({ id: 'Meeting.SidePanel.Signing.Guide' })}</Text>
              <Button primary onClick={startSigningTask} {...sidePanelState.startSigningButtonProps}>
                {t({ id: 'Meeting.SidePanel.Signing.Start' })}
              </Button>
            </div>
          </OrganizerGuard>
        </>
      )}
      {sidePanelState.signingInProgress && <Dashboard />}
    </>
  );
};
