import { useEffect, useState } from 'react';
import { Checkbox, Text, Button, Divider } from '@fluentui/react-components';
import { DatePicker } from 'shared-fe-components/src/common/DatePicker';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { useDateFormatter } from '../../../../lib/useDateFormatter';
import { dayFormatterOptions } from '../dashboardTableData';
import './DashboardTableFilters.scss';

const initialCheckboxOptions = [
  { id: 1, title: 'Dashboard.Table.Filter.Signed', value: 'Signed', checked: false },
  { id: 2, title: 'Dashboard.Table.Filter.WaitingForOther', value: 'WaitingForOther', checked: false },
  { id: 3, title: 'Dashboard.Table.Filter.WaitingForUser', value: 'WaitingForUser', checked: false },
  { id: 4, title: 'Dashboard.Table.Filter.Expired', value: 'Expired', checked: false },
  { id: 5, title: 'Dashboard.Table.Filter.Rejected', value: 'Rejected', checked: false },
  { id: 6, title: 'Dashboard.Table.Filter.Cancelled', value: 'Cancelled', checked: false },
];

const dateRangesKeys = {
  flowBeginFrom: 'flowBeginFrom',
  flowBeginTo: 'flowBeginTo',
  flowEndFrom: 'flowEndFrom',
  flowEndTo: 'flowEndTo',
  expirationFrom: 'expirationFrom',
  expirationTo: 'expirationTo',
};

const initialDateRanges = {
  [dateRangesKeys.flowBeginFrom]: null,
  [dateRangesKeys.flowBeginTo]: null,
  [dateRangesKeys.flowEndFrom]: null,
  [dateRangesKeys.flowEndTo]: null,
  [dateRangesKeys.expirationFrom]: null,
  [dateRangesKeys.expirationTo]: null,
};

const initialSelectedItems = new Set();

export const DashboardTableFilters = ({
  activeDateRange,
  activeFilters,
  isOpen,
  onClose,
  onFiltersChange,
  onDateRangesChange,
  activeFilterCount,
}) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const [checkboxOptions, setCheckboxOptions] = useState(() => initialCheckboxOptions);
  const [dateRanges, setDateRanges] = useState(initialDateRanges);
  const [areFiltersActive, setAreFiltersActive] = useState(false);
  const dayFormatter = useDateFormatter(dayFormatterOptions);

  const { flowBeginFrom, flowBeginTo, flowEndFrom, flowEndTo, expirationFrom, expirationTo } = dateRangesKeys;

  useEffect(() => {
    activeFilterCount(0);
  }, []);

  useEffect(() => {
    isOpen && syncFiltersStateWithActiveFilters();
  }, [isOpen]);

  //This function runs only once, after the filters opened.
  //The state in this component must be synced with the DashboardTable component state
  //to display the correct state of the active filters
  const syncFiltersStateWithActiveFilters = () => {
    //if checkboxes are checked, the local state must be updated
    if (activeFilters.length) {
      setSelectedItems(new Set(activeFilters));
      setCheckboxOptions((state) =>
        state.map((option) => ({
          ...option,
          checked: activeFilters.includes(option.value),
        }))
      );
    }

    //if date ranges are selected, the local state must be updated
    activeDateRange && setDateRanges(activeDateRange);
  };

  const handleCheckboxSelect = (item) => {
    if (item.checked) {
      setSelectedItems((state) => new Set(state).add(item.value));

      setCheckboxOptions((state) =>
        state.map((option) => ({
          ...option,
          checked: option.id === item.id ? true : option.checked,
        }))
      );
      return;
    }

    setSelectedItems((state) => {
      const updatedSelected = new Set(state);
      updatedSelected.delete(item.value);
      return updatedSelected;
    });

    setCheckboxOptions((state) =>
      state.map((option) => ({
        ...option,
        checked: option.id === item.id ? false : option.checked,
      }))
    );
  };

  const handleDateSelect = (item, fieldName) => {
    if (item) {
      /**
       * getTimezoneOffset() returns the number of minutes, so we need to
       * convert it to milliseconds by multiplying by 60000.
       */
      const date = new Date(item.getTime() - item.getTimezoneOffset() * 60000).toISOString();
      setDateRanges((ranges) => ({
        ...ranges,
        [fieldName]: date,
      }));
    }
  };

  const handleApplyFilters = () => {
    let activeDataRangesCount = 0;
    onFiltersChange([...selectedItems]);
    onDateRangesChange(dateRanges);
    // Checks and count how many filters are being active
    for (const key of Object.keys(dateRanges)) {
      if (dateRanges[key]) activeDataRangesCount = activeDataRangesCount + 1;
    }
    activeFilterCount([...selectedItems].length + activeDataRangesCount);
    setAreFiltersActive(true);
    onClose();
  };

  const handleClearFilters = () => {
    setSelectedItems(initialSelectedItems);
    setDateRanges(initialDateRanges);
    // This method clears the checked values on the CheckboxList
    const clearedCheckboxes = checkboxOptions.map((el) => {
      el.checked = false;
      return el;
    });
    setCheckboxOptions(clearedCheckboxes);
    setAreFiltersActive(false);
    // After Clearing All, filters are restored back to default values and table loads new data
    onFiltersChange([...initialSelectedItems]);
    onDateRangesChange(initialDateRanges);
    activeFilterCount(0);
    onClose();
  };

  const handleCloseFilters = () => {
    // Checking if the filters are being used, if so closing does not clear checkboxes and data ranges
    !areFiltersActive && handleClearFilters();
    onClose();
  };

  const getDateRangeActionTitle = (from, to) => {
    const handleClearDateRange = () => {
      setDateRanges((ranges) => ({
        ...ranges,
        [from]: null,
        [to]: null,
      }));
    };

    return (
      <>
        <div className="dashboard-filters-content__datepicker-action-title">
          <Text as="h3" weight="medium">
            {t({ id: 'Dashboard.Table.Filter.Date.Range' })}
          </Text>
          <Button
            disabled={!dateRanges[from] && !dateRanges[to]}
            appearance="transparent"
            onClick={handleClearDateRange}
          >
            {t({ id: 'Dashboard.Table.Filter.Clear' })}
          </Button>
        </div>
        <Divider />
      </>
    );
  };

  const filterEndDate = (
    <div className="dashboard-filters-content__field-wrapper">
      <div className="dashboard-filters-content__field-wrapper-header">
        <Text as="h2" weight="semibold">
          {t({ id: 'Dashboard.Table.Filter.Process.End.Date' })}
        </Text>
        <Text>{t({ id: 'Dashboard.Table.Filter.Time.Select' })}</Text>
      </div>
      <div className="dashboard-filters-content__datepicker-wrapper">
        {getDateRangeActionTitle(flowEndFrom, flowEndTo)}
        <div className="dashboard-filters-content__datepicker">
          <Text size={200}>{t({ id: 'Common.From' })}</Text>
          <DatePicker
            formatDate={(date) => dayFormatter(date)}
            autoWidth
            value={dateRanges[flowEndFrom] ? new Date(dateRanges[flowEndFrom]) : null}
            placeholder={t({ id: 'Common.Date.Placeholder' })}
            onSelectDate={(date) => handleDateSelect(date, flowEndFrom)}
          />
        </div>
        <div className="dashboard-filters-content__datepicker">
          <Text size={200}>{t({ id: 'Common.To' })}</Text>
          <DatePicker
            formatDate={(date) => dayFormatter(date)}
            autoWidth
            value={dateRanges[flowEndTo] ? new Date(dateRanges[flowEndTo]) : null}
            placeholder={t({ id: 'Common.Date.Placeholder' })}
            onSelectDate={(date) => handleDateSelect(date, flowEndTo)}
          />
        </div>
      </div>
    </div>
  );

  const filterMainStatus = (
    <div className="dashboard-filters-content__field-wrapper">
      <div className="dashboard-filters-content__field-wrapper-header">
        <Text as="h2" weight="semibold" size={300}>
          {t({ id: 'Dashboard.Table.Filter.Information' })}
        </Text>
      </div>
      <div className="dashboard-filters-content__options">
        {checkboxOptions.map((option) => (
          <Checkbox
            key={`checkbox-item-${option.id}`}
            className="dashboard-filters-content__checkbox"
            labelPosition="before"
            label={t({ id: option.title })}
            checked={option.checked}
            onChange={(_, data) => handleCheckboxSelect({ ...option, checked: data.checked })}
          />
        ))}
      </div>
    </div>
  );

  const filterStartDate = (
    <div className="dashboard-filters-content__field-wrapper">
      <div className="dashboard-filters-content__field-wrapper-header">
        <Text as="h2" weight="semibold">
          {t({ id: 'Dashboard.Table.Filter.Process.Start.Date' })}
        </Text>
        <Text>{t({ id: 'Dashboard.Table.Filter.Time.Select' })}</Text>
      </div>
      <div className="dashboard-filters-content__datepicker-wrapper">
        {getDateRangeActionTitle(flowBeginFrom, flowBeginTo)}
        <div className="dashboard-filters-content__datepicker">
          <Text size={200}>{t({ id: 'Common.From' })}</Text>
          <DatePicker
            formatDate={(date) => dayFormatter(date)}
            autoWidth
            value={dateRanges[flowBeginFrom] ? new Date(dateRanges[flowBeginFrom]) : null}
            placeholder={t({ id: 'Common.Date.Placeholder' })}
            onSelectDate={(date) => handleDateSelect(date, flowBeginFrom)}
          />
        </div>
        <div className="dashboard-filters-content__datepicker">
          <Text size={200}>{t({ id: 'Common.To' })}</Text>
          <DatePicker
            formatDate={(date) => dayFormatter(date)}
            autoWidth
            value={dateRanges[flowBeginTo] ? new Date(dateRanges[flowBeginTo]) : null}
            placeholder={t({ id: 'Common.Date.Placeholder' })}
            onSelectDate={(date) => handleDateSelect(date, flowBeginTo)}
          />
        </div>
      </div>
    </div>
  );

  const filterSignDate = (
    <div className="dashboard-filters-content__field-wrapper">
      <div className="dashboard-filters-content__field-wrapper-header">
        <Text as="h2" weight="semibold">
          {t({ id: 'Dashboard.Table.Filter.SigningDate' })}
        </Text>
        <Text>{t({ id: 'Dashboard.Table.Filter.Date.Select' })}</Text>
      </div>
      <div className="dashboard-filters-content__datepicker-wrapper">
        {getDateRangeActionTitle(expirationFrom, expirationTo)}
        <div className="dashboard-filters-content__datepicker">
          <Text size={200}>{t({ id: 'Common.From' })}</Text>
          <DatePicker
            formatDate={(date) => dayFormatter(date)}
            autoWidth
            value={dateRanges[expirationFrom] ? new Date(dateRanges[expirationFrom]) : null}
            placeholder={t({ id: 'Common.Date.Placeholder' })}
            onSelectDate={(date) => handleDateSelect(date, expirationFrom)}
          />
        </div>
        <div className="dashboard-filters-content__datepicker">
          <Text size={200}>{t({ id: 'Common.To' })}</Text>
          <DatePicker
            formatDate={(date) => dayFormatter(date)}
            autoWidth
            value={dateRanges[expirationTo] ? new Date(dateRanges[expirationTo]) : null}
            placeholder={t({ id: 'Common.Date.Placeholder' })}
            onSelectDate={(date) => handleDateSelect(date, expirationTo)}
          />
        </div>
      </div>
    </div>
  );

  const filterHeader = (
    <>
      <Text as="h1" weight="bold" size={500}>
        {t({ id: 'Dashboard.Table.Filter.Header' })}
      </Text>
      <Button
        size="small"
        className="dashboard-filters__close-icon"
        appearance="transparent"
        icon={<Dismiss24Regular />}
        onClick={handleCloseFilters}
      />
    </>
  );

  const filterFooter = (
    <>
      <Button appearance="transparent" size="medium" onClick={handleClearFilters}>
        {t({ id: 'Dashboard.Table.Filter.Clear.All' })}
      </Button>
      <Button appearance="primary" onClick={handleApplyFilters}>
        {t({ id: 'Dashboard.Table.Filter.Apply' })}
      </Button>
    </>
  );

  return (
    <>
      <div className="filters-overlay" style={{ visibility: isOpen ? 'visible' : 'hidden' }} />
      <div className="dashboard-filters" style={{ visibility: isOpen ? 'visible' : 'hidden' }}>
        <div className="dashboard-filters-header">{filterHeader}</div>
        <div className="dashboard-filters-content">
          {filterMainStatus}
          {filterStartDate}
          {filterSignDate}
          {filterEndDate}
        </div>
        <div className="dashboard-filters-content__buttons-wrapper">{filterFooter}</div>
      </div>
    </>
  );
};
