import { useState, useMemo, useEffect } from 'react';
import { Dropdown, Option } from '@fluentui/react-components';
import { useFormContext } from 'react-hook-form';
import './LanguagesDropdown.scss';

export const LanguagesDropdown = ({ config = {}, fieldName, errorMessage, isDisabled, setIsFormDirty }) => {
  const formContext = useFormContext();
  const { setValue } = formContext;
  const [language, setLanguage] = useState(config.defaultValue.key);

  useEffect(() => {
    setValue(fieldName, language);
  }, [language, fieldName]);

  const languageItems = useMemo(() => {
    return config.items;
  }, [config]);

  const getHeader = (value) => {
    return languageItems.find(({ key }) => key === value).header;
  };

  const handleDropdownChange = (_, e) => {
    setIsFormDirty(true);
    setLanguage(e.optionValue);
  };

  return (
    <div className="languages-dropdown__container">
      <Dropdown
        appearance="filled-darker"
        className="languages-dropdown"
        disabled={isDisabled}
        defaultValue={config.defaultValue.header}
        selectedOptions={[language]}
        onOptionSelect={handleDropdownChange}
        value={getHeader(language)}
      >
        {languageItems.map((option) => (
          <Option key={option.key} value={option.key}>
            {option.header}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};
