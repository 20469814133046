import { useEffect, useState } from 'react';
import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { QRCanvas } from 'shared-fe-components/src/common/QRCanvas';
import { IdNowIcon } from 'shared-fe-components/src/common/Icons';
import { LoadingScreen } from '../../Common/LoadingScreen';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { ProviderPendingScreen } from 'shared-fe-components/src/common/StatusScreens';
import { useSignTaskContext } from '../SignTaskContext';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { Button as ButtonCustom } from 'shared-fe-components/src/common/FluentMigration';
import 'shared-fe-components/src/providers/IdNow/IdNowSign.scss';

const IdNowBtnLink = ({ children, idNowUrl }) => {
  return (
    <button
      className="idnow-sign__btn-link"
      onClick={() => {
        window.open(idNowUrl);
      }}
    >
      {children}
    </button>
  );
};

export const IdNowSign = ({ additionalParameters, onBack, onCancel, goToDownload }) => {
  const [loading, setLoading] = useState(true);
  const [providerPending, setProviderPending] = useState(false);
  const [idNowIdentId, setIdNowIdentId] = useState(null);
  const [idNowUrl, setIdNowUrl] = useState(null);
  const { sign, getSignatureStatus } = useSignTaskContext();

  // start signing effect
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await sign({ additionalParameters });
      setIdNowIdentId(response?.providerResponse?.identId);
      setIdNowUrl(response?.providerResponse?.startPageUrl);
      setLoading(false);
    })();
  }, [additionalParameters, sign]);

  // status refetching effect
  useEffect(() => {
    const signStatusInterval = setInterval(async () => {
      if (!loading) {
        await getSignatureStatus({ onProviderPending: () => setProviderPending(true) });
      }
    }, 5000);
    return () => clearInterval(signStatusInterval);
  }, [getSignatureStatus, loading]);

  if (loading) {
    return <LoadingScreen />;
  } else if (providerPending) {
    return <ProviderPendingScreen />;
  } else {
    return (
      <WizardBox className="idnow-sign" growContent={false} centerContent>
        <div>
          <Text as="h1" weight="bold">
            {t({ id: 'Signing.SignHeader' })}
          </Text>
        </div>

        <div className="idnow-sign__content-container">
          <div className="idnow-sign__color-container">
            <div className="idnow-sign__sign-container">
              <IdNowIcon className="idnow-sign__logo" />
              {idNowIdentId && (
                <div className="idnow-sign__process-qr-code-details">
                  <Trans id="Providers.IdNow.Guide.1" />
                  <QRCanvas code={idNowUrl} width={180} height={180} alt={t({ id: 'Common.QRCodeAlt' })} />
                  <div>
                    <Trans id="Providers.IdNow.Guide.2" components={[<IdNowBtnLink idNowUrl={idNowUrl} />]} />
                  </div>
                </div>
              )}
              <div className="idnow-sign__process-details">
                <Text> {t({ id: 'Providers.IdNow.Details' })}</Text>
                <div className="idnow-sign__process-details-identId idnow-sign__process-details-flex-wrapper">
                  <div>Ident-ID</div>
                  <div className="idnow-sign__process-details--value">{idNowIdentId}</div>
                </div>
                <div className="idnow-sign__process-details-flex-wrapper">
                  <Trans id="Common.DownloadTheApp.ID" values={{ appName: 'IDnow' }} />
                  <ButtonCustom outline inverted onClick={goToDownload}>
                    {t({ id: 'Common.DownloadTheApp' })}
                  </ButtonCustom>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <ButtonBack onClick={onBack} />
          <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
        </div>
      </WizardBox>
    );
  }
};
