import * as api from 'shared-fe-components/src/api';
import { createUseContinuousData } from './createUseContinuousData';
import dayjs from 'dayjs';

const fetchDocumentsWithParams = async (params) => {
  const startOfDay = (type) => dayjs(params.dateFilterRanges[type]).startOf('day');
  const endOfDay = (type) => dayjs(params.dateFilterRanges[type]).endOf('day');

  const requestParams = {
    ...params,
    sortBy: params.sortBy ? params.sortBy : undefined,
    sortingOrder: params.sortingOrder > 0 ? 'Ascending' : params.sortingOrder < 0 ? 'Descending' : undefined,
    searchPhrase: params.searchPhrase ? params.searchPhrase : undefined,
    mainFilter: params.mainFilter === 'All' || params.mainFilter === 'Archived' ? undefined : params.mainFilter,
    archived: params.mainFilter === 'Archived' ? true : undefined,
    flowBeginFrom: params.dateFilterRanges?.flowBeginFrom && startOfDay('flowBeginFrom'),
    flowBeginTo: params.dateFilterRanges?.flowBeginTo && endOfDay('flowBeginTo'),
    flowEndFrom: params.dateFilterRanges?.flowEndFrom && startOfDay('flowEndFrom'),
    flowEndTo: params.dateFilterRanges?.flowEndTo && endOfDay('flowEndTo'),
    expirationFrom: params.dateFilterRanges?.expirationFrom && startOfDay('expirationFrom'),
    expirationTo: params.dateFilterRanges?.expirationTo && endOfDay('expirationTo'),
  };
  delete requestParams.dateFilterRanges; // Removing, so the spread operator won't send unnecessary data to BE
  const result = await api.signing.getSigningSessions(requestParams);

  return [result.items, result.continuationToken];
};

const fetchMoreDocumentsWithToken = async (continuationToken, params) => {
  return await fetchDocumentsWithParams({ ...params, continuationToken });
};

export const useSigningSessionFiles = createUseContinuousData(fetchDocumentsWithParams, fetchMoreDocumentsWithToken);
