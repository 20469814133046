import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@fluentui/react-components/unstable';
import { Button } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { GraphError } from '@microsoft/microsoft-graph-client';
import { HTTPError } from 'ky';
import { ArrowRotateClockwise16Regular, Warning16Regular } from '@fluentui/react-icons';

const selectErrorMessage = (error) => {
  const statusCode =
    error instanceof GraphError ? error.statusCode : error instanceof HTTPError ? error.response.status : undefined;

  if (typeof statusCode === 'number') {
    switch (statusCode) {
      case 404:
        return t({ id: 'FileBrowser.Error.FolderNotFound' });
      case 500:
        return t({ id: 'FileBrowser.Error.ServerError' });
      default: // falls through
    }
  }

  return t({ id: 'FileBrowser.Error.UnknownError' });
};

export const FileBrowserFilesError = ({ error, onRetry = null }) => (
  <Alert
    intent="error"
    icon={<Warning16Regular />}
    action={
      <Button
        appearance="transparent"
        icon={<ArrowRotateClockwise16Regular />}
        title={t({ id: 'Common.Retry' })}
        onClick={onRetry}
      />
    }
  >
    {selectErrorMessage(error)}
  </Alert>
);

FileBrowserFilesError.propTypes = {
  error: PropTypes.object,
  onRetry: PropTypes.func,
};
