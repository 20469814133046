import { useEffect, useRef, useState } from 'react';
import { IdNowSign } from './';
import { IdNowForm, IdNowRequirements, IdNowDownload } from 'shared-fe-components/src/providers/IdNow';
import { useGraphApi } from '../../../lib/useGraph';
import { LoadingScreen } from '../../Common/LoadingScreen';
import { useSignTaskContext } from '../SignTaskContext';
import { useLocalizationContext } from 'contexts';

export const IdNowFlow = () => {
  const [step, setStep] = useState('requirements');
  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useSignTaskContext();

  const goToRequirements = () => setStep('requirements');
  const goToDownload = () => setStep('download');
  const goToForm = () => setStep('form');
  const goToSign = () => setStep('sign');

  useEffect(() => {
    if (overlay !== null) {
      goToRequirements();
    }
  }, [overlay]);

  const [userData, setUserData] = useState(null);
  const { userData: fetchUserData } = useGraphApi();
  useEffect(() => {
    fetchUserData().then((graphData) => {
      setUserData(graphData);
    });
  }, [fetchUserData]);

  const additionalParameters = useRef({});
  const formData = useRef({});

  const { locale } = useLocalizationContext();

  const handleFormData = (data) => {
    formData.current = data;
    additionalParameters.current = {
      ...additionalParameters.current,
      idNowParameters: { ...formData.current },
      culture: locale,
    };
  };

  const onBackInForm = (data) => {
    handleFormData(data);
    goToRequirements();
  };

  const onContinueInForm = (data) => {
    handleFormData(data);
    setStep('sign');
  };

  return (
    <>
      {step === 'requirements' && (
        <IdNowRequirements
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onContinue={goToForm}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'form' && !userData && <LoadingScreen />}
      {step === 'form' && userData && (
        <IdNowForm
          formData={formData.current}
          onBack={onBackInForm}
          onCancel={onCancel}
          onContinue={onContinueInForm}
          userData={userData}
        />
      )}
      {step === 'sign' && (
        <IdNowSign
          additionalParameters={additionalParameters.current}
          onBack={goToForm}
          onCancel={onCancel}
          goToDownload={goToDownload}
        />
      )}
      {step === 'download' && <IdNowDownload onBack={goToSign} onCancel={onCancel} />}
    </>
  );
};
