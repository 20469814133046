import { Text } from '@fluentui/react-components';
import { InperlyIcon, FreemiumIcon } from 'shared-fe-components/src/common/Icons';
import { t } from '@lingui/macro';
import { useConfigurationContext, useUserContext } from 'contexts';
import * as api from 'shared-fe-components/src/api';
import { Button as CustomButton } from 'shared-fe-components/src/common/FluentMigration';
import { License } from '../../contexts/user/models';
import { CalendarArrowCounterclockwise48Regular } from '@fluentui/react-icons';
import './LicenseError.scss';
import * as microsoftTeams from '@microsoft/teams-js';

type Props = {
  license?: License;
};

export const LicenseError = ({ license }: Props) => {
  const { freemiumStatus } = useUserContext();
  const { appId } = useConfigurationContext();

  let licenseState = 'Missing';
  if (license?.isCancelled) {
    licenseState = 'Cancelled';
  } else if (license?.isSuspended) {
    licenseState = 'Suspended';
  }

  const onActivateFreemium = async () => {
    if (!freemiumStatus.isAvailable) return;

    const response = await api.subscriptions.activateFreemiumSubscription();

    if (response.activationUrl) window.open(response.activationUrl);

    const taskInfo = {
      url: `${window.location.origin}/reload`,
      title: t({ id: 'Common.Catchphrase' }),
      size: {
        height: 530,
        width: 600,
      },
    };

    const submitHandler: microsoftTeams.dialog.DialogSubmitHandler = ({ result }) => {
      if (result === 'reload') window.location.reload();
    };

    microsoftTeams.dialog.url.open(taskInfo, submitHandler);
  };

  const onPurchaseSubscription = async () => {
    await microsoftTeams.appInstallDialog.openAppInstallDialog({
      appId: appId,
    });
  };

  const subscriptionBox = (
    <div className="license-box license-box__subscription">
      <CalendarArrowCounterclockwise48Regular className="license-box__icon" />
      <Text as="h3" size={600} className="license-box__header">
        {t({ id: `License.Subscription` })}
      </Text>
      <div className="license-feature">
        {Object.values(t({ id: `License.Subscription.Features` })).map((feature: string) => (
          <Text size={300} className="license-feature__tag" key={feature}>
            {feature}
          </Text>
        ))}
      </div>

      <Text size={500} as="h3" className="license-box__cta-text">
        {t({ id: `License.Subscription.Cta` })}
      </Text>

      <CustomButton outline inverted onClick={onPurchaseSubscription}>
        {t({ id: `License.Subscription.Cta.Button` })}
      </CustomButton>
    </div>
  );

  const freemiumBox = (
    <div className={`license-box license-box__freemium ${!freemiumStatus.isAvailable ? 'licence-box__disabled' : ''}`}>
      <FreemiumIcon className="license-box__icon" />
      <Text as="h3" size={600} className="license-box__header">
        {t({ id: `License.Freemium` })}
      </Text>
      <div className="license-feature">
        <Text size={300} className="license-feature__tag">
          {t({ id: `License.Freemium.Features.1`, values: { operationCount: freemiumStatus.defaultLimits?.operationCount } })}
        </Text>
        <Text size={300} className="license-feature__tag">
          {t({ id: `License.Freemium.Features.2` })}
        </Text>
      </div>

      <Text size={500} as="h3" className="license-box__cta-text">
        {t({ id: `License.Freemium.Cta`, values: { lengthInDays: freemiumStatus.defaultLimits?.lengthInDays } })}
      </Text>

      <CustomButton outline inverted onClick={onActivateFreemium} disabled={!freemiumStatus.isAvailable}>
        {t({ id: `License.Freemium.Cta.Button` })}
      </CustomButton>
    </div>
  );

  const licenseHeader = (
    <div className="license-error__header">
      <InperlyIcon className="license-error__icon" />
      <Text as="h1" weight="bold" size={600} className="license-error__title">
        {t({ id: `License.Header.Title` })}
      </Text>
      <Text as="h2" size={500} className="license-error__header-text">
        {t({ id: `License.${licenseState}.Title` })}
      </Text>
    </div>
  );

  return (
    <div className="license-error__page">
      <div className="license-error">
        {licenseHeader}
        {subscriptionBox}
        {freemiumBox}
      </div>
    </div>
  );
};
