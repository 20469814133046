import { useData } from "./useData";
import { TeamsUserCredential, createMicrosoftGraphClient } from "@microsoft/teamsfx";
import { FileUpload, OneDriveLargeFileUploadTask } from '@microsoft/microsoft-graph-client';

const executeGraph = async (callback, scope) => {
  const credential = new TeamsUserCredential({
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
    clientId: process.env.REACT_APP_CLIENT_ID
  });
  await credential.getToken(scope);

  const graph = createMicrosoftGraphClient(credential, scope);

  return callback(graph);
};

export function useGraph(asyncFunc, options) {
  const { scope, initialState } = { scope: ["User.Read", "Files.ReadWrite.All"], initialState: {}, ...options };

  const { data, error, loading, reload } = useData(() => executeGraph(asyncFunc, scope), { auto: false, initialState });

  return { data, error, loading, reload };
};

const getFileInfoAsync = async (driveId, fileId) => {
  const scope = ["User.Read", "Files.ReadWrite.All"];

  return await executeGraph((graph) => graph.api(`/drives/${driveId}/items/${fileId}`).get(), scope);
};

const oneDriveCreateFolderAsync = async (name) => {
  const scope = ["Files.ReadWrite.All"];

  return await executeGraph((graph) => graph.api(`/me/drive/root/children`).post({
    name,
    folder: {}
  }), scope);
};

const oneDriveGetPathInfoAsync = async (path) => {
  const scope = ["User.Read", "Files.ReadWrite.All"];

  return await executeGraph((graph) => graph.api(`/me/drive/root:/${path}`).get(), scope);
};

const oneDriveFileUploadAsync = async (path, file) => {
  const scope = ["Files.ReadWrite.All"];

  return await executeGraph(async (graph) => {
    const options = {
      path,
      fileName: file.name,
    };

    const fileObject = new FileUpload(file, file.name, file.size);
    const task = await OneDriveLargeFileUploadTask.createTaskWithFileObject(graph, fileObject, options);

    return task.upload();
  }, scope);
};

const userData = async (userId) => {
  const scope = ["User.ReadBasic.All"];

  if (!!userId) {
    return await executeGraph((graph) => graph.api(`/users/${userId}`).get(), scope);
  } else {
    return await executeGraph((graph) => graph.api(`/me`).get(), scope);
  }
};

const userPhoto = async (userId) => {
  const scope = ["User.ReadBasic.All"];

  return await executeGraph((graph) => graph.api(`/users/${userId}/photos/48x48/$value`).get(), scope);
};

const graphApi = { getFileInfoAsync, oneDriveCreateFolderAsync, oneDriveGetPathInfoAsync, oneDriveFileUploadAsync, userData, userPhoto };
export const useGraphApi = () => graphApi;
