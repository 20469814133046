import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FileBrowserFiles } from './FileBrowserFiles';
import { useTeamChannelFileList } from './useFileList';
import { first, last, take } from './utils';

export const FileBrowserTeamFiles = ({ team, allowedFileTypes = null, onFileSelect }) => {
  const [ordering, setOrdering] = useState(1);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const { loading, data, error, reload } = useTeamChannelFileList(
    first(selectedFolders)?.id,
    last(selectedFolders)?.id
  );

  useEffect(() => {
    setSelectedFolders([team]);
  }, [team]);

  const onOrderingClick = useCallback(() => setOrdering((value) => -value), []);

  const onFolderSelect = useCallback((folder) => {
    setSelectedFolders((current) => [...current, folder]);
  }, []);

  const onBreadcrumbClick = useCallback((index) => {
    setSelectedFolders((current) => take(current, index + 1));
  }, []);

  return (
    <FileBrowserFiles
      data={data}
      loading={loading}
      error={error}
      selectedFolders={selectedFolders}
      ordering={ordering}
      allowedFileTypes={allowedFileTypes}
      onRetry={reload}
      onOrderingClick={onOrderingClick}
      onFileSelect={onFileSelect}
      onBreadcrumbClick={onBreadcrumbClick}
      onFolderSelect={onFolderSelect}
    />
  );
};

FileBrowserTeamFiles.propTypes = {
  team: PropTypes.object,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  onFileSelect: PropTypes.func.isRequired,
};
