import { useState, useMemo, ReactNode } from 'react';
import { Checkmark24Regular, Dismiss24Regular, Alert24Regular } from '@fluentui/react-icons';
import {
  Dialog,
  Button,
  Text,
  Tooltip,
  Spinner,
  DialogBody,
  DialogActions,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
} from '@fluentui/react-components';
import { useForm, Controller } from 'react-hook-form';
import { Props } from './models';
import { TextAreaWithCounter } from 'shared-fe-components/src/common/TextAreaWithCounter/TextAreaWithCounter';
import { t } from '@lingui/macro';
import dayjs from 'dayjs';
import * as api from 'shared-fe-components/src/api';
import { InperlyLogoSloganColor } from 'shared-fe-components/src/common/Icons';
import './ReminderFormDialog.scss';

const ReminderDialogBody = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <div className="reminder-form-dialog__icon">
        <Alert24Regular style={{ zoom: 2 }} />
      </div>
      <div className="reminder-form-dialog__text">
        <Text as="p" size={700} weight="semibold">
          {t({ id: 'ProcessDetails.SendReminder.Dialog.Header' })}
        </Text>
        <br />
        <Text as="p" size={500}>
          {t({ id: 'ProcessDetails.SendReminder.Dialog.Content' })}
        </Text>
      </div>
      <div className="reminder-form-dialog__dialog-content">{children}</div>
    </div>
  );
};

export const ReminderFormDialog = ({ recipient, lastRemind, sessionId, signatureId }: Props) => {
  const isReminderBlocked = useMemo(() => {
    const today = dayjs(new Date());
    const date = dayjs(lastRemind);
    return date.isValid() && today.diff(date, 'days') <= 1;
  }, []);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const [isSent, setIsSent] = useState(isReminderBlocked);

  const formMethods = useForm({
    criteriaMode: 'all',
    defaultValues: {
      [recipient.id]: '',
    },
    mode: 'onChange',
  });
  const {
    control,
    formState: { errors },
    getValues,
  } = formMethods;

  const sendForm = async () => {
    if (errors[recipient.id] !== undefined || !isButtonActive) {
      return;
    }

    try {
      setIsButtonActive(false);
      await api.signing.sendManualReminder({
        message: getValues(recipient.id) ?? '',
        sessionId: sessionId,
        signatureId: signatureId,
      });
      setIsSent(true);
      closeDialog();
    } catch (e) {
      setIsButtonActive(true);
      console.error(e);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {isSent ? (
        <Tooltip content={t({ id: 'ProcessDetails.SendReminder.Tooltip' })} relationship="label">
          <Button className="reminder-form-dialog__trigger-button">
            <Checkmark24Regular />
          </Button>
        </Tooltip>
      ) : (
        <Dialog open={isDialogOpen} onOpenChange={(event, data) => setIsDialogOpen(data.open)}>
          <DialogTrigger>
            <Button className="reminder-form-dialog__trigger-button">{t({ id: 'ProcessDetails.SendReminder' })}</Button>
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle
                action={
                  <DialogTrigger action="close">
                    <Button icon={<Dismiss24Regular />} appearance="transparent" />
                  </DialogTrigger>
                }
              >
                <InperlyLogoSloganColor className="reminder-form-dialog__dialog-logo" />
              </DialogTitle>
              <DialogContent>
                <ReminderDialogBody>
                  <Controller
                    control={control}
                    name={recipient.id}
                    rules={{ maxLength: 200 }}
                    render={(formData) => (
                      <TextAreaWithCounter
                        className="reminder-form-dialog__textarea"
                        isAlertVisible
                        alertText={t({ id: 'ProcessDetails.SendReminder.Dialog.TextArea.Error' })}
                        fluid
                        characterLimit={200}
                        formData={formData}
                      />
                    )}
                  />
                </ReminderDialogBody>
              </DialogContent>
              <DialogActions>
                <DialogTrigger action="close">
                  <Button className="reminder-form-dialog__dialog-button">
                    {t({ id: 'ProcessDetails.SendReminder.Dialog.Cancel' })}
                  </Button>
                </DialogTrigger>
                <Button className="reminder-form-dialog__dialog-button" appearance="primary" onClick={sendForm}>
                  {isButtonActive ? t({ id: 'ProcessDetails.SendReminder.Dialog.Accept' }) : <Spinner size="tiny" />}
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
};
