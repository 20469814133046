import { TeamsUserCredential } from '@microsoft/teamsfx';

const tokenSearchParam = new URLSearchParams(window.location.search).get('token');

let teamsToken; // { token, expiresOnTimestamp, ... }
let teamsTokenFetcherPromise; // Promise, when we're in process of new token fetching

const fetchNewTeamsToken = () => {
  if (!teamsTokenFetcherPromise) {
    teamsTokenFetcherPromise = new Promise((resolve, reject) => {
      if (tokenSearchParam) {
        resolve({
          token: tokenSearchParam,
          expiresOnTimestamp: new Date().valueOf() + 60 * 60 * 1000, // 1 hour
        });
        return;
      }

      new TeamsUserCredential({
        initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
        clientId: process.env.REACT_APP_CLIENT_ID
      })
        .getToken([])
        .then((value) => {
          teamsToken = value;
          teamsTokenFetcherPromise = undefined;

          resolve(teamsToken);
        })
        .catch((error) => {
          teamsToken = undefined;
          teamsTokenFetcherPromise = undefined;

          reject(error);
        });
    });
  }

  return teamsTokenFetcherPromise;
}

const isTeamsTokenValid = (token) => {
  return token?.token && token?.expiresOnTimestamp > Date.now();
};

window.getToken = async () => {
  if (isTeamsTokenValid(teamsToken)) {
    return teamsToken.token;
  }

  // if getting new access token fails, ignore it – probably the app is used buy anonymous user in web browser
  try {
    const newToken = await fetchNewTeamsToken();
    if (isTeamsTokenValid(newToken)) {
      return newToken.token;
    }
  } catch { }

  return undefined;
};
