import { Text } from '@fluentui/react-components';
import { useSearchParams } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import { useTeamsContext, useUserContext } from 'contexts';
import { t } from '@lingui/macro';
import { InperlyIcon } from 'shared-fe-components/src/common/Icons';
import { LicenseError } from '../License';
import './TabConfig.scss';

const ChannelTabConfig = ({ appName }) => (
  <Text as="h2">{t({ id: 'Config.AddApp.Channel.Title', values: { appName } })}</Text>
);

const MeetingTabConfig = ({ appName }) => (
  <Text as="h2">{t({ id: 'Config.AddApp.Meeting.Title', values: { appName } })}</Text>
);

export const TabConfig = () => {
  const [searchParams] = useSearchParams();

  const { context: teamsContext } = useTeamsContext();

  const displayName = t({ id: 'Common.AppName' });
  const { hasLicense, user } = useUserContext();

  microsoftTeams.app.initialize().then(() => {
    microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
      let baseUrl = `https://${window.location.hostname}`;

      let url = '/tab?initialTheme={theme}';
      if (searchParams.get('meetingId')) {
        // note: meetingId might be present in query but be empty
        url = '/meeting?initialTheme={theme}';
      }

      microsoftTeams.pages.config.setConfig({
        suggestedDisplayName: displayName,
        entityId: 'inperly-for-teams',
        contentUrl: baseUrl + url,
      });
      saveEvent.notifySuccess();
    });

    microsoftTeams.pages.config.setValidityState(hasLicense);
  });

  return (
    <div className="tab-config background-settings">
      {hasLicense ? (
        <>
          <InperlyIcon className="tab-config__icon" />

          {teamsContext?.channel?.id && <ChannelTabConfig appName={displayName} />}
          {teamsContext?.meeting?.id && <MeetingTabConfig appName={displayName} />}
        </>
      ) : (
        <>
          <LicenseError license={user?.license} />
        </>
      )}
    </div>
  );
};
