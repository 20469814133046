import { Image } from '@fluentui/react-components';
import { PdfIcon, XmlIcon, ImageIcon } from 'shared-fe-components/src/common/FilesIcon/utils';
import { FILE_FORMATS_MAPPED } from 'shared-fe-components/src/utils/fileFormats';

const DriveItemIcon = ({ isFolder = false, mimeType = null }) => {
  if (isFolder) return <Image src="/icons/folder.svg" />;

  switch (mimeType) {
    case 'image/png': return <ImageIcon />;
    case 'image/jpeg': return <ImageIcon />;
    case FILE_FORMATS_MAPPED.PDF: return <PdfIcon />;
    default: return <XmlIcon />;
  }
}

export default DriveItemIcon;
