import { useRef, useCallback } from 'react';

export const useLatestCallable = (value) => {
  const ref = useRef();
  ref.current = value;

  const callback = useCallback((...args) => ref.current(...args), []);

  return callback;
};
