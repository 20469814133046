import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { CloudUploadIcon } from 'shared-fe-components/src/common/Icons';
import { t } from '@lingui/macro';
import './FileDropper.scss';
import { Text } from '@fluentui/react-components';

interface Props {
  onAddFiles: (files: File[]) => void;
  acceptedFileTypes: DropzoneOptions['accept'];
  onInputClick: () => void;
}

export const FileDropper = ({ onAddFiles, acceptedFileTypes, onInputClick }: Props) => {
  const onDrop: DropzoneOptions['onDrop'] = (acceptedFiles) => {
    onAddFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { ...acceptedFileTypes },
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return (
    <div {...getRootProps()} className="file-dropper" onClick={onInputClick}>
      <input {...getInputProps()} />
      <CloudUploadIcon />
      <Text>{t({ id: 'Form.FilesInputs.DragAndDrop' })}</Text>
    </div>
  );
};
