import { createContext, useContext, useEffect, useMemo } from 'react';
import { useTeamsContext } from 'contexts';
import { ColorSchemeVariables } from 'shared-fe-components/src/common/ColorSchemeVariables';
import { Theme, ThemeObject, ThemeProps, darkTheme, whiteTheme } from './models';
import { useCookies } from 'shared-fe-components/src/hooks';

const Context = createContext<ThemeObject | null>(null);

const getCustomColors = (themeName: string): Theme => {
  return themeName === 'default' ? whiteTheme : { ...whiteTheme, ...darkTheme };
};

export const ThemeContextProvider = ({ children }: ThemeProps) => {
  const { setCookie } = useCookies();
  const { themeName, theme } = useTeamsContext();

  useEffect(() => {
    document.body.setAttribute('app-theme', themeName);
    setCookie({ key: 'theme', value: themeName });
  }, [themeName]);

  const customColors = useMemo(() => getCustomColors(themeName),[themeName]);

  return (
    <Context.Provider
      value={{
        theme,
        customColors,
        themeName,
      }}
    >
      <ColorSchemeVariables themeColors={theme as any} customColors={customColors} />
      {children}
    </Context.Provider>
  );
};

export const useThemeContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Lack of ThemeProvider in components tree');
  }

  return ctx;
};
