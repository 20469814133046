import { useCallback, useMemo } from 'react';
import { useLocalizationContext } from '../contexts';

const isValidDate = (date) => {
  if (date instanceof Date) {
    return Number.isFinite(date.getTime());
  } else {
    return Number.isFinite(date);
  }
};

export const useDateFormatter = (options) => {
  const { locale } = useLocalizationContext();
  const formatter = useMemo(() => new Intl.DateTimeFormat(locale, options), [locale, options]);
  return useCallback((date) => isValidDate(date) ? formatter.format(date) : null, [formatter]);
};
