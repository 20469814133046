import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocalization } from 'shared-fe-components/src/contexts/LocalizationContext';
import { LanguagesDropdown } from '../../../Common/LanguagesDropdown/LanguagesDropdown';
import { LANGUAGES } from 'shared-fe-components/src/common/Constants';

export const Languages = ({ index, config = {}, moduleName, isDisabled, setIsFormDirty }) => {
  const {
    formState: { errors },
    getValues,
  } = useFormContext();
  const { locale } = useLocalization();

  const fieldName = `${moduleName}.${index}.lang`;
  const mapedLanguages = LANGUAGES.map((item) => ({ key: item.key, header: item.value }));

  const languagesConfig = useMemo(() => {
    return {
      ...config,
      items: mapedLanguages,
      defaultValue: getValues(fieldName)
        ? mapedLanguages.find((item) => item.key === getValues(fieldName))
        : mapedLanguages.some((item) => item.key === locale)
        ? mapedLanguages.find((item) => item.key === locale)
        : mapedLanguages.find((item) => item.key === 'en'),
    };
  }, [locale, config, fieldName]);

  return (
    <LanguagesDropdown
      config={languagesConfig}
      fieldName={fieldName}
      errorMessage={errors?.[moduleName]?.[index]?.value?.message ?? ''}
      isDisabled={isDisabled}
      setIsFormDirty={setIsFormDirty}
    />
  );
};
