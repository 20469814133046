import { MenuList, MenuItem } from '@fluentui/react-components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import DriveItemIcon from '../../../FileList/DriveItemIcon';
import './FileBrowserFilesList.scss';

const isFileAllowed = (allowedFileTypes, entry) => {
  return allowedFileTypes.includes(entry.file?.mimeType);
};

const Icon = ({ entry }) => (
  <>
    <div style={{ width: '1.5em' }}>
      <DriveItemIcon isFolder={!!entry.folder} mimeType={entry.file?.mimeType} />
    </div>
  </>
);

export const FileBrowserFilesList = ({
  values = [],
  allowedFileTypes = null,
  ordering = 1,
  className = '',
  onFolderSelect = null,
  onFileSelect = null,
}) => {
  const items = useMemo(() => {
    const filter =
      Array.isArray(allowedFileTypes) && allowedFileTypes.length > 0
        ? (entry) => entry.folder || isFileAllowed(allowedFileTypes, entry)
        : () => true;

    return values
      .filter(filter)
      .sort((a, b) => {
        if ((a.folder && b.folder) || (a.file && b.file)) {
          return a.name.localeCompare(b.name) * ordering;
        }

        return a.folder ? -1 : 1;
      })
      .map((entry) => ({
        key: entry.id,
        media: <Icon entry={entry} />,
        content: entry.name,
        onClick: entry.folder ? () => onFolderSelect?.(entry) : () => onFileSelect?.(entry),
      }));
  }, [values, allowedFileTypes, ordering, onFolderSelect, onFileSelect]);


  return (
    <MenuList className={`file-browser-files-list__list ${className}`}>
      {items.map((item) => (
        <MenuItem key={item.key}>
          <div className="menu-item-container" onClick={() => item.onClick()}>
            <div>{item.media}</div>
            <div>{item.content}</div>
          </div>
        </MenuItem>
      ))}
    </MenuList>
  );
};

FileBrowserFilesList.propTypes = {
  className: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        file: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        folder: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ])
  ),
  ordering: PropTypes.number,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  onFileSelect: PropTypes.func.isRequired,
  onFolderSelect: PropTypes.func.isRequired,
};
