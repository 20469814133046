import { useState, useEffect, useCallback, useMemo } from 'react';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import { Text } from 'shared-fe-components/src/common/FluentMigration';
import { useMeetingContext } from 'contexts';
import { WaitingIcon } from 'shared-fe-components/src/common/Icons';
import { t } from '@lingui/macro';
import { LoadingScreen } from '../../Common/LoadingScreen';
import { VerificationSummaryTable } from '../VerificationSummaryTable';
import { useDateFormatter } from 'lib/useDateFormatter';
import * as api from 'shared-fe-components/src/api';
import './CalendarDetails.scss';

const dateFormatterOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const CalendarDetails = () => {
  const [sessions, setSessions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionsExist, setSessionsExist] = useState(false);

  const { meeting, user } = useMeetingContext();

  const refreshVerificationAsync = useCallback(async () => {
    if (user) {
      const response = await api.meeting.getVerificationSummary(meeting.id);
      if (response) {
        setSessions(response);
        setSessionsExist(response?.length > 0);
      }
      setLoading(false);
    }
  }, [meeting.id, user]);

  useEffect(() => {
    refreshVerificationAsync();
  }, [refreshVerificationAsync]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="calendar-details">
      {sessionsExist ? <VerificationsAccordion sessions={sessions} /> : <VerificationsNotFound />}
    </div>
  );
};

const VerificationsNotFound = () => {
  return (
    <div className="calendar-details__verfications-not-found">
      <WaitingIcon />
      <Text size={500} weight="bold">
        {t({ id: 'CalendarDetails.VerificationsNotFound.Title' })}
      </Text>
      <Text>{t({ id: 'CalendarDetails.VerificationsNotFound.Text' })}</Text>
    </div>
  );
};

const VerificationsAccordion = ({ sessions }) => {
  const entries = useMemo(
    () =>
      sessions.map((s, idx) => ({
        key: s.id,
        value: idx,
        title: t({ id: 'CalendarDetails.Verification', values: { n: idx + 1 } }),
        verifications: s.verifications,
        startTime: s?.startTime,
        endTime: s?.endTime,
      }))
      .map(props => <AccordionEntry {...props}/>),
    [sessions]
  );

  return <Accordion defaultOpenItems={sessions.length - 1} collapsible>{entries}</Accordion>;
};

const AccordionEntry = ({ value, title, verifications, startTime, endTime }) => {
  const dateFormatter = useDateFormatter(dateFormatterOptions);
  const startTimeFormatted = startTime ? dateFormatter(new Date(startTime)) : '–';
  const endTimeFormatted = endTime ? dateFormatter(new Date(endTime)) : '–';
  return (
    <AccordionItem value={value}>
      <AccordionHeader>{title}</AccordionHeader>
      <AccordionPanel>
        <VerificationSummaryTable verifications={verifications} />
        <div>
          <Text disabled>{t({ id: 'CalendarDetails.VerificationStartTime' })}: {startTimeFormatted}</Text>
        </div>
        <div>
          <Text disabled>{t({ id: 'CalendarDetails.VerificationEndTime' })}: {endTimeFormatted}</Text>
        </div>
      </AccordionPanel>
    </AccordionItem>
  );
};
