import { t } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { CountryCallingCodeDropdown } from 'shared-fe-components/src/common/ReactHookForm';
import { useEffect } from 'react';
import { CountryFlagsPreloader } from 'shared-fe-components/src/common/CountryFlags/CountryFlagsPreloader';
import { InputWithError } from 'shared-fe-components/src/common/WithError';
import './PhoneNumberWithCode.scss';

export const PhoneNumberWithCode = ({ index, moduleName, isDisabled, phoneCodes }) => {
  const {
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();

  const fieldNameCode = `${moduleName}.${index}.mobilephoneCode`;
  const fieldNameNumber = `${moduleName}.${index}.mobilephoneNumber`;

  useEffect(() => {
    const savedFieldNameCode = getValues(moduleName)[index].mobilephoneCode;
    setValue(fieldNameCode, savedFieldNameCode ?? 'PL-48');
  }, []);

  const phoneNumberRules = {
    pattern: {
      value: /^\d+$/g,
      message: t({ id: 'Providers.IdNow.Form.Field.mobilephoneNumber.Error.OnlyDigits' }),
    },
    maxLength: {
      value: 15,
      message: t({ id: 'Providers.IdNow.Form.Field.mobilephoneNumber.Error.MaxLength' }),
    },
    minLength: {
      value: 5,
      message: t({ id: 'Providers.IdNow.Form.Field.mobilephoneNumber.Error.MinLength' }),
    },
  };

  const countryCodeProps = {
    menuPlacement: 'bottom',
    fieldName: fieldNameCode,
    whiteList: phoneCodes,
    isLoading: phoneCodes.length === 0,
    isDisabled: phoneCodes.length === 0 || isDisabled,
    minMenuHeight: 400,
    className: 'input__mobilephone-code',
    styles: {
      control: (provided) => ({
        ...provided,
        width: '100px',
      }),
      menuList: (provided) => ({
        ...provided,
        width: '200px',
      }),
    },
  };

  const numberProps = {
    className: 'input__mobilephone-number',
    placeholder: t({ id: 'Providers.IdNow.Form.Field.mobilephoneNumber.Placeholder' }),
    fluid: true,
    disabled: isDisabled,
    appearance: 'filled-darker',
  };

  const mobilephoneErrorMessage = errors[moduleName] && errors[moduleName][index]?.mobilephoneNumber?.message;

  return (
    <>
      <CountryFlagsPreloader />
      <CountryCallingCodeDropdown {...countryCodeProps} />
      <div className="input__mobilephone-number-container">
        <Controller
          control={control}
          name={fieldNameNumber}
          defaultValue=""
          rules={phoneNumberRules}
          render={({ field }) => (
            <InputWithError
              value={field.value}
              onChange={field.onChange}
              errorMessage={mobilephoneErrorMessage}
              {...numberProps}
              {...field}
            />
          )}
        />
      </div>
    </>
  );
};
