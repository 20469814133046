import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { useAlertQueue } from '../../lib/retriable';

export function RetryAlerts() {
  const queue = useAlertQueue();

  return (
    <>
      {queue.map(({ key, onCancel }) => (
        <Dialog key={key} open={true} modalType="alert">
          <DialogSurface>
            <DialogBody>
              <DialogTitle>{t({ id: 'Retriable.Alert.Header' })}</DialogTitle>
              <DialogContent>{t({ id: 'Retriable.Alert.Content' })}</DialogContent>
              <DialogActions>
                <Button appearance="secondary" onCancel={onCancel}>
                  {t({ id: 'Common.Close' })}
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      ))}
    </>
  );
}
