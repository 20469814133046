import { VerificationErrorIcon } from 'shared-fe-components/src/common/Icons';
import { Button } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import * as microsoftTeams from '@microsoft/teams-js';
import { WizardTintedBox } from 'shared-fe-components/src/common/WizardBox';

export const SignNotRequested = () => {
  return (
    <WizardTintedBox color="red">
      <div></div>
      <div>
        <VerificationErrorIcon />
        <h2>{t({ id: 'Signing.Not.Requested.Error.Header' })}</h2>
        <h3>{t({ id: 'Signing.Not.Requested.Error.Guide' })}</h3>
      </div>
      <div>
        <div></div>
        <div>
          <Button onClick={() => microsoftTeams.dialog.url.submit()}>{t({ id: 'Common.Close' })}</Button>
        </div>
      </div>
    </WizardTintedBox>
  );
};
