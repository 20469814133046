import { t } from '@lingui/macro';
import * as microsoftTeams from '@microsoft/teams-js';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { XmlSelectionModal } from 'shared-fe-components/src/common/XmlSelectionModal';
import { DashboardFileSigningChooseFiles } from './DashboardFileSigningChooseFiles';
import { DashboardFileSigningError } from './DashboardFileSigningError';
import { DashboardFileSigningSuccess } from './DashboardFileSigningSuccess';
import { useUploadOnStorage } from 'lib/useUploadOnStorage';
import { Dialog, DialogBody, DialogSurface, Spinner } from '@fluentui/react-components';
import './DashboardFileSigning.scss';

const startAsyncSelfSignTaskOnContainer = async ({ containerId, fileId, fileName }, processName, xadesFormat) => {
  return new Promise((resolve, reject) => {
    const taskInfo = {
      url: `${window.location.origin}/tasks/self-sign-container/${containerId}/${fileId}?initialTheme=dark&processName=${processName}&fileName=${fileName}&xadesFormat=${xadesFormat}`,
      title: t({ id: 'Common.Catchphrase' }),
      size: {
        height: 530,
        width: 600,
      },
    };

    const submitHandler = async ({ err, result }) => {
      if (['back', 'cancel'].includes(result?.submitReason)) {
        resolve({ status: result.submitReason });
      } else if (result?.submitReason === 'success') {
        resolve({ status: result.submitReason, signedFileUrl: result?.signedFileUrl });
      } else if (!!err && err.includes('closed the task')) {
        // user clicked an X
        resolve({ status: 'back' });
      } else {
        // unexpected error
        resolve({ status: 'error', err, result });
      }
    };

    microsoftTeams.dialog.url.open(taskInfo, submitHandler);
  });
};

const startAsyncSelfSignTask = async ({ driveId, folderId, fileId }, processName, xadesFormat) => {
  return new Promise((resolve, reject) => {
    const taskInfo = {
      url: `${window.location.origin}/tasks/self-sign/${driveId}/${folderId}/${fileId}?initialTheme=dark&processName=${processName}&xadesFormat=${xadesFormat}`,
      title: t({ id: 'Common.Catchphrase' }),
      size: {
        height: 530,
        width: 600,
      },
    };

    const submitHandler = async ({ err, result }) => {
      if (['back', 'cancel'].includes(result?.submitReason)) {
        resolve({ status: result.submitReason });
      } else if (result?.submitReason === 'success') {
        resolve({ status: result.submitReason, signedFileUrl: result?.signedFileUrl });
      } else if (!!err && err.includes('closed the task')) {
        // user clicked an X
        resolve({ status: 'back' });
      } else {
        // unexpected error
        resolve({ status: 'error', err, result });
      }
    };

    microsoftTeams.dialog.url.open(taskInfo, submitHandler);
  });
};

export const DashboardFileSigning = ({ onClose }) => {
  const [step, setStep] = useState('ChooseFiles');
  const [signedFileUrl, setSignedFileUrl] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const files = useRef([]);
  const processName = useRef('');
  const [successDocumentName, setSuccessDocumentName] = useState(null);

  const { putItOnStorage } = useUploadOnStorage();

  const onXmlTypeSelect = async (xadesFormat) => {
    setStep('Loader');
    await onSendInToSign(xadesFormat);
  };

  const onSendInToSign = async (xadesFormat) => {
    const isFilesFromDevice = files.current.map((item) => item.teamsFileSource).includes(null);

    const signingResults = [];
    if (isFilesFromDevice) {
      const { container, isError } = await putItOnStorage(files.current);

      if (isError) return setStep('Error');

      for (const file of container.documents) {
        const result = await startAsyncSelfSignTaskOnContainer(
          {
            fileId: file.id,
            containerId: container.id,
            fileName: file.fileName,
          },
          processName.current,
          xadesFormat
        );
        if (result.status === 'back') {
          setStep('ChooseFiles');
          return;
        }
        if (result.status === 'cancel') {
          onClose();
          return;
        }
        signingResults.push({ file, result });
      }
    } else {
      const filesForSigning = files.current.map(({ teamsFileSource, name }) => ({
        ...teamsFileSource,
        filename: name,
      }));

      for (const file of filesForSigning) {
        const result = await startAsyncSelfSignTask(file, processName.current, xadesFormat);
        if (result.status === 'back') {
          setStep('ChooseFiles');
          return;
        }
        if (result.status === 'cancel') {
          onClose();
          return;
        }
        signingResults.push({ file, result });
      }
    }

    if (signingResults.every((x) => x.result.status === 'success')) {
      setSuccessDocumentName(signingResults[0].file.filename);
      setSignedFileUrl(signingResults[0].result.signedFileUrl);
      setStep('Success');
    } else {
      setErrorDescription('');
      setStep('Error');
    }
  };
  const onSignInChooseFiles = async (chosenFiles, chosenProcessName) => {
    files.current = chosenFiles;
    processName.current = chosenProcessName || '';
    setStep('Loader');

    if (files.current[0].name.endsWith('.xml')) {
      setStep('XmlSelection');
    } else {
      await onSendInToSign();
    }
  };

  switch (step) {
    default:
    case 'ChooseFiles':
      return (
        <DashboardFileSigningChooseFiles
          {...{ files: files.current, processName: processName.current, onCancel: onClose, onSignInChooseFiles }}
        />
      );
    case 'XmlSelection':
      return (
        <Dialog open={true}>
          <DialogBody>
            <DialogSurface>
              <XmlSelectionModal onBack={() => setStep('')} onCancel={onClose} onContinue={onXmlTypeSelect} />
            </DialogSurface>
          </DialogBody>
        </Dialog>
      );
    case 'Loader':
      return (
        <WizardBox>
          <div></div>
          <div className="file-signing__loader">
            <Spinner />
          </div>
          <div></div>
        </WizardBox>
      );
    case 'Success':
      return (
        <DashboardFileSigningSuccess
          documentName={successDocumentName}
          onClose={onClose}
          signedFileUrl={signedFileUrl}
        />
      );
    case 'Error':
      return <DashboardFileSigningError {...{ errorDescription, onClose }} />;
  }
};

DashboardFileSigning.propTypes = {
  onClose: PropTypes.func.isRequired,
};
