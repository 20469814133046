import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { useMeetingContext } from 'contexts';
import { State, Action, StageContextType } from './StageContext.types';
import * as api from 'shared-fe-components/src/api';

const stageInitialState: State = {
  signingInProgress: false,
  verificationInProgress: false,
  finished: false,
  signingStatusApiResponse: null,
  verificationStatusApiResponse: null,
  signingStatusSummary: null,
  verificationStatusSummary: null,
  verificationFetchTimestamp: null,
};

const stageReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'IDLE':
      return {
        ...state,
        signingInProgress: false,
        verificationInProgress: false,
        finished: false,
      };
    case 'FINISHED':
      return {
        ...state,
        finished: true,
      };
    case 'SIGNING_STATUS_UPDATE':
      return {
        ...state,
        signingStatusApiResponse: action.signingStatusApiResponse,
        signingStatusSummary: action.signingStatusSummary,
      };
    case 'VERIFICATION_STATUS_UPDATE':
      return {
        ...state,
        verificationFetchTimestamp: action.verificationFetchTimestamp,
        verificationStatusApiResponse: action.verificationStatusApiResponse,
        verificationStatusSummary: action.verificationStatusSummary,
      };
    case 'SIGNING_IN_PROGRESS':
      return {
        ...state,
        signingInProgress: true,
      };
    case 'VERIFICATION_IN_PROGRESS':
      return {
        ...state,
        verificationInProgress: true,
      };
  }
};

const StageContext = createContext<StageContextType | null>(null);

export const StageContextProvider = ({ children }) => {
  const [stageState, stageDispatch] = useReducer(stageReducer, stageInitialState);
  const { meeting } = useMeetingContext() as any;

  const updateSigningStatus = useCallback(
    async (initialUpdate = false) => {
      const sessions = await api.meeting.getSigningSessions(meeting.id);
      const pendingSessions = sessions.filter((session) => ['NotStarted', 'InProgress'].includes(session?.status));
      const statuses = [...new Set(pendingSessions.map((session) => session.signatures).flat())];
      const summary = {
        pending: statuses?.filter((x: any) => !['Succeeded', 'Failed'].includes(x.status)).length,
        succeeded: statuses?.filter((x: any) => x.status === 'Succeeded').length,
        failed: statuses?.filter((x: any) => x.status === 'Failed').length,
      };
      stageDispatch({
        type: 'SIGNING_STATUS_UPDATE',
        signingStatusApiResponse: sessions,
        signingStatusSummary: summary,
      });

      const pendingSessionsExist = pendingSessions.length > 0;
      if (!initialUpdate && !pendingSessionsExist) {
        stageDispatch({ type: 'FINISHED' });
      }

      return pendingSessionsExist;
    },
    [meeting.id]
  );

  const updateVerificationStatus = useCallback(
    async (initialUpdate = false) => {
      const session = await api.meeting.getVerificationSession(meeting.id);
      const verifications = session?.verifications ?? [];
      const summary = {
        pending: verifications.filter((x: any) => !['Succeeded', 'Failed'].includes(x.status)).length,
        succeeded: verifications.filter((x: any) => x.status === 'Succeeded').length,
        failed: verifications.filter((x: any) => x.status === 'Failed').length,
      };
      stageDispatch({
        type: 'VERIFICATION_STATUS_UPDATE',
        verificationStatusApiResponse: session,
        verificationStatusSummary: summary,
        verificationFetchTimestamp: new Date(),
      });

      const pendingSessionsExist = session?.status === 'InProgress';
      if (!initialUpdate && !pendingSessionsExist) {
        stageDispatch({ type: 'FINISHED' });
      }

      return pendingSessionsExist;
    },
    [meeting.id]
  );

  useEffect(() => {
    Promise.all([updateVerificationStatus(true), updateSigningStatus(true)]).then(
      ([verificationStarted, signingStarted]) => {
        if (verificationStarted) {
          stageDispatch({ type: 'VERIFICATION_IN_PROGRESS' });
        } else if (signingStarted) {
          stageDispatch({ type: 'SIGNING_IN_PROGRESS' });
        } else {
          stageDispatch({ type: 'IDLE' });
        }
      }
    );
  }, [updateSigningStatus, updateVerificationStatus]);

  useEffect(() => {
    if (stageState.signingInProgress && !stageState.finished) {
      updateSigningStatus();
      const interval = setInterval(updateSigningStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [updateSigningStatus, stageState.signingInProgress, stageState.finished]);

  useEffect(() => {
    if (stageState.verificationInProgress && !stageState.finished) {
      updateVerificationStatus();
      const interval = setInterval(updateVerificationStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [updateVerificationStatus, stageState.verificationInProgress, stageState.finished]);

  return (
    <StageContext.Provider value={{ stageState, stageDispatch, updateSigningStatus, updateVerificationStatus }}>
      {children}
    </StageContext.Provider>
  );
};

export const useStageContext = () => {
  const ctx = useContext(StageContext);
  if (!ctx) {
    throw new Error('Lack of StageProvider in components tree');
  }
  return ctx;
};
