import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import {
  Table,
  Text,
  Spinner,
  Button,
  Input,
  TableHeader,
  TableHeaderCell,
  useTableFeatures,
  useTableSort,
  TableBody,
  TableCellLayout,
  TableCell,
  TableRow,
  Link,
} from '@fluentui/react-components';
import {
  Filter24Regular,
  Search24Regular,
  Edit24Regular,
  ArrowClockwise24Regular,
  Warning16Regular,
  Document24Regular,
} from '@fluentui/react-icons';
import { Alert } from '@fluentui/react-components/unstable';
import { useDateFormatter } from '../../../lib/useDateFormatter';
import { useSigningSessionFiles } from '../../../lib/useSigningSessionFiles';
import { useThrottledState } from '../../../lib/useThrottledState';
import { useDebouncedState } from '../../../lib/useDebouncedState';
import { MenuTabs } from './MenuTabs';
import {
  columns,
  statuses,
  dayAndHourFormatterOptions,
  dayFormatterOptions,
  mainFilterStatuses,
  columnsDetails,
  config,
} from './dashboardTableData';
import { ProcessDetails } from '../ProcessDetails/ProcessDetails';
import { useUserContext } from 'contexts';
import { DashboardTableFilters } from './DashboardTableFilters/DashboardTableFilters';
import { FilesIcon } from 'shared-fe-components/src/common/FilesIcon';
import { extractFileFormat } from 'shared-fe-components/src/utils';
import { configurationProviders } from 'shared-fe-components/src/api';
import { useInfiniteScroll } from 'shared-fe-components/src/hooks';
import './DashboardTable.scss';
import { FolderIcon } from 'shared-fe-components/src/common/Icons';

const buildNameCell = (name, fileName, documentCountInSession) => {
  const label = name || fileName;
  const icon =
    documentCountInSession > 1 ? (
      <FolderIcon className="dashboard-table__name-icon" />
    ) : (
      <FilesIcon className="dashboard-table__name-icon" fileType={extractFileFormat(fileName)} />
    );

  return {
    content: (
      <div className="dashboard-table__name-container">
        {icon}
        <Text title={label}>{label}</Text>
      </div>
    ),
    key: 'Name',
    className: 'dashboard-table__table-Name-cell',
    label: label,
  };
};

const buildStatusCell = (status) => {
  const { title, className } = statuses.filter(({ value }) => value === status)?.[0] ?? { title: '', class: '' };
  return {
    content: <Text className={className}>{t({ id: title })}</Text>,
    key: 'Status',
  };
};

const buildFlowBeginningDateCell = (formattedFlowBeginningDate, flowBeginningDate) => ({
  content: formattedFlowBeginningDate,
  key: 'FlowBeginningDate',
  date: flowBeginningDate,
});

const buildExpirationTimeCell = (formattedExpirationTime, expirationTime) => ({
  content: formattedExpirationTime,
  style: {
    justifyContent: 'center',
  },
  key: 'ExpirationTime',
  date: expirationTime,
});

const buildFlowEndingDateCell = (formattedFlowEndingDate, flowEndingDate) => ({
  content: formattedFlowEndingDate,
  key: 'FlowEndingDate',
  date: flowEndingDate,
});

const buildDetailsCell = ({
  sessionId,
  name,
  fileName,
  signingsInfo,
  flowBeginningDate,
  flowEndingDate,
  expirationTime,
  sender,
  status,
  signatureType,
  user,
  onSigningCancel,
  onMadeChanges,
  onEditSignatureClick,
  meetingId,
  providerNames,
}) => {
  const startDate = flowBeginningDate ? new Date(flowBeginningDate) : null;
  const endDateValue = flowEndingDate || null;
  const endDate = endDateValue ? new Date(endDateValue) : null;
  const people = (signingsInfo ?? []).map(({ userName, userId, status, signingTime }) => {
    const signedAt = signingTime ? new Date(signingTime) : null;
    const signingTimeAfterStatusCheck = ['Succeeded'].includes(status) ? signedAt : null;
    return {
      name: userName,
      status,
      signedAt: signingTimeAfterStatusCheck,
    };
  });

  const processDetailsProps = {
    sessionId,
    people,
    startDate,
    expirationTime: expirationTime ? new Date(expirationTime) : null,
    endDate,
    canCancel: status === 'Waiting' && sender.email === user.email,
    onCancel: onSigningCancel,
    fileName,
    name: name || fileName,
    status,
    onMadeChanges,
    signatureType,
    meetingId,
    onEditSignatureClick,
    providerNames,
  };

  return {
    content: (
      <div className="dashboard-table__details">
        <ProcessDetails {...processDetailsProps} />
        {!['Signed', 'Expired', 'Rejected', 'Cancelled', 'Finished', 'NotSigned'].includes(status) &&
          signatureType !== 'SelfSign' &&
          !meetingId && (
            <Link
              onClick={() => onEditSignatureClick(sessionId)}
              title={t({ id: 'Dashboard.Table.Edit.Signature' })}
              style={{ marginLeft: '12px' }}
            >
              <Edit24Regular />
            </Link>
          )}
      </div>
    ),
    key: 'Details',
  };
};

export const DashboardTable = ({ onEditSignatureClick }) => {
  const [searchPhrase, setSearchPhrase] = useDebouncedState(null, 500);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [, secondaryFilters, setSecondaryFilters] = useThrottledState(() => [], 500);
  const [, mainFilter, setMainFilter] = useThrottledState(() => undefined, 500);
  const [, dateFilterRanges, setDateFilterRanges] = useThrottledState(() => undefined, 500);
  const [providerNames, setProviderNames] = useState({});

  const dayAndHourFormatter = useDateFormatter(dayAndHourFormatterOptions);
  const dayFormatter = useDateFormatter(dayFormatterOptions);
  const { user } = useUserContext();

  const params = useMemo(
    () => ({ ...config, mainFilter, secondaryFilters, dateFilterRanges, searchPhrase }),
    [mainFilter, secondaryFilters, dateFilterRanges, searchPhrase]
  );

  const { list, loading, hasMore, load, loadMore, retry, errorOrigin } = useSigningSessionFiles(params);

  const onMadeChanges = useCallback(() => {
    load(params);
  }, [load, params]);

  useEffect(() => {
    (async () => {
      const providersCountrySigning = await configurationProviders.getProvidersCountrySigning();
      setProviderNames(providersCountrySigning?.names);
    })();
  }, []);

  const items = useMemo(() => {
    const rows = list?.map(
      ({
        id,
        name,
        sender,
        fileName,
        status,
        flowBeginningDate,
        expirationTime,
        flowEndingDate,
        signingsInfo,
        archived,
        signatureType,
        meetingId,
        documentCountInSession,
      }) =>
        ({
          name: buildNameCell(name, fileName, documentCountInSession),
          status: buildStatusCell(status),
          flowBeginningDate: buildFlowBeginningDateCell(
            flowBeginningDate ? dayAndHourFormatter(new Date(flowBeginningDate)) : null,
            flowBeginningDate ? new Date(flowBeginningDate) : null
          ),
          expirationTime: buildExpirationTimeCell(
            expirationTime ? dayFormatter(new Date(expirationTime)) : <span>&mdash;</span>,
            expirationTime ? new Date(expirationTime) : null
          ),
          flowEndingDate: buildFlowEndingDateCell(
            flowEndingDate ? dayAndHourFormatter(new Date(flowEndingDate)) : null,
            flowEndingDate ? new Date(flowEndingDate) : null
          ),
          details: buildDetailsCell({
            sessionId: id,
            name,
            fileName,
            signingsInfo,
            flowBeginningDate,
            flowEndingDate,
            expirationTime,
            sender,
            status,
            user,
            archived,
            onMadeChanges,
            onEditSignatureClick,
            signatureType,
            meetingId,
            providerNames,
          }),
        } ?? [])
    );
    return rows;
  }, [dayAndHourFormatter, dayFormatter, list, onMadeChanges, user]);

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'flowBeginningDate', sortDirection: 'ascending' },
      }),
    ]
  );

  const rows = sort(getRows());

  const onSearchInput = useCallback(
    (event) => {
      setSearchPhrase(event.target.value);
    },
    [setSearchPhrase]
  );

  const menuTabs = mainFilterStatuses.map(({ title, key }) => ({ content: t({ id: title }), key }));
  const defaultMenuTabKey = menuTabs.find(({ key }) => key === 'All')?.key;

  const headerSortProps = (columnId) => ({
    onClick: (e) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const { isLoading, triggerRef } = useInfiniteScroll(loadMore, !errorOrigin && hasMore);

  return (
    <>
      <DashboardTableFilters
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onFiltersChange={setSecondaryFilters}
        onDateRangesChange={setDateFilterRanges}
        activeFilterCount={setActiveFilterCount}
        activeDateRange={dateFilterRanges}
        activeFilters={secondaryFilters}
      />
      <div className="dashboard-table">
        <div className="dashboard-table__header-wrapper">
          <Text as="h1" className="dashboard-table__header">
            <Document24Regular className="dashboard-table__header-icon" /> {t({ id: 'Dashboard.Table.Header' })}
          </Text>
          <div className="dashboard-table__header-filters-wrapper">
            <Button appearance="transparent" icon={<Filter24Regular />} onClick={() => setIsFilterOpen(true)}>
              <Text>{t({ id: 'Dashboard.Table.Filter.Header' })}</Text>{' '}
              {activeFilterCount > 0 && `(${activeFilterCount})`}
            </Button>
          </div>
          <Input
            size="small"
            appearance="outline"
            onChange={onSearchInput}
            placeholder={t({ id: 'Dashboard.Table.Search.Placeholder' })}
            contentAfter={<Search24Regular />}
            className="dashboard-table__search-input"
          />
        </div>

        <MenuTabs items={menuTabs} defaultSelectedValue={defaultMenuTabKey} onChange={setMainFilter} />

        <div className="dashboard-table__table-container">
          <Table sortable className="dashboard-table__table">
            <TableHeader>
              <TableRow>
                {columnsDetails.map(({ sortable, name, title }) =>
                  sortable ? (
                    <TableHeaderCell key={name} {...headerSortProps(name)}>
                      {t({ id: title })}
                    </TableHeaderCell>
                  ) : (
                    <TableHeaderCell key={name}>{t({ id: title })}</TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {rows.length > 0 &&
                rows.map(({ item }, index) => (
                  <TableRow ref={rows.length === index + 1 ? triggerRef : undefined} key={`row-${index}`}>
                    <TableCell>
                      <TableCellLayout>{item.name.content}</TableCellLayout>
                    </TableCell>
                    <TableCell>
                      <TableCellLayout>{item.status.content}</TableCellLayout>
                    </TableCell>
                    <TableCell>
                      <TableCellLayout>{item.flowBeginningDate.content}</TableCellLayout>
                    </TableCell>
                    <TableCell>
                      <TableCellLayout>{item.expirationTime.content}</TableCellLayout>
                    </TableCell>
                    <TableCell>
                      <TableCellLayout>{item.flowEndingDate.content}</TableCellLayout>
                    </TableCell>
                    <TableCell>
                      <TableCellLayout>{item.details.content}</TableCellLayout>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        {loading && <Spinner />}
        {!loading && errorOrigin && (
          <Alert
            intent="error"
            icon={<Warning16Regular />}
            action={
              <Button appearance="transparent" icon={<ArrowClockwise24Regular />} onClick={retry}>
                {t({ id: 'Dashboard.Table.TryAgain' })}
              </Button>
            }
          >
            {t({ id: 'Dashboard.Table.FailedToLoad' })}
          </Alert>
        )}
        {isLoading && <Spinner />}
        {!loading && !errorOrigin && !hasMore && rows.length === 0 && (
          <div className="dashboard-table__no-documents-in-history">
            {t({ id: 'Dashboard.Table.NoDocumentsInHistory' })}
          </div>
        )}
      </div>
    </>
  );
};
