import { RetryAlerts } from './RetryAlerts';
import { RetryPrompts } from './RetryPrompts';

export function RetryDialogs() {
  return (
    <>
      <RetryAlerts />
      <RetryPrompts />
    </>
  );
}
