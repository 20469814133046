import { Button, Spinner, Text } from '@fluentui/react-components';
import { ArrowForward24Regular, TimerOff24Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { useCallback, useEffect, useState } from 'react';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { useMszafirPopup } from './useMszafirPopup';
import { LoadingScreen } from '../../Common/LoadingScreen';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { MszafirEmail } from 'shared-fe-components/src/providers/MSzafir';
import { useSignTaskContext } from '../SignTaskContext';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import 'shared-fe-components/src/providers/MSzafir/Mszafir.scss';

export const MszafirSign = ({ additionalParameters, onBack, onCancel }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { open, isOpen } = useMszafirPopup();
  const [popupShouldBeOpen, setPopupShouldBeOpen] = useState(false);
  const { sign, getSignatureStatus } = useSignTaskContext();

  const onOpenMszafirClick = useCallback(() => {
    if (data?.providerResponse?.hAuth) {
      open(data.providerResponse.hAuth);

      setPopupShouldBeOpen(true);
    }
  }, [open, data]);

  // start signing effect
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await sign({ additionalParameters });
      setData(response);
      setLoading(false);
    })();
  }, [additionalParameters, sign]);

  // status refetching effect
  useEffect(() => {
    const signStatusInterval = setInterval(async () => {
      if (!loading) {
        await getSignatureStatus({});
      }
    }, 5000);
    return () => clearInterval(signStatusInterval);
  }, [getSignatureStatus, loading]);

  if (loading) {
    return <LoadingScreen />;
  } else if (popupShouldBeOpen) {
    return (
      <WizardBox className="mszafir-window">
        <div></div>
        <div className="mszafir-window__sign-container">
          {isOpen ? (
            <Spinner />
          ) : (
            <>
              <TimerOff24Regular className="mszafir-window__sign-container-icon" />
              <div className="mszafir-window__sign-container-text-wrapper">
                <h1 className="mszafir-window__sign-container-header">
                  {t({ id: 'Providers.Mszafir.SessionClosed.Title' })}
                </h1>
                <Text size={400}>{t({ id: 'Providers.Mszafir.SessionClosed.Text' })}</Text>
              </div>
            </>
          )}
        </div>
        <div>
          <div></div>
          <Button appearance="primary" disabled={isOpen} onClick={onBack}>
            {t({ id: 'Providers.Mszafir.SessionClosed.Button' })}
          </Button>
        </div>
      </WizardBox>
    );
  } else {
    return (
      <WizardBox className="mszafir-window">
        <div></div>
        <div className="mszafir-window__sign-container">
          <ArrowForward24Regular className="mszafir-window__sign-container-icon" />
          <div className="mszafir-window__sign-container-text-wrapper">
            <h1 className="mszafir-window__sign-container-header">{t({ id: 'Providers.Mszafir.Redirect.Title' })}</h1>
            <Text size={400}>{t({ id: 'Providers.Mszafir.Redirect.Description' })}</Text>
            <Text size={400}>
              <Trans
                id="Providers.Mszafir.Redirect.Description.Footer"
                values={{ email: MszafirEmail }}
                components={[<Anchor href={`mailto:${MszafirEmail?.toLowerCase()}`} />]}
              />
            </Text>
          </div>
        </div>
        <div>
          <div>
            <ButtonBack onClick={onBack} />
          </div>
          <div>
            <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
            <Button appearance="primary" onClick={onOpenMszafirClick}>
              {t({ id: 'Common.Proceed' })}
            </Button>
          </div>
        </div>
      </WizardBox>
    );
  }
};
