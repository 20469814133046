import { useState, useEffect } from "react";

let counter = 0;
let pendingDialogs = [];
let setters = [];

function register(setter) {
  setters = [...setters, setter];

  setter(pendingDialogs);
}

function unregister(setter) {
  setters = setters.filter((item) => item !== setter);
}

function notifyAll(value) {
  setters.forEach((setter) => setter(value));
}

function teardown(key) {
  pendingDialogs = pendingDialogs.filter((value) => value.key !== key);

  notifyAll(pendingDialogs);
}

function enqueue(resolve) {
  const key = counter++;

  pendingDialogs = [
    ...pendingDialogs,
    {
      key,
      onConfirm() {
        teardown(key);

        resolve(true);
      },
      onCancel() {
        teardown(key);

        resolve(false);
      },
    },
  ];

  notifyAll(pendingDialogs);
}

export function prompt() {
  return new Promise(enqueue);
}

export function usePromptQueue() {
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    register(setQueue);

    return () => unregister(setQueue);
  }, []);

  return queue;
}
