import { useState, useRef, useCallback, useEffect } from 'react';
import { useConfigurationContext } from 'contexts';

const isPopupOpen = (popupRef) => !!popupRef.current && !popupRef.current.closed;

export const useMszafirPopup = () => {
  const [watchIsOpen, setWatchIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const popupRef = useRef();
  const { providers: providersConfiguration } = useConfigurationContext();

  const close = useCallback(() => {
    if (isPopupOpen(popupRef)) {
      popupRef.current.close();
      popupRef.current = null;
    }

    setWatchIsOpen(false);
  }, []);

  const open = useCallback((token) => {
    close();

    setWatchIsOpen(true);

    const url = new URL('/mszafir-signing.html', window.location.origin);
    url.searchParams.set('token', token);
    url.searchParams.set('url', providersConfiguration.mszafir.authorizationEndpoint);

    popupRef.current = window.open(url, 'mSzafir');
  }, [close]);

  useEffect(() => {
    window.addEventListener('beforeunload', close, false);

    return () => {
      window.removeEventListener('beforeunload', close);

      close();
    };
  }, [close]);

  useEffect(() => {
    if (!watchIsOpen) {
      return;
    }

    const interval = setInterval(() => {
      setIsOpen(isPopupOpen(popupRef));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [watchIsOpen]);

  return { isOpen, open };
};
