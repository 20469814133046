import { useState } from 'react';
import { Button, Text } from 'shared-fe-components/src/common/FluentMigration';
import { useMeetingContext, useLocalizationContext } from 'contexts';
import { t } from '@lingui/macro';
import { useSidePanelContext } from '../SidePanelContext';
import * as api from 'shared-fe-components/src/api';
import { OrganizerGuard } from '../../Utils';
import { Checkbox, Slider } from '@fluentui/react-components';

export const Intro = () => {
  const { sidePanelState, sidePanelDispatch, shareToStage } = useSidePanelContext();
  const [error, setError] = useState<string | null>(null);
  const [saveVideo, setSaveVideo] = useState<boolean>(false);
  const [videoDurationMinValue, videoDurationMaxValue] = [5, 20];
  const [videoDuration, setVideoDuration] = useState<number>(videoDurationMinValue);

  const { meeting }: any = useMeetingContext();
  const { locale }: any = useLocalizationContext();

  const startVerification = async () => {
    setError(null);
    sidePanelDispatch({ type: 'VERIFICATION_BUSY' });

    try {
      const session = await api.meeting.getVerificationSession(meeting.id);
      if (!session) {
        await api.verification.requestVerification({
          meetingId: meeting.id,
          culture: locale,
          videoDuration: saveVideo ? videoDuration : null,
        });
      }
      await api.meeting.startVerification(meeting.id);
      sidePanelDispatch({ type: 'VERIFICATION_IN_PROGRESS' });
      shareToStage();
    } catch (err) {
      console.error(err);
      const msg = err?.response?.status;
      setError(t({ id: 'Meeting.SidePanel.Verification.Start.Error', values: { error: msg } }));
      sidePanelDispatch({ type: 'IDLE' });
    }
  };

  const onSliderChange = (_, data: any) => {
    setVideoDuration(data.value);
  };

  return (
    <div className="side-panel__intro">
      <OrganizerGuard>
        <Text className="side-panel__section-title" weight="bold">
          {t({ id: 'Meeting.SidePanel.Verification.Header' })}
        </Text>
        <Text>{t({ id: 'Meeting.SidePanel.Verification.Guide' })}</Text>
        <div className={`side-panel__verification-video ${saveVideo ? 'side-panel__verification-video--open' : ''}`}>
          <Checkbox
            checked={saveVideo}
            onChange={() => setSaveVideo((v) => !v)}
            label={t({ id: 'Meeting.SidePanel.Verification.Record.Checkbox' })}
            className="side-panel__verification-video-checkbox"
          />
          {saveVideo && (
            <>
              <Slider
                min={videoDurationMinValue}
                max={videoDurationMaxValue}
                value={videoDuration}
                onChange={onSliderChange}
              />
              <Text>{t({ id: 'Meeting.SidePanel.Verification.Record.SliderTitle', values: { videoDuration } })}</Text>
            </>
          )}
        </div>
        <Button primary onClick={startVerification} {...sidePanelState.startVerificationButtonProps}>
          {t({ id: 'Meeting.SidePanel.Verification.Start' })}
        </Button>
        {error && (
          <Text error role="alert">
            {error}
          </Text>
        )}
      </OrganizerGuard>
    </div>
  );
};
