import { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Label,
  Button,
  Input,
} from '@fluentui/react-components';
import { Text } from 'shared-fe-components/src/common/FluentMigration';
import {
  Building20Regular,
  Info20Regular,
  Person20Regular,
  PersonEdit20Regular,
  Eye20Regular,
  ChevronDown20Regular,
  ChevronUp20Regular,
} from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import {
  CertificateIcon,
  IdIcon,
  StatusFailedIcon,
  StatusPendingIcon,
  StatusSuccessIcon,
  OcrIcon,
} from 'shared-fe-components/src/common/Icons';
import { useMeetingContext } from 'contexts';
import { useDateFormatter } from 'lib/useDateFormatter';
import './VerificationSummaryTable.scss';

const dateFormatterOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const VerificationSummaryTable = ({ verifications, expandDetails = false }) => {
  const rows = useMemo(
    () => verifications.map((v) => <Row key={v.id} verification={v} expandDetails={expandDetails} />),
    [verifications, expandDetails]
  );

  return (
    <div className="verification-summary-table">
      <TableHeader />
      {rows}
    </div>
  );
};

const TableHeader = () => {
  return (
    <>
      <div className="verification-summary-table__header">
        <Person20Regular />
        <span>{t({ id: 'VerificationSummaryTable.Header.MeetingIdentity' })}</span>
      </div>
      <div className="verification-summary-table__header">
        <CertificateIcon />
        <span>{t({ id: 'VerificationSummaryTable.Header.VerificationStatus' })}</span>
      </div>
      <div className="verification-summary-table__header">
        <Building20Regular />
        <span>{t({ id: 'VerificationSummaryTable.Header.Origin' })}</span>
      </div>
      <div className="verification-summary-table__header">
        <IdIcon />
        <span>{t({ id: 'VerificationSummaryTable.Header.VerifiedIdentity' })}</span>
      </div>
      <div className="verification-summary-table__header">
        <Eye20Regular />
        <span>{t({ id: 'VerificationSummaryTable.Header.DetailedInformation' })}</span>
      </div>
    </>
  );
};

const Row = ({ verification, expandDetails }) => {
  const { user } = useMeetingContext();
  const organizer = user.meetingRole === 'Organizer';
  const [expandSingleDetail, setExpandSingleDetail] = useState(false);
  const canExpandDetails = useMemo(() => {
    if (!organizer) return false;
    if (verification?.status !== 'Succeeded') return false;
    if (verification?.personalInfo?.documentData === null) return false;

    return true;
  }, [organizer, verification?.status, verification?.personalInfo?.documentData]);

  useEffect(() => {
    expandDetails ? setExpandSingleDetail(true) : setExpandSingleDetail(false);
  }, [expandDetails]);

  return (
    <>
      <div className="verification-summary-table__row verification-summary-table__name">
        <CellName name={verification?.subject?.name} />
      </div>
      <div className="verification-summary-table__row verification-summary-table__status">
        <CellStatus status={verification?.status} />
        {organizer && <CellStatusError verification={verification} />}
      </div>
      <div className="verification-summary-table__row">
        <CellOrigin verification={verification} />
      </div>
      <div className="verification-summary-table__row">
        <CellVerifiedName verification={verification} />
      </div>
      <div className="verification-summary-table__row">
        <CellExpandData
          canExpandDetails={canExpandDetails}
          expandSingleDetail={expandSingleDetail}
          expandDetails={expandDetails}
          onExpandSingleDetail={() => setExpandSingleDetail((v) => !v)}
        />
      </div>
      {canExpandDetails && (expandDetails || expandSingleDetail) && <Details verification={verification} />}
    </>
  );
};

const Details = ({ verification }) => {
  const dateFormatter = useDateFormatter(dateFormatterOptions);
  const authenticationDate = verification.authenticationDate
    ? dateFormatter(new Date(verification.authenticationDate))
    : null;
  const photo = verification?.personalInfo?.photo;
  const firstName = verification?.personalInfo?.documentData?.firstName;
  const lastName = verification?.personalInfo?.documentData?.lastName;
  const personalNumber = verification?.personalInfo?.documentData?.personalNumber;
  const status = verification?.status;
  const ocrComparisonData = verification?.personalInfo?.userProvidedData;
  const photoAlt = t({ id: 'VerificationSummaryTable.Details.Photo' });

  const areValuesMatching =
    ocrComparisonData &&
    firstName === ocrComparisonData?.firstName &&
    lastName === ocrComparisonData?.lastName &&
    personalNumber === ocrComparisonData?.personalNumber;

  console.log(areValuesMatching, 'areValuesMatching');
  console.log(personalNumber, 'comp');
  console.log(ocrComparisonData?.personalNumber, 'ocr comps');

  return (
    <div className="verification-summary-table__full-row">
      <div className="verification-summary-table__details">
        {photo && (
          <div className="verification-summary-table__details-photo">
            <img src={`data:image/png;base64, ${photo}`} alt={photoAlt} />
          </div>
        )}
        <div className="verification-summary-table__details-data">
          <Detail name="FirstName" value={firstName} />
          <Detail name="LastName" value={lastName} />
          <Detail name="PersonalNumber" value={personalNumber} />
          <Detail name="AuthenticationDate" value={authenticationDate} />
        </div>
        {!!ocrComparisonData &&
          Object.keys(ocrComparisonData).length > 0 &&
          status === 'Succeeded' &&
          !areValuesMatching && (
            <div className="verification-summary-table-ocr-comparison__wrapper">
              <Accordion collapsible>
                <AccordionItem value="ocr-comparison">
                  <AccordionHeader expandIconPosition="end">
                    <Text size={400}>{t({ id: 'VerificationSummaryTable.Ocr.Comparison.Success.Header' })}</Text>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Text className="verification-summary-table-ocr-comparison__description" size={400}>
                      {t({ id: 'VerificationSummaryTable.Ocr.Comparison.Description' })}
                    </Text>
                    <div className="verification-summary-table-ocr-comparison__data-container">
                      <div className="verification-summary-table-ocr-comparison-data">
                        <div className="verification-summary-table-ocr-comparison-data__header">
                          <OcrIcon />
                          <Text>{t({ id: 'VerificationSummaryTable.Ocr.Comparison.Read.Data.Header' })}</Text>
                        </div>
                        {ocrComparisonData.firstName !== firstName && (
                          <div className="verification-summary-table-ocr-comparison-data__input-container">
                            <Label htmlFor="firstNameComparision">
                              {t({ id: 'VerificationSummaryTable.Details.FirstName' })}
                            </Label>
                            <Input
                              value={firstName || ''}
                              disabled={true}
                              appearance="underline"
                              id="firstNameComparision"
                              contentAfter={<Info20Regular />}
                            />
                          </div>
                        )}
                        {ocrComparisonData.lastName !== lastName && (
                          <div className="verification-summary-table-ocr-comparison-data__input-container">
                            <Label htmlFor="lastNameComparision">
                              {t({ id: 'VerificationSummaryTable.Details.LastName' })}
                            </Label>
                            <Input
                              value={lastName || ''}
                              disabled={true}
                              appearance="underline"
                              id="lastNameComparision"
                              contentAfter={<Info20Regular />}
                            />
                          </div>
                        )}
                        {ocrComparisonData?.personalNumber !== personalNumber && (
                          <div className="verification-summary-table-ocr-comparison-data__input-container">
                            <Label htmlFor="underlineId">
                              {t({ id: 'VerificationSummaryTable.Details.PersonalNumber' })}
                            </Label>
                            <Input
                              value={personalNumber || ''}
                              disabled={true}
                              appearance="underline"
                              id="underlineI"
                              contentAfter={<Info20Regular />}
                            />
                          </div>
                        )}
                      </div>
                      <div className="verification-summary-table-ocr-comparison-data">
                        <div className="verification-summary-table-ocr-comparison-data__header">
                          <PersonEdit20Regular />
                          <Text size={400}>
                            {t({ id: 'VerificationSummaryTable.Ocr.Comparison.Corrected.Data.Header' })}
                          </Text>
                        </div>
                        {ocrComparisonData.firstName !== firstName && (
                          <div className="verification-summary-table-ocr-comparison-data__input-container">
                            <Label htmlFor="firstNameUser">
                              {t({ id: 'VerificationSummaryTable.Details.FirstName' })}
                            </Label>
                            <Input
                              value={ocrComparisonData.firstName || ''}
                              disabled={true}
                              appearance="underline"
                              id="firstNameUser"
                              contentAfter={<Info20Regular />}
                            />
                          </div>
                        )}
                        {ocrComparisonData.lastName !== lastName && (
                          <div className="verification-summary-table-ocr-comparison-data__input-container">
                            <Label htmlFor="lastNameUser">
                              {t({ id: 'VerificationSummaryTable.Details.LastName' })}
                            </Label>
                            <Input
                              value={ocrComparisonData.lastName || ''}
                              disabled={true}
                              appearance="underline"
                              id="lastNameUser"
                              contentAfter={<Info20Regular />}
                            />
                          </div>
                        )}
                        {ocrComparisonData?.personalNumber !== personalNumber && (
                          <div className="verification-summary-table-ocr-comparison-data__input-container">
                            <Label htmlFor="personalNumberUser">
                              {t({ id: 'VerificationSummaryTable.Details.PersonalNumber' })}
                            </Label>
                            <Input
                              value={ocrComparisonData?.personalNumber || ''}
                              disabled={true}
                              appearance="underline"
                              id="personalNumberUser"
                              contentAfter={<Info20Regular />}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>
          )}
      </div>
    </div>
  );
};

const Detail = ({ name, value }: { name: string; value: string }) => {
  return (
    <div className="verification-summary-table__detail">
      <Text weight="bold">{t({ id: `VerificationSummaryTable.Details.${name}` })}</Text>
      {value && <Text>{value}</Text>}
      {!value && <Text disabled>{t({ id: 'Common.NoData' })}</Text>}
    </div>
  );
};

const CellName = ({ name }: { name: string }) => {
  if (name) {
    return (
      <>
        <Avatar name={name} />
        <Text>{name}</Text>
      </>
    );
  }

  return <Text disabled>{t({ id: 'Common.NoData' })}</Text>;
};

const CellStatus = ({ status }: { status: string }) => {
  if (status === 'Succeeded') {
    return (
      <>
        <span className="verification-summary-table__status--success verification-summary-table__status--icon">
          <StatusSuccessIcon />
        </span>
        <span className="verification-summary-table__status--success">
          {t({ id: 'VerificationSummaryTable.Status.Succeeded' })}
        </span>
      </>
    );
  } else if (status === 'Failed') {
    return (
      <>
        <span className="verification-summary-table__status--failed verification-summary-table__status--icon">
          <StatusFailedIcon />
        </span>
        <span className="verification-summary-table__status--failed">
          {t({ id: 'VerificationSummaryTable.Status.Failed' })}
        </span>
      </>
    );
  } else if (status === 'Rejected') {
    return (
      <>
        <span className="verification-summary-table__status--failed verification-summary-table__status--icon">
          <StatusFailedIcon />
        </span>
        <span className="verification-summary-table__status--failed">
          {t({ id: 'VerificationSummaryTable.Status.Rejected' })}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span className="verification-summary-table__status--pending verification-summary-table__status--icon">
          <StatusPendingIcon />
        </span>
        <span className="verification-summary-table__status--pending">
          {t({ id: 'VerificationSummaryTable.Status.Pending' })}
        </span>
      </>
    );
  }
};

const CellStatusError = ({ verification }) => {
  const errorCode = verification?.operationState?.errors?.friendlyErrorCode;
  if (verification.status !== 'Failed' || !errorCode) {
    return <></>;
  }

  return (
    <Popover withArrow positioning="after">
      <PopoverTrigger>
        <Info20Regular />
      </PopoverTrigger>
      <PopoverSurface>
        <div className="verification-summary-table__popover-surface">
          {t({ id: `StatusScreen.Error.Code.${errorCode}` })}
        </div>
      </PopoverSurface>
    </Popover>
  );
};

const CellExpandData = ({
  canExpandDetails,
  onExpandSingleDetail,
  expandSingleDetail,
  expandDetails,
}: {
  canExpandDetails: boolean;
  expandSingleDetail: boolean;
  expandDetails: boolean;
  onExpandSingleDetail: () => void;
}) => {
  return (
    <>
      {canExpandDetails ? (
        <div>
          {expandSingleDetail ? (
            <Button
              disabled={expandDetails}
              icon={<ChevronUp20Regular />}
              iconPosition="after"
              onClick={onExpandSingleDetail}
            >
              {t({ id: 'VerificationSummaryTable.Details' })}
            </Button>
          ) : (
            <Button
              disabled={expandDetails}
              icon={<ChevronDown20Regular />}
              iconPosition="after"
              onClick={onExpandSingleDetail}
            >
              {t({ id: 'VerificationSummaryTable.Details' })}
            </Button>
          )}
        </div>
      ) : (
        <Text>{t({ id: 'VerificationSummaryTable.Details.NoData' })}</Text>
      )}
    </>
  );
};

const CellOrigin = ({ verification }) => {
  const value =
    verification?.subject?.origin || verification?.subject?.meetingParticipant?.teamsMeetingParticipant?.origin;
  if (['External', 'Guest', 'Internal'].includes(value)) {
    return <Text>{t({ id: `VerificationSummaryTable.Origin.${value}` })}</Text>;
  } else if (value) {
    return <Text>{value}</Text>;
  }

  return <Text disabled>{t({ id: 'Common.NoData' })}</Text>;
};

const CellVerifiedName = ({ verification }) => {
  const firstName = verification?.personalInfo?.documentData?.firstName;
  const lastName = verification?.personalInfo?.documentData?.lastName;
  if (firstName && lastName) {
    return <Text>{`${firstName} ${lastName}`}</Text>;
  }

  return <Text disabled>{t({ id: 'Common.NoData' })}</Text>;
};
