import { useMemo } from 'react';
import { Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { StatusCounters } from '../StatusCounters/StatusCounters';
import { useSidePanelContext } from '../SidePanelContext';

export const Dashboard = () => {
  const { sidePanelState } = useSidePanelContext();

  const statusCountersValues = useMemo(() => {
    const { pending, succeeded, failed } = sidePanelState.signingStatusSummary;
    const rawValues = [
      {
        dict: 'Meeting.SidePanel.Status.Pending',
        value: pending,
        color: 'var(--colorScheme-colorNeutralForeground1)',
      },
      {
        dict: 'Meeting.SidePanel.Status.Signed',
        value: succeeded,
        color: 'var(--colorScheme-colorPaletteLightGreenForeground1)',
      },
      {
        dict: 'Meeting.SidePanel.Status.Rejected',
        value: failed,
        color: 'var(--colorScheme-colorPaletteRedForeground1)',
      },
    ];

    return rawValues.map(({ dict, value, color }) => ({ name: t({ id: dict }), value, color }));
  }, [sidePanelState.signingStatusSummary]);

  return (
    <div className="side-panel__dashboard">
      <Text weight="bold">{t({ id: 'Meeting.SidePanel.Signing.StatusHeader' })}</Text>
      <StatusCounters values={statusCountersValues} />
    </div>
  );
};
