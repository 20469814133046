import { Avatar, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import './MeetingParticipant.scss';

interface Props {
  participant: {
    name: string;
    photoUrl: string;
    meetingRole: string;
  };
}

export const MeetingParticipant = ({ participant }: Props) => {
  return (
    <div className="meeting-participant">
      <Avatar name={participant.name} image={{ src: participant.photoUrl }} />
      <span>
        <Text>{participant.name}</Text>{' '}
        {participant.meetingRole === 'Organizer' && <Text>{t({ id: 'StartSignTask.Organizer' })}</Text>}
      </span>
    </div>
  );
};
