import { useMemo } from 'react';
import './StatusCounters.scss';

const StatusCountersRow = ({ name, value, color }) => {
  return (
    <>
      <div className="side-panel-status-counters__name">{name}</div>
      <div className="side-panel-status-counters__value" style={{ color }}>
        {value}
      </div>
    </>
  );
};

export const StatusCounters = ({ values = [] }) => {
  // values = [{name, value, color}, ...]

  const elements = useMemo(() => {
    return values.map((value) => <StatusCountersRow key={`value-${value.name}`} {...value} />);
  }, [values]);

  return <div className="side-panel-status-counters">{elements}</div>;
};
