import { useState, useMemo } from 'react';
import { Text, Button } from 'shared-fe-components/src/common/FluentMigration';
import { t } from '@lingui/macro';
import { StatusCounters } from '../StatusCounters/StatusCounters';
import { OrganizerGuard } from '../../Utils';
import * as api from 'shared-fe-components/src/api';
import { useSidePanelContext } from '../SidePanelContext';

export const Dashboard = () => {
  const { sidePanelState, updateVerificationStatus } = useSidePanelContext();
  const [isBusyFinishing, setIsBusyFinishing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const finishVerification = async () => {
    if (!isBusyFinishing) {
      setError(null);
      setIsBusyFinishing(true);

      try {
        await api.verification.completeVerification(sidePanelState.verificationStatusApiResponse.id);
        await updateVerificationStatus();
      } catch (err) {
        setError(t({ id: 'Meeting.SidePanel.Verification.Finish.Error' }));
      }
      setIsBusyFinishing(false);
    }
  };

  const statusCountersValues = useMemo(() => {
    const { pending, succeeded, failed } = sidePanelState.verificationStatusSummary;
    const rawValues = [
      {
        dict: 'Meeting.SidePanel.Status.BeforeVerification',
        value: pending,
        color: 'var(--colorScheme-colorNeutralForeground1)',
      },
      {
        dict: 'Meeting.SidePanel.Status.Verified',
        value: succeeded,
        color: 'var(--colorScheme-colorPaletteLightGreenForeground1)',
      },
      {
        dict: 'Meeting.SidePanel.Status.Rejected',
        value: failed,
        color: 'var(--colorScheme-colorPaletteRedForeground1)',
      },
    ];

    return rawValues.map(({ dict, value, color }) => ({ name: t({ id: dict }), value, color }));
  }, [sidePanelState.verificationStatusSummary]);

  return (
    <div className="side-panel__dashboard">
      <Text weight="bold">{t({ id: 'Meeting.SidePanel.Verification.StatusHeader' })}</Text>
      <StatusCounters values={statusCountersValues} />

      <OrganizerGuard>
        <div className="side-panel__dashboard-buttons-wrapper">
          <Button primary onClick={finishVerification} disabled={isBusyFinishing} loading={isBusyFinishing}>
            {t({ id: 'Meeting.SidePanel.Verification.Finish' })}
          </Button>
        </div>
        {error && (
          <Text error role="alert">
            {error}
          </Text>
        )}
      </OrganizerGuard>
    </div>
  );
};
