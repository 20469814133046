import { Dispatch } from 'react';

export type ButtonProps = {
  loading?: boolean;
  disabled?: boolean;
};

export const buttonDisabled: ButtonProps = {
  disabled: true,
};

export const buttonDisabledAndLoading: ButtonProps = {
  loading: true,
  disabled: true,
};

export type State = {
  startSigningButtonProps: ButtonProps;
  startVerificationButtonProps: ButtonProps;
  signingInProgress: boolean;
  verificationInProgress: boolean;
  signingStatusApiResponse: any;
  verificationStatusApiResponse: any;
  signingStatusSummary: any;
  verificationStatusSummary: any;
};

export type Action =
  | { type: 'IDLE' }
  | { type: 'SIGNING_STATUS_UPDATE'; signingStatusApiResponse: any; signingStatusSummary: any }
  | { type: 'VERIFICATION_STATUS_UPDATE'; verificationStatusApiResponse: any; verificationStatusSummary: any }
  | { type: 'SIGNING_BUSY' }
  | { type: 'VERIFICATION_BUSY' }
  | { type: 'SIGNING_IN_PROGRESS' }
  | { type: 'VERIFICATION_IN_PROGRESS' };

export type SidePanelContextType = {
  sidePanelState: State;
  sidePanelDispatch: Dispatch<Action>;
  updateSigningStatus: any;
  updateVerificationStatus: any;
  shareToStage: any;
};
