import { useCallback, useEffect, useRef, useState } from 'react';

export const useInfiniteScroll = (fetchData: () => unknown, hasMore: boolean) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const observer = useRef(new IntersectionObserver(() => {}));
  const triggerRef = useCallback(
    (node: any) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsLoading(true);
          fetchData();
          setIsLoading(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  return { isLoading, triggerRef };
};
