import { useCallback, useRef, useState } from "react";

export const useThrottledState = (initialValue, timeout = 1000) => {
  const [[immediateValue, throttledValue], setValue] = useState(() => {
      const value = typeof initialValue === 'function' ? initialValue() : initialValue;

      return [value, value];
  });

  const timerRef = useRef(null);

  const setValueThrottled = useCallback((value) => {
      setValue((state) => {
          const [immediateValue, throttledValue] = state;

          const newValue = typeof value === 'function' ? value(immediateValue) : value;

          if (timerRef.current !== null) {
              clearTimeout(timerRef.current);
          }

          timerRef.current = setTimeout(() => {
              setValue([newValue, newValue]);

              timerRef.current = null;
          }, timeout);

          return [newValue, throttledValue];
      });
  }, [timeout]);

  return [immediateValue, throttledValue, setValueThrottled];
};