import { Fragment } from 'react';
import { Breadcrumb, BreadcrumbDivider, BreadcrumbButton, BreadcrumbItem } from '@fluentui/react-breadcrumb-preview';
import PropTypes from 'prop-types';
import './FileBrowserBreadcrumbs.scss';

export const FileBrowserBreadcrumbs = ({ folders, onClick }) => (
  <Breadcrumb className="file-browser-breadcrumbs">
    {folders.map((folder, index) => {
      const isLastItem = folders.length - 1 === index;

      return (
        <Fragment key={folder.id}>
          <BreadcrumbItem>
            <BreadcrumbButton current={isLastItem} onClick={() => !isLastItem && onClick(index)}>
              {folder.displayName ?? folder.name}
            </BreadcrumbButton>
          </BreadcrumbItem>
          {!isLastItem && <BreadcrumbDivider />}
        </Fragment>
      );
    })}
  </Breadcrumb>
);

FileBrowserBreadcrumbs.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};
