import { useMeetingContext } from 'contexts';

export const OrganizerGuard = ({ children }) => {
  const { user } = useMeetingContext() as any;
  return user.meetingRole === 'Organizer' ? children : null;
};

export const NotOrganizerGuard = ({ children }) => {
  const { user } = useMeetingContext() as any;
  return user.meetingRole === 'Organizer' ? null : children;
};
