import { Dialog, DialogSurface, DialogBody, Button, Text, Portal } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { Warning16Regular, Dismiss16Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FileBrowserCloudFiles } from './MainContent/FileBrowserCloudFiles';
import { FileBrowserLocalFiles } from './MainContent/FileBrowserLocalFiles';
import { FileBrowserTeamFiles } from './MainContent/FileBrowserTeamFiles';
import { FileBrowserSidebar } from './Sidebar/FileBrowserSidebar';
import { format as formatBytes } from 'bytes';
import { FILE_FORMATS } from 'shared-fe-components/src/utils/fileFormats';
import './FileBrowser.scss';

const validateFile = (entryOrFile, allowedFileTypes = null, allowedFileSize = null) => {
  if (Array.isArray(allowedFileTypes) && allowedFileTypes.length > 0) {
    const mimeType = entryOrFile instanceof File ? entryOrFile.type : entryOrFile.file.mimeType;

    if (!allowedFileTypes.includes(mimeType)) {
      return { fileType: { mimeType } };
    }
  }

  if (isFinite(allowedFileSize) && allowedFileSize > 0) {
    if (entryOrFile.size > allowedFileSize) {
      return { fileSize: { size: entryOrFile.size } };
    }
  }

  return null;
};

const formatTypes = (types) => {
  return (types ?? []).map((type) => FILE_FORMATS[type] ?? type).join(', ');
};

export const FileBrowserModal = ({ allowedFileTypes = null, allowedFileSize = null, onFileSelect, onCancel }) => {
  const [activeGroup, setActiveGroup] = useState(() => ['local', null]);
  const [error, setError] = useState(null);

  const onClickLocalMachine = useCallback(() => {
    setActiveGroup(['local', null]);
    setError(null);
  }, []);

  const onClickTeam = useCallback((team) => {
    setActiveGroup(['team', team]);
    setError(null);
  }, []);

  const onClickCloud = useCallback((cloud) => {
    setActiveGroup(['cloud', cloud]);
    setError(null);
  }, []);

  const onFileSelectWithValidation = useCallback(
    (entryOrFile) => {
      const validationError = validateFile(entryOrFile, allowedFileTypes, allowedFileSize);
      setError(validationError);

      if (!validationError) {
        onFileSelect(entryOrFile);
      }
    },
    [onFileSelect, allowedFileTypes, allowedFileSize]
  );
  const AlertCloseButton = () => (
    <Button
      appearance="transparent"
      icon={<Dismiss16Regular />}
      title={t({ id: 'Common.Close' })}
      onClick={() => setError(null)}
    />
  );

  return (
    <div className="file-browser-wrapper background-task">
      <div className="file-browser-wrapper__header">
        <Text as="h1" weight="bold">{t({ id: 'FileBrowser.Header.Title' })}</Text>
      </div>

      <div className="file-browser-wrapper__middle">
        <div className="file-browser-wrapper__sidebar">
          <FileBrowserSidebar
            activeGroup={activeGroup[1]}
            onClickLocalMachine={onClickLocalMachine}
            onClickTeam={onClickTeam}
            onClickCloud={onClickCloud}
            className="file-browser-modal__sidebar"
          />
        </div>

        <div className="file-browser-wrapper__main-content">
          {activeGroup[0] === 'local' && (
            <FileBrowserLocalFiles allowedFileTypes={allowedFileTypes} onFileSelect={onFileSelectWithValidation} />
          )}
          {activeGroup[0] === 'team' && (
            <FileBrowserTeamFiles
              allowedFileTypes={allowedFileTypes}
              team={activeGroup[1]}
              onFileSelect={onFileSelectWithValidation}
            />
          )}
          {activeGroup[0] === 'cloud' && (
            <FileBrowserCloudFiles allowedFileTypes={allowedFileTypes} onFileSelect={onFileSelectWithValidation} />
          )}
        </div>
      </div>

      <div className="file-browser-wrapper__errors">
        {error?.fileType && (
          <Alert intent="error" icon={<Warning16Regular />} action={AlertCloseButton}>
            {t({
              id: 'FileBrowser.Error.FileType',
              values: { allowedFileTypes: formatTypes(allowedFileTypes) },
            })}
          </Alert>
        )}
        {error?.fileSize && (
          <Alert intent="error" icon={<Warning16Regular />} action={AlertCloseButton}>
            {t({ id: 'FileBrowser.Error.FileSize', values: { allowedFileSize: formatBytes(allowedFileSize) } })}
          </Alert>
        )}
      </div>

      <footer className="file-browser-wrapper__footer">
        <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
      </footer>
    </div>
  );
};

/*
Usage:

  <FileBrowser
    open={true}
    allowedFileTypes={['application/pdf']}
    allowedFileSize={5 * 1024 * 1024}
    onFileSelect={onFileSelect}
    onCancel={onCancel}
  />
*/
export const FileBrowser = ({ open, allowedFileTypes, allowedFileSize, onFileSelect, onCancel }) => (
  <Portal>
    <Dialog open={open}>
      <DialogBody>
        <DialogSurface>
          <FileBrowserModal {...{ allowedFileTypes, allowedFileSize, onFileSelect, onCancel }} />
        </DialogSurface>
      </DialogBody>
    </Dialog>
  </Portal>
);

FileBrowser.propTypes = {
  open: PropTypes.bool.isRequired,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  allowedFileSize: PropTypes.number,
  onFileSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
