import { useState, useEffect } from "react";

let counter = 0;
let pendingAlerts = [];
let setters = [];

function register(setter) {
  setters = [...setters, setter];

  setter(pendingAlerts);
}

function unregister(setter) {
  setters = setters.filter((item) => item !== setter);
}

function notifyAll(value) {
  setters.forEach((setter) => setter(value));
}

function teardown(key) {
  pendingAlerts = pendingAlerts.filter((value) => value.key !== key);

  notifyAll(pendingAlerts);
}

function enqueue() {
  const key = counter++;

  pendingAlerts = [
    ...pendingAlerts,
    {
      key,
      onCancel() {
        teardown(key);
      },
    },
  ];

  notifyAll(pendingAlerts);
}

export function alert() {
  enqueue();
}

export function useAlertQueue() {
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    register(setQueue);

    return () => unregister(setQueue);
  }, []);

  return queue;
}
