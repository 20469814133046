import { useState } from 'react';
import { Button } from 'shared-fe-components/src/common/FluentMigration';
import { Text } from '@fluentui/react-components';
import { ReOrderDotsVertical24Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { MeetingParticipant } from './MeetingParticipant';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './PeopleOrder.scss';

export const PeopleOrder = ({ selectedPeople, onPeopleOrdered }) => {
  const [people, setPeople] = useState(() => [...selectedPeople]);

  const onDragEnd = ({ source, destination }) => {
    if (destination) {
      const tempPeople = [...people];
      [tempPeople[source.index], tempPeople[destination.index]] = [
        tempPeople[destination.index],
        tempPeople[source.index],
      ];
      setPeople(tempPeople);
    }
  };

  const draggableItem = (participant, index) => (
    <Draggable key={`people-order__draggable-${index}`} draggableId={`people-order__draggable-${index}`} index={index}>
      {(provided, snapshot) => (
        <li key={participant.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div className="people-order__draggable">
            &nbsp; {/**Trick to center the marker */}
            <ReOrderDotsVertical24Regular />
            <MeetingParticipant participant={participant} />
          </div>
        </li>
      )}
    </Draggable>
  );

  return (
    <WizardBox className="people-order">
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'StartSignTask.PeopleOrder.Header' })}
        </Text>
      </div>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="people-order__droppable">
            {(provided, snapshot) => (
              <ol {...provided.droppableProps} ref={provided.innerRef}>
                {people.map((participant, index) => draggableItem(participant, index))}
                {provided.placeholder}
              </ol>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div>
        <div>
          <ButtonBack onClick={() => onPeopleOrdered(people, false)} />
        </div>
        <div>
          <Button primary onClick={() => onPeopleOrdered(people, true)}>
            {t({ id: 'Common.Start' })}
          </Button>
        </div>
      </div>
    </WizardBox>
  );
};
