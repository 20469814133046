import { Spinner } from '@fluentui/react-components';
import { useTeamsContext, useThemeContext } from 'contexts';
import './LoadingScreen.scss';

interface Props {
  label: string | null;
  backgroundColor: string | null;
}

export const LoadingScreen = ({ label = null, backgroundColor: propBackgroundColor = null }: Props) => {
  const { customColors } = useThemeContext();
  const { context: teamsContext } = useTeamsContext();

  const backgroundColor =
    propBackgroundColor ?? customColors[`_${teamsContext?.page.frameContext}_background`] ?? `transparent`;

  return (
    <div className="loading-screen" style={{ backgroundColor }}>
      <Spinner label={label} labelPosition="below" />
    </div>
  );
};
