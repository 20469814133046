import { useEffect, useMemo } from 'react';
import { Input, Text, Dropdown, Switch, Option } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { Warning16Regular } from '@fluentui/react-icons';
import { Alert } from '@fluentui/react-components/unstable';
import { t } from '@lingui/macro';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { Recipients, SortableRecipients } from './Recipients';
import { CCRecipients } from './CCRecipients';
import { useConfigurationContext, useUserContext } from 'contexts';
import { TextAreaWithCounter } from 'shared-fe-components/src/common/TextAreaWithCounter/TextAreaWithCounter';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { FormCheck } from 'shared-fe-components/src/common/ReactHookForm/FormCheck';
import { DefaultReminderFrequency, ReminderFrequencyItems } from 'shared-fe-components/src/common/RequestSigning';
import { FilesIcon } from 'shared-fe-components/src/common/FilesIcon';
import { extractFileFormat } from 'shared-fe-components/src/utils';
import { DatePicker } from 'shared-fe-components/src/common/DatePicker';
import { MESSAGE_MAX_LENGTH } from './Utils';
import { FileObject } from '../../../lib/fileObject';
import { FilesMergeConvert } from '../FilesMergeConvert/FilesMergeConvert';
import { useDateFormatter } from '../../../lib/useDateFormatter';
import { dayFormatterOptions } from '../DashboardTable/dashboardTableData';
import dayjs from 'dayjs';

export const DashboardSendForSigningRequestSignature = ({
  recipients,
  reminderFrequency,
  ccRecipients,
  message,
  processName,
  isInOrder,
  setIsInOrder,
  onChosenData,
  files,
  expirationDate,
  onSendInFilesAndDate,
  isEditMode,
  isConfirmed,
  setIsConfirmed,
  setIsFormDirty,
}) => {
  const { user, features } = useUserContext();
  const { signing } = useConfigurationContext();

  const minExpirationDate = new Date();

  const maxExpirationDate = useMemo(() => {
    return dayjs().add(signing.maxSessionLength, 'day').toDate();
  }, [signing]);

  const dayFormatter = useDateFormatter(dayFormatterOptions);

  const expirationDateValidation = {
    required: {
      value: true,
      message: t({ id: 'Form.Errors.RequiredField' }),
    },
    min: {
      value: new Date(),
      message: t({ id: 'Dashboard.Signing.Errors.DateCantBeInThePast' }),
    },
  };

  const processNameProps = {
    fluid: true,
    maxLength: 50,
    placeholder: t({ id: 'Dashboard.Signing.Placeholders.ProcessName' }),
    appearance: 'filled-darker',
  };

  const messageProps = {
    characterLimit: MESSAGE_MAX_LENGTH,
    size: 'large',
    resize: 'vertical',
    fluid: true,
    placeholder: t({ id: 'Form.Message.Placeholder' }),
    appearance: 'filled-darker',
  };

  const onReminderFrequencyChange = (item) => {
    isEditMode && setIsFormDirty(true);
    setValue('reminderFrequency', item.optionValue);
  };

  const mappedReminderFrequencyItems = ReminderFrequencyItems.map((item) => ({
    ...item,
    header: t({ id: item.header }),
  }));

  const reminderFrequencyOptions = mappedReminderFrequencyItems.map((option) => (
    <Option key={option.key} value={option.key}>
      {option.header}
    </Option>
  ));

  const reminderFrequencyValue = reminderFrequency ?? DefaultReminderFrequency;
  const DefaultReminderFrequencyItemObj = mappedReminderFrequencyItems.find(
    (item) => item.key === reminderFrequencyValue
  );

  const reminderFrequencyConfig = {
    defaultValue: DefaultReminderFrequencyItemObj.header,
    onOptionSelect: (_, item) => onReminderFrequencyChange(item),
    appearance: 'filled-darker',
  };

  const userEmailAddress = user.email;
  const formMethods = useForm({
    criteriaMode: 'all',
    defaultValues: {
      recipients: recipients,
      reminderFrequency: reminderFrequency ?? DefaultReminderFrequencyItemObj.key,
      ccRecipients: ccRecipients,
      isInOrder: isInOrder,
      files: isEditMode ? [] : files.map((x) => ({ value: x, isSelected: false, editFilename: x.name })),
      expirationDate: expirationDate,
      message: message,
      processName: processName,
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    trigger,
    control,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = formMethods;

  const onFormSubmit = (data) => {
    onChosenData(
      data.recipients,
      data.ccRecipients,
      data.message,
      data.processName,
      data.files.map((file) => new FileObject(file.value)),
      data.expirationDate,
      data.reminderFrequency
    );
    onSendInFilesAndDate();
  };

  const onConfirm = handleSubmit(onFormSubmit);

  useEffect(() => {
    if (isConfirmed) {
      trigger()
        .then((isValid) => {
          isValid && onConfirm();
        })
        .finally(() => {
          setIsConfirmed(false);
        });
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (isDirty && isEditMode) setIsFormDirty(true);
  }, [isDirty, isEditMode]);

  const handleOrderChange = (e, data) => {
    setIsInOrder(!data.checked);
  };

  const handleDateChange = (date) => {
    setValue('expirationDate', date);
    setIsFormDirty(true);
  };

  const formEmailChecks = [
    {
      key: 'validationDuplicateEmails',
      fieldName: 'validationDuplicateEmails',
      validateFn: () => {
        const recipients = getValues('recipients');
        const ccRecipients = getValues('ccRecipients');
        if (recipients.length <= 1 && ccRecipients.length <= 1) {
          return true;
        } else {
          let uniqueRecipientsValues = new Set(recipients.map((v) => v.value));
          let uniqueCcRecipientsValues = new Set(ccRecipients.map((v) => v.value));
          const noRecipientEmailDuplicates = uniqueRecipientsValues.size !== recipients.length;
          const noCcRecipientEmailDuplicates = uniqueCcRecipientsValues.size !== ccRecipients.length;
          if (noRecipientEmailDuplicates || noCcRecipientEmailDuplicates) {
            return t({ id: 'Dashboard.Signing.Errors.DuplicateEmails' });
          } else {
            return true;
          }
        }
      },
    },
  ].map((checkProps) => <FormCheck {...checkProps} />);

  return (
    <FormProvider {...formMethods}>
      <div className="send-for-signing__two-column-view">
        <div className="send-for-signing__two-column-view-left-column">
          <div className="send-for-signing__column-with-gaps">
            <Header as="h2">{t({ id: 'Dashboard.Signing.Headers.Recipients' })}</Header>
            <div className="send-for-signing__recipients-selection">
              <Text weight={isInOrder ? 'semibold' : 'regular'}>{t({ id: 'Form.Sign.InOrder' })}</Text>
              <Switch
                label={<Text weight={isInOrder ? 'regular' : 'semibold'}>{t({ id: 'Form.Sign.NotInOrder' })}</Text>}
                checked={!isInOrder}
                disabled={!features.ParallelSigning || isEditMode}
                onChange={handleOrderChange}
              />
            </div>
            {isInOrder ? (
              <SortableRecipients
                deleteFirstIfEmpty={false}
                userEmailAddress={userEmailAddress}
                isCC={false}
                isDisabled={isEditMode}
                setIsFormDirty={setIsFormDirty}
              />
            ) : (
              <Recipients
                isCC={false}
                deleteFirstIfEmpty={false}
                userEmailAddress={userEmailAddress}
                isDisabled={isEditMode}
                setIsFormDirty={setIsFormDirty}
              />
            )}
          </div>
          <div>
            <Header as="h2">{t({ id: 'Dashboard.Signing.Headers.CarbonCopy' })}</Header>
            <CCRecipients setIsFormDirty={setIsFormDirty} userEmailAddress={userEmailAddress} isDisabled={isEditMode} />
          </div>
          <div>{formEmailChecks}</div>
        </div>
      </div>
      <div className="send-for-signing__two-column-view">
        <div className="send-for-signing__two-column-view-left-column">
          <div className="send-for-signing__column-with-gaps">
            <Header as="h2">{t({ id: 'Dashboard.Signing.Headers.Files' })}</Header>
            {isEditMode ? (
              files.map((file) => {
                return (
                  <div key={file.id} className="send-for-signing__selected-files">
                    <FilesIcon fileType={extractFileFormat(file.filename)} />
                    <Text>{file.filename}</Text>
                    <Anchor href={file.originalDocumentUrl} target="_blank" rel="noreferrer noopener">
                      {t({ id: 'Form.FilesInputs.PreviewFile' })}
                    </Anchor>
                  </div>
                );
              })
            ) : (
              <div className="send-for-signing__choose-files">
                <FilesMergeConvert />
              </div>
            )}
            <div className="send-for-signing__row">
              <div className="send-for-signing__column-with-gaps">
                <Header as="h2">{t({ id: 'Dashboard.Signing.Headers.ExpirationDate' })}</Header>
                <DatePicker
                  fieldName="expirationDate"
                  rules={expirationDateValidation}
                  inputOnly
                  error={errors?.expirationDate?.message}
                  minDate={minExpirationDate}
                  maxDate={maxExpirationDate}
                  formatDate={(date) => dayFormatter(date)}
                  value={new Date(getValues('expirationDate'))}
                  appearance="filled-darker"
                  onSelectDate={handleDateChange}
                />
                {errors?.expirationDate?.message && (
                  <Alert intent="error" icon={<Warning16Regular />}>
                    {errors?.expirationDate?.message}
                  </Alert>
                )}
              </div>
              <div className="send-for-signing__column-with-gaps">
                <Header as="h2">{t({ id: 'Dashboard.Signing.Headers.ReminderFrequency' })}</Header>
                <Dropdown {...reminderFrequencyConfig}>{reminderFrequencyOptions}</Dropdown>
                {errors?.reminderFrequency?.message && (
                  <Alert intent="error" icon={<Warning16Regular />}>
                    {errors?.reminderFrequency?.message}
                  </Alert>
                )}
              </div>
            </div>
            <div className="send-for-signing__column-with-gaps">
              <Header as="h2">{t({ id: 'Dashboard.Signing.Headers.Message' })}</Header>
              <Controller
                control={control}
                name="processName"
                render={({ field }) => <Input {...processNameProps} {...field} />}
              />
              <Controller
                control={control}
                name="message"
                rules={{ maxLength: MESSAGE_MAX_LENGTH }}
                render={(formData) => <TextAreaWithCounter {...messageProps} formData={formData} />}
              />
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
