import { useMemo, useRef, useState } from 'react';
import { Button, Dialog, DialogBody, DialogSurface } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import * as api from 'shared-fe-components/src/api';
import { t } from '@lingui/macro';
import { useUploadOnStorage } from '../../../lib/useUploadOnStorage';
import { useLocalizationContext } from '../../../contexts';
import { LoadingScreen } from '../../Common/LoadingScreen';
import { DashboardSendForSigningRequestSignature } from './DashboardSendForSigningRequestSignature';
import { DashboardSendForSigningError } from './DashboardSendForSigningError';
import { DashboardSendForSigningSuccess } from './DashboardSendForSigningSuccess';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { XmlSelectionModal } from 'shared-fe-components/src/common/XmlSelectionModal';
import dayjs from 'dayjs';
import { useSessionStatisticsContext } from 'contexts';
import PropTypes from 'prop-types';
import { RequestSignatureOffset } from 'shared-fe-components/src/common/DatePicker';
import { ProtectionType } from 'shared-fe-components/src/common/Constants';
import './DashboardSendForSigning.scss';

const getDefaultExpirationDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + RequestSignatureOffset);
  return date;
};

export const DashboardSendForSigning = ({ onClose }) => {
  const { fetchSessionStatistics } = useSessionStatisticsContext();
  const [step, setStep] = useState('');
  const [loaderLabel, setLoaderLabel] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [isInOrder, setIsInOrder] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const recipients = useRef([]);
  const ccRecipients = useRef([]);
  const reminderFrequency = useRef();
  const message = useRef('');
  const processName = useRef('');
  const files = useRef([]);
  const expirationDate = useRef(getDefaultExpirationDate());
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { locale } = useLocalizationContext();
  const { putItOnStorage } = useUploadOnStorage();

  const onChosenData = (
    emittedRecipients,
    emittedCcRecipients,
    emittedMessage,
    emittedProcessName,
    emittedFiles,
    emittedExpirationDate,
    emittedReminderFrequency
  ) => {
    recipients.current = emittedRecipients;
    ccRecipients.current = emittedCcRecipients;
    message.current = emittedMessage;
    processName.current = emittedProcessName;
    files.current = emittedFiles;
    expirationDate.current = emittedExpirationDate;
    reminderFrequency.current = emittedReminderFrequency;
  };

  const sendForSigning = async (xadesFormat) => {
    const isFilesFromDevice = files.current.map((item) => item.teamsFileSource).includes(null);

    const recipientsList = recipients.current
      .filter((recipient) => recipient.value?.length > 0)
      .map((recipient) => {
        const otpData =
          recipient.mobilephoneNumber?.length > 0
            ? {
                protectionType: ProtectionType.OneTimePassword,
                phoneNumber: {
                  prefix: Number(recipient.mobilephoneCode?.split('-')?.[1]),
                  number: recipient.mobilephoneNumber,
                  countryCode: recipient.mobilephoneCode?.split('-')?.[0],
                },
              }
            : {};

        return {
          type: 'External',
          email: recipient.value,
          culture: recipient.lang,
          ...otpData,
        };
      });

    const ccRecipientsList = ccRecipients.current
      .filter((ccRecipient) => ccRecipient.value?.length > 0)
      .map((ccRecipient) => ({
        email: ccRecipient.value,
        culture: ccRecipient.lang,
      }));

    let bodyObj = {};
    if (isFilesFromDevice) {
      const { container, isError } = await putItOnStorage(files.current);

      if (isError) return setStep('Error');

      bodyObj = {
        sources: container.documents.map((file) => ({
          filename: file.fileName,
          type: 'StorageContainer',
          StorageContainerSource: { ContainerId: container.id, FileId: file.id },
        })),
        signatureType: isInOrder ? 'Sequential' : 'Parallel',
        recipients: recipientsList,
        carbonCopyRecipients: ccRecipientsList,
        message: message.current,
        name: processName.current,
        expirationTime: dayjs(expirationDate.current).endOf('day').format(),
        culture: locale,
        reminderFrequency: reminderFrequency.current,
        xadesFormat,
      };
    } else {
      const sources = files.current.map(({ teamsFileSource }) => ({ teamsFileSource }));

      bodyObj = {
        sources: sources,
        signatureType: isInOrder ? 'Sequential' : 'Parallel',
        recipients: recipientsList,
        carbonCopyRecipients: ccRecipientsList,
        message: message.current,
        name: processName.current,
        expirationTime: dayjs(expirationDate.current).endOf('day').format(),
        culture: locale,
        reminderFrequency: reminderFrequency.current,
        xadesFormat,
      };
    }
    try {
      const resp = await api.signing.requestSignature(bodyObj);
      return [true, resp];
    } catch (error) {
      return [false, error];
    }
  };

  const onSendInToSign = async (xadesFormat) => {
    setLoaderLabel('');
    setStep('Loader');
    const result = await sendForSigning(xadesFormat);
    if (result[0]) {
      setStep('Success');
      fetchSessionStatistics();
    } else {
      setErrorDescription(t({ id: 'Dashboard.Signing.Errors.SendingFailed' }));
      setStep('Error');
    }
  };

  const onSendInFilesAndDate = async () => {
    if (files.current[0].name.endsWith('.xml')) {
      setStep('XmlSelection');
    } else {
      await onSendInToSign();
    }
  };

  const onXmlTypeSelect = async (xadesFormat) => {
    await onSendInToSign(xadesFormat);
  };

  const filenames = useMemo(() => {
    return files.current.map((file) => file.name);
  }, [files]);

  switch (step) {
    default:
      return (
        <WizardBox className="send-for-signing" growContent={false}>
          <div>
            <Header as="h1">{t({ id: 'Dashboard.Signing.Headers.RequestSignature' })}</Header>
          </div>
          <div>
            <DashboardSendForSigningRequestSignature
              recipients={recipients.current}
              ccRecipients={ccRecipients.current}
              reminderFrequency={reminderFrequency.current}
              message={message.current}
              processName={processName.current}
              isInOrder={isInOrder}
              setIsInOrder={setIsInOrder}
              onChosenData={onChosenData}
              files={files.current}
              expirationDate={expirationDate.current}
              onSendInFilesAndDate={onSendInFilesAndDate}
              onClose={onClose}
              setIsConfirmed={setIsConfirmed}
              isConfirmed={isConfirmed}
              setIsFormDirty={setIsFormDirty}
            />
          </div>
          <div>
            <span className="send-for-signing__two-column-view">
              <div className="send-for-signing__two-column-view-left-column">
                <div className="send-for-signing__control-action-buttons">
                  <Button onClick={onClose}>{t({ id: 'Common.Cancel' })}</Button>
                  <Button appearance="primary" onClick={() => setIsConfirmed(true)}>
                    {t({ id: 'Common.Send' })}
                  </Button>
                </div>
              </div>
            </span>
          </div>
        </WizardBox>
      );
    case 'XmlSelection':
      return (
        <Dialog open={true}>
          <DialogBody>
            <DialogSurface>
              <XmlSelectionModal
                hideXmlTypeSign={recipients.current.length > 1}
                onBack={() => setStep('')}
                onCancel={onClose}
                onContinue={onXmlTypeSelect}
              />
            </DialogSurface>
          </DialogBody>
        </Dialog>
      );
    case 'Loader':
      return <LoadingScreen label={loaderLabel} backgroundColor={'var(--colorNeutralBackground1)'} />;
    case 'Success':
      return <DashboardSendForSigningSuccess filenames={filenames} onClose={onClose} />;
    case 'Error':
      return <DashboardSendForSigningError errorDescription={errorDescription} onClose={onClose} />;
  }
};

DashboardSendForSigning.propTypes = {
  onClose: PropTypes.func.isRequired,
};
